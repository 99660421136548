import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  FormControl,
  Select,
  Box,
  Grid,
  Divider,
  MenuItem,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import { withStyles } from '@material-ui/core/styles';
import NewProjectDialogStyles from '../../styles/jss/components/common/NewProjectDialogStyles';
import { cfg } from '../../config';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { NotificationManager } from 'react-notifications';
import withRouter from '../../helpers/withRouter';
import EditIcon from '@material-ui/icons/Edit';
import { checkForData } from '../../helpers';

const useStyles = makeStyles(NewProjectDialogStyles);

const EditAnalysisDialog = (props) => {
  const [open, setOpen] = React.useState(false);
  const [saving, setSaving] = React.useState(false);

  const getInitialState = (props) => {
    return {
      name: props.name,
      aircraftMSN: props.args.aircraftMSN,
      aircraftType: props.args.aircraftType,
      aircraftEffectivity: props.args.aircraftEffectivity,
      aircraftTail: props.args.aircraftTail,
    };
  };

  const [{ name, aircraftMSN, aircraftType, aircraftEffectivity, aircraftTail }, setState] =
    React.useState(getInitialState(props));

  const clearState = () => {
    setState({ ...getInitialState(props) });
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const updateProps = () => {
    setSaving(true);
    const updatedData = {
      name: name,
      args: {
        aircraftMSN: aircraftMSN,
        aircraftType: aircraftType,
        aircraftEffectivity: aircraftEffectivity,
        aircraftTail: aircraftTail,
      },
      lastModifiedBy: props.user.attributes.email,
    };
    props
      .patchAnalysis(updatedData)
      .then(() => {
        props.update(name, 'name');
        props.update(aircraftMSN, 'aircraftMSN');
        props.update(aircraftEffectivity, 'aircraftEffectivity');
        props.update(aircraftTail, 'aircraftTail');
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const classes = useStyles(NewProjectDialogStyles);

  const handleOpenClick = () => {
    clearState();
    setOpen(!open);
  };

  const handleCloseClick = () => {
    setOpen(!open);
  };

  const handleUpdateClick = () => {
    updateProps();
    setOpen(!open);
  };

  const handleCancelClick = () => {
    setOpen(!open);
  };

  return (
    <div>
      <LoadingButton
        color="primary"
        style={{ width: '75%' }}
        disabled={props.isDisabled}
        onClick={handleOpenClick}
        loading={saving}
        loadingPosition="start"
        startIcon={<EditIcon />}
      >
        EDIT
      </LoadingButton>
      <Dialog
        open={open}
        onClose={handleCloseClick}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.dialogContainer }}
      >
        <form>
          <DialogTitle id="form-dialog-title">Edit Analysis Details</DialogTitle>
          <Divider />
          <br />
          <DialogContent>
            <Box mb={2}>
              <FormControl fullWidth>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      id="name"
                      autoFocus
                      name="name"
                      fullWidth
                      variant="filled"
                      label="Analysis Name"
                      defaultValue={name}
                      onChange={onChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      type="text"
                      id="aircraftType"
                      name="aircraftType"
                      fullWidth
                      variant="filled"
                      label="Aircraft Type"
                      defaultValue={aircraftType}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      type="text"
                      id="aircraftMSN"
                      name="aircraftMSN"
                      fullWidth
                      variant="filled"
                      label="Aircraft MSN"
                      defaultValue={aircraftMSN}
                      onChange={onChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      type="text"
                      id="aircraftEffectivity"
                      name="aircraftEffectivity"
                      fullWidth
                      variant="filled"
                      label="Aircraft Effectivity"
                      defaultValue={aircraftEffectivity}
                      onChange={onChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      type="text"
                      id="aircraftTail"
                      name="aircraftTail"
                      fullWidth
                      variant="filled"
                      label="Aircraft Tail"
                      defaultValue={aircraftTail}
                      onChange={onChange}
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions classes={{ root: classes.buttonContainer }}>
            <Button color={'default'} onClick={handleCancelClick}>
              Cancel
            </Button>
            <Button color="primary" variant={'contained'} onClick={handleUpdateClick}>
              Update
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default withRouter(EditAnalysisDialog);
