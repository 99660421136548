import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  FormControl,
  Select,
  InputLabel,
  Box,
  Grid,
  Divider,
  Typography,
  MenuItem,
} from '@material-ui/core';
import Stack from '@mui/material/Stack';
import { withStyles } from '@material-ui/core/styles';
import NewProjectDialogStyles from '../../../../styles/jss/components/common/NewProjectDialogStyles';
import { cfg } from '../../../../config';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { NotificationManager } from 'react-notifications';
import ContentLoading from '../../../common/ContentLoading';
import withRouter from '../../../../helpers/withRouter';
import { getBase64, downloadBoeingCsvTemplate } from '../../../../helpers';
import { DropzoneArea } from 'material-ui-dropzone';
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";

const NewAnalysisDialog = (props) => {
  const navigate = useNavigate();
  const params = useParams();

  const [user, setUser] = React.useState(null);
  const [inputs, setInputs] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [aircrafts, setAircrafts] = React.useState([]);

  React.useEffect(() => {
    fetchData();
    fetchUserInfo();
  }, []);

  const fetchData = () => {
    let tempInputs = {};
    for (let i = 0; i < props.inputs.length; i++) {
      tempInputs[props.inputs[i].id] = '';
    }
    setInputs(tempInputs);
    // Fetch available aircrafts
    Auth.currentSession().then((session) => {
      setIsLoading(true);
      axios
        .get(`${cfg.apiUrl}/app/boeing-ela/aircrafts`, {
          headers: {
            Authorization: session.getIdToken().jwtToken,
            'Content-Type': 'application/json',
          },
        })
        .then((resp) => {
          setIsLoading(false);
          const data = resp.data;
          // Create aircrafts list with all permutations of aircraft and msn
          let aircrafts = [];
          for (let i in data) {
            const aircraft = data[i];
            for (let j in aircraft.msns) {
              const msn = aircraft.msns[j];
              aircrafts.push(`${aircraft.aircraft} - ${msn}`);
            }
          }
          setAircrafts(aircrafts);
        })
        .catch((e) => {
          setIsLoading(false);
          console.log(e);
        });
    });
  }

  const fetchUserInfo = () => {
    Auth.currentAuthenticatedUser().then((resp) => {
      setUser(resp);
    });
  }

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const previousInputs = { ...inputs };
    previousInputs[name] = value;
    setInputs(previousInputs);
  };

  const handleFileChange = async (files) => {
    // Update state callback
    const updateInputFileState = (file) => {
      const previousInputs = inputs;
      previousInputs['inputFile'] = file; // base64
      setInputs(previousInputs);
    };

    // Reset inputFile if the input file is removed from the UI
    if (files.length === 0) {
      updateInputFileState(''); // reset inputFile
      return;
    }

    // Get the input file as base64 and update the state with the callback
    getBase64(files[0], updateInputFileState); // prints the base64 string
  };

  const submit = async (e) => {
    e.preventDefault();
    Auth.currentSession().then((session) => {
      setIsLoading(true);
      axios
        .post(
          `${cfg.apiUrl}/app/boeing-ela/analysis`,
          {
            projectId: params.id,
            username: user.attributes.email,
            name: inputs.name,
            args: {
              aircraftEffectivity: inputs.aircraftEffectivity,
              aircraftType: inputs.aircraftType,
              aircraftTail: inputs.aircraftTail,
              aircraftMSN: inputs.aircraftMSN,
            },
            csvEncoded: inputs.inputFile,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: session.getIdToken().jwtToken,
            },
          }
        )
        .then((resp) => {
          if (resp.status === 200) {
            // Close form
            setIsLoading(false);
            props.setOpen(false);
            navigate(`/app/BoeingELA/analysis/${params.id}/${resp.data.id}`);
          } else {
            NotificationManager.warning(
              'Something went wrong, please check console for response.',
              'WARNING'
            );
            console.log(resp);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.response) {
            if (err.response.status === 400) {
              NotificationManager.error(`The analysis cannot be created: ${err.response.data.error.message}`, 'Error', 10000);
              return
            }
          }
          NotificationManager.error('An error has occurred, please try again.', 'Error');
        });
    });
  };

  const { title } = props;
  const { classes, handleClose } = props;

  const theme = createTheme({
    overrides: {
      MuiDropzoneArea: {
        root: {
          minHeight: '', // reduce component height
        },
        text: {
          fontSize: '16px',
        },
      }
    }
  });


  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      classes={{ paper: classes.dialogContainer }}
    >
      <form onSubmit={(e) => submit(e)}>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <Divider />
        <br />
        <DialogContent>
        {isLoading ? (
          <div>
            <ContentLoading />
          </div>
        ) : (
          <Box mb={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  type="text"
                  id="name"
                  autoFocus
                  name="name"
                  fullWidth
                  required
                  variant="filled"
                  label="Analysis Name"
                  value={inputs.name}
                  onChange={(e) => handleChange(e)}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel variant="filled">Aircraft Type</InputLabel>
                  <Select
                    id="aircraftType"
                    name="aircraftType"
                    fullWidth
                    required
                    variant="filled"
                    label="Aircraft Type"
                    value={inputs.aircraftType}
                    onChange={(e) => handleChange(e)}
                  >
                    {aircrafts.map((aircraft) => (
                      <MenuItem value={aircraft} key={aircraft}>
                        {aircraft}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="text"
                  id="aircraftMSN"
                  name="aircraftMSN"
                  fullWidth
                  variant="filled"
                  label="Aircraft MSN"
                  value={inputs.aircraftMSN}
                  onChange={(e) => handleChange(e)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="text"
                  id="aircraftEffectivity"
                  name="aircraftEffectivity"
                  fullWidth
                  variant="filled"
                  label="Aircraft Effectivity"
                  value={inputs.aircraftEffectivity}
                  onChange={(e) => handleChange(e)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="text"
                  id="aircraftTail"
                  name="aircraftTail"
                  fullWidth
                  variant="filled"
                  label="Aircraft Tail"
                  value={inputs.aircraftTail}
                  onChange={(e) => handleChange(e)}
                />
              </Grid>
            </Grid>

            <br />
            <br />
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography variant="subtitle1">Import Analysis Data (optional)</Typography>
              <Button color={'primary'} onClick={() => downloadBoeingCsvTemplate()}>template.csv</Button>
            </Stack>
            <MuiThemeProvider theme={theme}>
              <DropzoneArea
                dropzoneText={'Drag & Drop or click to upload a .csv file'}
                onChange={(e) => handleFileChange(e)}
                acceptedFiles={['.csv']}
                filesLimit={1}
                showPreviews={true}
                showPreviewsInDropzone={false}
                useChipsForPreview
                previewText="Selected file"
              />
            </MuiThemeProvider>
          </Box>
        )}
        </DialogContent>
        <DialogActions classes={{ root: classes.buttonContainer }}>
          <Button disabled={isLoading} color={'default'} onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button disabled={isLoading || !inputs.name || !inputs.aircraftType} type={'submit'} color="primary" variant={'contained'}>
            Create
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

NewAnalysisDialog.propTypes = {
  title: PropTypes.string,
  inputs: PropTypes.array,
  confirmAction: PropTypes.func,
  open: PropTypes.bool,
};

NewAnalysisDialog.defaultProps = {
  title: 'Dialog Title',
  inputs: [
    {
      type: 'text',
      label: 'Text Input',
      id: 'text-input',
    },
    {
      type: 'select',
      label: 'Select Input',
      id: 'option-select',
      options: [
        {
          value: '123',
          label: 'Option 1',
        },
      ],
    },
  ],
  confirmAction: () => {
    console.log('Created New Project');
  },
  open: false,
};

export default withStyles(NewProjectDialogStyles, { withTheme: true })(
  withRouter(NewAnalysisDialog)
);
