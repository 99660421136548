import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createStore } from 'redux';
import rootReducer from './redux/reducers';
import { Provider } from 'react-redux';

import Amplify from 'aws-amplify';
import { cfg } from './config';

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

Amplify.configure(cfg.amplify.Auth);
Amplify.Storage.configure(cfg.amplify.Storage);

ReactDOM.render(
  // <React.StrictMode>
  //     <Provider store={store}>
  //         <App />
  //     </Provider>
  // </React.StrictMode>,

  // Removed StrictMode: Material-UI has a findDOMNode error that was annoying me... will re-add. Error will be resolved v5
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
