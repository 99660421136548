/* This class is used when the Project List page has no projects for the
logged in user and prompts the user to create a new project */

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TextField } from '@material-ui/core';

/** App Data */
import newProjectData from '../../../../data/ELA-analysis-new-project';

import { withStyles } from '@material-ui/core/styles';
import NewProjectDialogStyles from '../../../../styles/jss/components/common/NewProjectDialogStyles';
import { cfg } from '../../../../config';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { NotificationManager } from 'react-notifications';
import withRouter from '../../../../helpers/withRouter';
import PropTypes from 'prop-types';
import ContentLoading from '../../../common/ContentLoading';

const ProjectListEmpty = (props) => {
  const navigate = useNavigate();

  const [inputs, setInputs] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    let tempInputs = {};
    for (let i = 0; i < props.inputs.length; i++) {
      tempInputs[props.inputs[i].id] = '';
    }
    setInputs(tempInputs);
  }, []);

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const previousInputs = inputs;
    previousInputs[name] = value;
    setInputs(previousInputs);
  };

  const submit = async (e) => {
    e.preventDefault();
    Auth.currentSession().then((session) => {
      setLoading(true);
      axios
        .post(
          `${cfg.apiUrl}/app/airbus-ela/project`,
          {
            ...inputs,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: session.getIdToken().jwtToken,
            },
          }
        )
        .then((resp) => {
          setLoading(false);
          if (resp.status === 200) {
            navigate(`/app/AirbusELA/project/${resp.data.id}`);
          }
        })
        .catch((err) => {
          setLoading(false);
          if (err.response) {
            if (err.response.status === 400) {
              NotificationManager.error(err.response.data.error.message, 'Error');
            } else {
              NotificationManager.error('An error has occurred, please try again.', 'Error');
            }
          } else if (err.request) {
            NotificationManager.error('An error has occurred, please try again.', 'Error');
          } else {
            NotificationManager.error('An error has occurred, please try again.', 'Error');
          }
        });
    });
  };

  return (
    <div>
      {loading ? (
        <div>
          <ContentLoading />
        </div>
      ) : (
        <Box display="flex" justifyContent="center">
          <form onSubmit={(e) => submit(e)}>
            <Box display="flex" flexDirection="column" textAlign="center">
              <br />
              <br />
              <Box component="h2">{newProjectData.title}</Box>
              <Box width="70%" m="auto">
                {newProjectData.description}
              </Box>
              <br />
              <TextField
                variant="filled"
                type="text"
                id="name"
                autoFocus
                name="name"
                fullWidth
                required
                label="Project Name"
                onChange={(e) => handleChange(e)}
              />
              <br />
              <Button type={'submit'} color="primary" variant={'contained'}>
                + Create Project
              </Button>
            </Box>
          </form>
        </Box>
      )}
    </div>
  );
};

ProjectListEmpty.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  inputs: PropTypes.array,
  confirmAction: PropTypes.func,
};

ProjectListEmpty.defaultProps = {
  title: 'Dialog Title',
  description: 'The dialog description should describe what this project does.',
  inputs: [
    {
      type: 'text',
      label: 'Text Input',
      id: 'text-input',
    },
  ],
  confirmAction: () => {
    console.log('Created New Project');
  },
};

export default withStyles(NewProjectDialogStyles, { withTheme: true })(
  withRouter(ProjectListEmpty)
);
