import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableBody,
  TableCell,
  TextField,
  Checkbox,
  IconButton,
} from '@material-ui/core/';
import { withStyles } from '@material-ui/styles';
import ClearIcon from '@material-ui/icons/Clear';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import CachedIcon from '@mui/icons-material/Cached';

/** Helper methods */
import { convertPixelsToRem } from '../../../../../helpers';
import { Typography } from '@mui/material';

// used to setup styles for short text fields
const ShortTextField = withStyles((theme) => ({
  root: {
    width: 65,
    '& .MuiInputBase-root': {
      height: 20,
      fontSize: `${convertPixelsToRem(12)} !important`,
    },
  },
}))(TextField);

// used to setup styles for table rows
const StyledTableRow = withStyles((theme) => ({
  root: {
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: '#eff2f8',
    },
  },
}))(TableRow);

// used to setup styles for containers and tables
const useStyles = makeStyles({
  container: {
    borderRight: '1px solid #797979',
    fontSize: `${convertPixelsToRem(12)} !important`,
  },
  table: {
    minWidth: 650,
  },
});

const AirbusSetupTableTwo = (props) => {

  const handleTextfieldChange = async (e, cbIdx, valIdx, props) => {
    // Object Location
    let objectLocation = ''
    if (props.version === 1) {
      // Version 1
      const phaseIdx = props.getPhaseIndex();
      objectLocation = `phases.${phaseIdx}.circuitBreakers.${cbIdx}.${props.load}.${valIdx}`;
    } else if (props.version === 1.1) {
      // Version 1.1
      objectLocation = `circuitBreakers.${cbIdx}.${props.load}.${valIdx}`;
    } else {
      // Version 1.2
      objectLocation = `circuitBreakers.${cbIdx}.postmod.${props.load}.${valIdx}`;
    }

    // Object value (only float and blank strings are allowed)
    let val = parseFloat(e.target.value)
    if (Number.isNaN(val)) {
      // The value is not a valid float -> set to blank
      val = '';
    }

    // Update object
    props.update(val, objectLocation);
  };

  const isSelected = (elecIdent) => props.selectedRows.indexOf(elecIdent) !== -1;

  const isCellDisabled = (circuitBreaker, key) => {
    if (key === '0') {
      return false; // the NOMINAL POWER column is never disabled
    }
    const permInter = circuitBreaker['permInter'];
    return permInter === 'I'
        || (permInter === 'I/P' && props.load === 'dataMaxi')
        || (permInter === 'P/I' && props.load === 'dataOperational');
  };

  const GetField = (circuitBreaker, cbIdx, key, valIdx) => {
    const color = props.getCircuitBreakerColor(cbIdx, props.load, key)

    return  <ShortTextField
              defaultValue={circuitBreaker[props.load][key]}
              variant="outlined"
              onBlur={(e) => handleTextfieldChange(e, cbIdx, valIdx, props)}
              disabled={isCellDisabled(circuitBreaker, key)}
              inputProps={{ style: { color: color}}}
              key={Math.random()} // to force component to refresh (needed by the undo modified changes feature)
            />
  };

  return (
    <TableContainer className={useStyles.container} style={{ height: 500 }}>
      <Table className={useStyles.table} stickyHeader aria-label="simple table">
      <TableHead>
      <TableRow style={{fontWeight:'bold'}}>
        <TableCell align="center"><b>NOMINAL<br />POWER</b></TableCell>
        <TableCell align="center"><b>GROUND</b></TableCell>
        <TableCell align="center"><b>START</b></TableCell>
        <TableCell align="center"><b>ROLL</b></TableCell>
        <TableCell align="center"><b>T/OFF</b></TableCell>
        <TableCell align="center"><b>CLIMB</b></TableCell>
        <TableCell align="center"><b>CRUISE</b></TableCell>
        <TableCell align="center"><b>DESC</b></TableCell>
        <TableCell align="center"><b>LAND</b></TableCell>
        <TableCell align="center"><b>TAXI</b></TableCell>
      </TableRow>
    </TableHead>
        <TableBody>
          {props.data && props.data.circuitBreakers
            ? props.data.circuitBreakers.map((circuitBreakerMain, cbIdx) => {
              const isItemSelected = isSelected(cbIdx);
              // Version >= 1.2
              const circuitBreaker = props.version >= 1.2 ? circuitBreakerMain.postmod : circuitBreakerMain

                return (
                  <StyledTableRow
                    hover
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={cbIdx}
                    selected={isItemSelected}
                    style={{ height: 41 }}
                  >
                    {Object.keys(circuitBreaker[props.load]).map(function (key, valIdx) {
                      return (
                        <TableCell
                          align="center"
                          key={valIdx}
                          style={{
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingRight: 0,
                            paddingLeft: 0,
                            color: isCellDisabled(circuitBreaker, key) ? 'LightGray': props.getCircuitBreakerColor(cbIdx, props.load, key),
                            textDecoration : props.isCircuitBreakerDeleted(cbIdx) ? 'line-through' : 'none'
                          }}
                        >
                          {props.tableUnlocked && !props.isCircuitBreakerDeleted(cbIdx) ? (
                            GetField(circuitBreaker, cbIdx, key, valIdx)
                          ) : (
                            circuitBreaker[props.load][key]
                          )}
                        </TableCell>
                      );
                    })}
                  </StyledTableRow>
                );
              })
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AirbusSetupTableTwo;
