import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, FormControl } from '@material-ui/core';
import StatesSelect from '../../data/states-select';
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import axios from 'axios';
import { cfg } from '../../config';
import { Auth } from 'aws-amplify';

const EditCompanyProfile = (props) => {
  const [data, setData] = React.useState({});
  const [address, setAddress] = React.useState({});
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    Auth.currentSession().then((session) => {
      const idToken = session.getIdToken();
      axios
        .get(`${cfg.apiUrl}/org`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: idToken.jwtToken,
          },
        })
        .then((resp) => {
          setData(resp.data.data);
          setAddress(resp.data.address);
          setOpen(false);
        })
        .catch((e) => {
          console.log(e);
        });
    });
  };

  return (
    <div>
      <IconButton disabled={!props.isAdmin} onClick={() => setOpen(true)}>
        <EditIcon></EditIcon>
      </IconButton>
      <Dialog
        maxWidth="md"
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-basic-info">Edit Company Profile</DialogTitle>
        <DialogContent style={{ overflow: 'hidden' }}>
          <FormControl fullWidth>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  margin="normal"
                  id="street-address"
                  label="Company Address"
                  defaultValue={address.line1}
                  variant="filled"
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  margin="normal"
                  id="city"
                  label="City"
                  defaultValue={address.city}
                  variant="filled"
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <StatesSelect />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  margin="normal"
                  id="zip-code"
                  label="Zip Code"
                  defaultValue={address.postal_code}
                  variant="filled"
                  fullWidth
                />
              </Grid>
            </Grid>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => setOpen(false)} color="primary" variant={'contained'}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditCompanyProfile;
