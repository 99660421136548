// Styling for general Icons behavior

const IconStyles = theme => ({
    icon: {
        fontSize: '48px',
        marginBottom: '8px',
        color: theme.palette.grey['700'],
        '&:hover': {
            color: '#3f51b5',
        }
    },
});

export default IconStyles;