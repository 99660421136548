import { Image, useTheme } from '@aws-amplify/ui-react';

export function Header() {
  const { tokens } = useTheme();

  return (
    <div style={{ textAlign: 'center' }}>
      <Image alt="Aerotage Logo" src={'/aerotage_logo_white.jpg'} padding={tokens.space.medium} />
      <h2>Sign in or sign up to Aerotage</h2>
      <p style={{ marginBottom: '5px' }}>
        Aerotage is a faster, more engaging way to share information.
      </p>
      <div style={{ marginBottom: '25px' }}>
        <a
          href="https://aerotage.com/"
          target="blank_"
          style={{ color: '#09c', textDecoration: 'none' }}
        >
          Learn More
        </a>
      </div>
    </div>
  );
}
