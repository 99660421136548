import React from 'react';
import { CardContent, Typography, IconButton, Box, Grid, Divider, Link } from '@material-ui/core';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import ClearIcon from '@material-ui/icons/Clear';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import WarningIcon from '@mui/icons-material/Warning';
import moment from 'moment';
import { getFilename, downloadFile } from './OutputFiles';


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(255, 0, 0, 0.87)',
    maxWidth: 700,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const OutputDrawer = (props) => {
  const { isOutputDrawerOpened, toggleDrawer, sortedOutputs } = props;
  return (
    <SwipeableDrawer
      anchor="right"
      open={isOutputDrawerOpened}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
    >
      <div tabIndex={0} role="button" onKeyDown={toggleDrawer(false)}>
        <Box m={2} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant={'h6'}>Output Files</Typography>
          <IconButton disableFocusRipple disableRipple onClick={toggleDrawer(false)}>
            <ClearIcon />
          </IconButton>
        </Box>

        <CardContent>
          <Grid container>
            <Grid item m={0.7} sm={12} md={4}>
              <Typography variant={'caption'}>Run Time</Typography>
            </Grid>
            <Grid item sm={12} md={6}>
              <Typography variant={'caption'}>Output Report</Typography>
            </Grid>
            <Grid item sm={12} md={2}>
              <Typography variant={'caption'}>User</Typography>
            </Grid>

            <Box width="100%"></Box>
            <Box width="100%">
              {sortedOutputs.map((file, index) => {
                return (
                  <React.Fragment key={file.runDate}>
                    <Divider />
                    <Box m={0.7} display="flex" flexDirection="row" width="100%">
                      <Grid item sm={12} md={4} xl={4}>
                        <Typography variant={'caption'} fontSize="14px">
                          {moment(file.runDate).format('lll')}
                        </Typography>
                      </Grid>
                      <Grid item sm={12} md={3} xl={3}>
                        <Typography variant={'caption'} fontSize="14px" style={{color: file.warnings && file.warnings.length > 0 ? "red" : "black"}}>
                          {getFilename(file.url)}
                        </Typography>
                        { file.warnings && file.warnings.length > 0 &&
                          <HtmlTooltip
                            title={
                              <React.Fragment>
                                <Typography variant={'body2'} fontSize="14px">ANALYSIS FINISHED WITH THE FOLLOWING WARNINGS:</Typography>
                                {
                                  file.warnings.map(function (warning, idx) {
                                    return (<li key={idx}>{warning}</li>)
                                  })
                                }
                              </React.Fragment>
                            }
                          >
                            <IconButton size="small" color="secondary">
                              <WarningIcon />
                            </IconButton>
                          </HtmlTooltip>
                        }
                      </Grid>
                      <Grid item sm={12} md={1} xl={1}>
                        <Link
                          component="button"
                          variant="body2"
                          onClick={(e) => downloadFile(file.url)}
                        >
                          PDF
                        </Link>
                      </Grid>
                      <Grid item sm={12} md={1} xl={1}>
                        <Link
                          component="button"
                          variant="body2"
                          onClick={(e) => downloadFile(file.csvUrl)}
                        >
                        {file.csvUrl ? "CSV" : ''}
                        </Link>
                      </Grid>
                      <Grid item sm={12} md={1} xl={1}>
                        <Link
                          component="button"
                          variant="body2"
                          onClick={(e) => downloadFile(file.mdUrl)}
                        >
                        {file.mdUrl ? "MD" : ''}
                        </Link>
                      </Grid>
                      <Grid item sm={12} md={2} xl={2}>
                        <Typography variant={'caption'} fontSize="14px">
                          {file.username ? file.username.split('@')[0] : ''}
                        </Typography>
                      </Grid>
                    </Box>
                  </React.Fragment>
                );
              })}
            </Box>
          </Grid>
        </CardContent>
      </div>
    </SwipeableDrawer>
  );
};

export default OutputDrawer;
