import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import withRouter from '../../../../../helpers/withRouter';
import { makeStyles, Grid, Button, Box, Typography, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

/** App theme */
import ELAStyles from '../../../../../styles/jss/components/apps/ELAStyles';

const useStyles = makeStyles(ELAStyles);

const ELAHeader = (props) => {
  const { name, disabled } = props;
  const classes = useStyles();
  const params = useParams();
  const navigate = useNavigate();

  const { submit } = props.functions;

  const handleSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  return (
    <Box width="100%">
      <Grid container>
        <Grid item md={10} xl={10}>
          <Typography variant={'h5'} className={classes.headerTitleContainer}>
            <IconButton
              disableFocusRipple
              disableRipple
              onClick={() => navigate('/app/AirbusELA/project/' + params.projectId)}
            >
              <ClearIcon />
            </IconButton>{' '}
            {name}
          </Typography>
        </Grid>
        <Grid item md={2} xl={2} className={classes.headerRightContent}>
          <Button
            disabled={disabled}
            variant={'contained'}
            color={'primary'}
            onClick={handleSubmit}
          >
            Run Analysis
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default withRouter(ELAHeader);
