import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import ProfileDetails from '../components/profile/ProfileDetails';
import ProfileSettings from '../components/profile/ProfileSettings';
import ContentLoading from '../components/common/ContentLoading';

const Profile = (props) => {
  const [user, setUser] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    Auth.currentAuthenticatedUser().then((resp) => {
      setUser(resp);
      setIsLoading(false);
    });
  };

  const updated = () => {
    fetchData();
  };

  return (isLoading ? (
    <div>
      <ContentLoading />
    </div>
  ) : (
    <div>
      {!Boolean(user) ? (
        <Typography>Loading</Typography>
      ) : (
        <Grid container spacing={3}>
          <Grid item md={4}>
            <ProfileDetails user={user} />
          </Grid>
          <Grid item md={8}>
            <ProfileSettings update={updated} user={user} setIsLoading={setIsLoading} />
          </Grid>
        </Grid>
      )}
    </div>
  ));
};

export default Profile;
