import React from 'react';
import { makeStyles } from '@material-ui/core';
import UserOptions from './UserOptions';
// You can uncomment when notifications and messaging is added
import Hidden from '@material-ui/core/Hidden';
import HelpMenu from './HelpMenu';
import NotificationsMenu from './NotificationsMenu';

const useStyles = makeStyles({
  rightMenuContainer: {
    display: 'flex',
  },
});

const RightMenu = () => {
  const classes = useStyles();

  return (
    <div className={classes.rightMenuContainer}>
      <Hidden>
        <HelpMenu />
        <NotificationsMenu />
      </Hidden>
      <UserOptions />
    </div>
  );
};

export default RightMenu;
