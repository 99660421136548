const MobileSidebarReducer = (state = true, action) => {
  switch (action.type) {
    case 'TOGGLE_MOBILE_SIDEBAR':
      return !state;
    default:
      return state;
  }
};

export default MobileSidebarReducer;
