import React, {Fragment} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableBody,
  TableCell,
  Box,
  Grid,
} from '@material-ui/core/';
import { withStyles } from '@material-ui/styles';
import { styled } from '@mui/system';

/** Helper methods */
import { convertPixelsToRem } from '../../../../../helpers';

const AirbusSumTable = (props) => {
  const useStyles = makeStyles({
    container: {
      borderRight: '1px solid #797979',
      fontSize: `${convertPixelsToRem(12)} !important`,
    },
    table: {
      minWidth: 650,
    },
  });

  const ThinTableCell = styled(TableCell)({
    paddingTop: 5, paddingBottom: 5
  });

  const StyledGrid = withStyles((theme) => ({
    root: {
      '&&& .hideScrollbar::-webkit-scrollbar': {
        background: 'transparent' /* Chrome/Safari/Webkit */,
        width: 0,
      },
    },
  }))(Grid);

  const getTableWidth = () => {
    if (props.nodeData && props.nodeData.circuitBreakers && props.nodeData.circuitBreakers.length > 10) {
      return 'calc(100% - 17px)'; // 17px is the scrollbar's width
    }
    return '100%';
  };

  const getSumRows = () => {
    let sum;

    sum = props.getSumForNode(props.node, props.parentNode, false, true); // skip conversion to show amps for nodes with AC/DC conversion (APUD...)

    // Round values
    sum = props.roundValues(sum)

    // Only keep odd PF columns for DC (where AMPS values are stored)
    if (props.node.current === 'DC') {
      sum = sum.filter((_, index) => {
        return (index % 2 === 1);
      });
    }

    return sum.map(function (_, i) {
      return (
        <TableCell
          align="right"
          width='6.25%'
          colSpan={props.node.current === 'DC' ? 2 : 1}
          key={i}
          style={{
            paddingTop: 10,
            paddingBottom: 10,
            paddingRight: 10,
            paddingLeft: 0,
          }}>
          {sum[i]}
        </TableCell>
      );
    })
  };

  return (
    <StyledGrid container>
      <Grid item md={4}>
        <Box borderRight="1px solid #9E9E9E" borderLeft="1px solid #9E9E9E">
          <TableContainer className={useStyles.container} >
            <Table className={useStyles.table}>
              <TableBody>
                <TableRow>
                  <TableCell
                    align="right"
                    style={{
                      paddingTop: 10,
                      paddingBottom: 10,
                      paddingRight: 30,
                      paddingLeft: 0,
                    }}>
                    <b>BUS LOAD</b>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
      <Grid item md={8}>
        <Box borderRight="1px solid #9E9E9E">
          <TableContainer className={useStyles.container} >
            <Table className={useStyles.table} style={{ width: getTableWidth() }}>
              <TableHead style={{ visibility: 'collapse', border: 'hidden' }}>
                <TableRow>
                  <ThinTableCell align="center" style={{height: 59, borderRight: '1px solid #e0e0e0'}} width='12.5%' colSpan={2}>
                    <b> CONNECTED </b>
                  </ThinTableCell>
                  <ThinTableCell align="center" style={{borderRight: '1px solid #e0e0e0'}} width='12.5%' colSpan={2}>
                    <b> GND OPERTN  </b>
                  </ThinTableCell>
                  <ThinTableCell align="center" style={{borderRight: '1px solid #e0e0e0'}} width='12.5%' colSpan={2}>
                    <b> ENGINE START </b>
                  </ThinTableCell>
                  <ThinTableCell align="center" style={{borderRight: '1px solid #e0e0e0'}} width='12.5%' colSpan={2}>
                    <b> TAXI </b>
                  </ThinTableCell>
                  <ThinTableCell align="center" style={{borderRight: '1px solid #e0e0e0'}} width='12.5%' colSpan={2}>
                    <b> T/O & CLIMB </b>
                  </ThinTableCell>
                  <ThinTableCell align="center" style={{borderRight: '1px solid #e0e0e0'}} width='12.5%' colSpan={2}>
                    <b> CRUISE </b>
                  </ThinTableCell>
                  <ThinTableCell align="center" style={{borderRight: '1px solid #e0e0e0'}} width='12.5%' colSpan={2}>
                    <b style={{ whiteSpace: 'nowrap' }}>HOLD &</b><b> LAND</b>
                  </ThinTableCell>
                  <ThinTableCell align="center" style={{borderRight: '1px solid #e0e0e0'}} width='12.5%' colSpan={2}>
                    <b> STANDBY </b>
                  </ThinTableCell>
                </TableRow>
                <TableRow>
                {
                  [...Array(8)].map((_, i) => {
                    return props.node.current === 'AC' ? (
                      <Fragment key={"table_2_" + i}>
                        <ThinTableCell align="center" style={{height: 39, top: 59, paddingRight: 0}} width='6.25%'>
                          <b> KVA </b>
                        </ThinTableCell>
                        <ThinTableCell align="center" style={{borderRight: '1px solid #e0e0e0', top: 59, paddingRight: 0}} width='6.25%'>
                          <b> PF </b>
                        </ThinTableCell>
                      </Fragment>
                    ) : (
                      <Fragment key={"table_2_" + i}>
                        <ThinTableCell align="center" style={{borderRight: '1px solid #e0e0e0', height: 39, top: 59, paddingRight: 0}} width='6.25%' colSpan={2}>
                          <b> AMPS </b>
                        </ThinTableCell>
                      </Fragment>
                    )
                  })
                }
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow style={{ height: 41 }}>
                  {
                    getSumRows()
                  }
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
    </StyledGrid>
  );
};

export default AirbusSumTable;
