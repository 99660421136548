import React from 'react';
import {
  Card,
  CardContent,
  Grid,
  Button,
  FormControl,
  OutlinedInput,
  InputLabel,
  CardHeader,
} from '@material-ui/core';
import { Auth } from 'aws-amplify';

const ProfileSettings = (props) => {
  const { update } = props;

  const [details, setDetails] = React.useState({
    given_name: '',
    family_name: '',
    phone_number: '',
    address: '',
  });

  React.useEffect(() => {
    const usedAttributes = ['given_name', 'family_name', 'address', 'phone_number'];
    let tempDetail = details;
    usedAttributes.forEach((key) => {
      if (key in props.user.attributes) {
        tempDetail[key] = props.user.attributes[key];
      }
    });
    setDetails({ ...details, ...tempDetail });
  }, [props.user]);

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const updateUser = async (e) => {
    e.preventDefault();
    props.setIsLoading(true);
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, details)
      .then((resp) => {
        update();
        props.setIsLoading(false);
      })
      .catch((e) => console.log(e));
  };

  const formatPhone = (e) => {
    e.target.value.replace('-', '');
    if (!e.target.value.includes('+1')) e.target.value = '+1' + e.target.value;
    return e;
  };

  return (
    <Card>
      <CardHeader title={'User Details'} />
      <form onSubmit={(e) => updateUser(e)}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={4} sm={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="given_name">First Name</InputLabel>
                <OutlinedInput
                  id="given_name"
                  varient={'outlined'}
                  name={'given_name'}
                  onChange={(e) => handleChange(e)}
                  value={details.given_name}
                  label={'First Name'}
                />
              </FormControl>
            </Grid>
            <Grid item md={4} sm={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="family_name">Last Name</InputLabel>
                <OutlinedInput
                  id="family_name"
                  varient={'outlined'}
                  name={'family_name'}
                  onChange={(e) => handleChange(e)}
                  value={details.family_name}
                  label={'Last Name'}
                />
              </FormControl>
            </Grid>
            <Grid item md={4} sm={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="phone_number">Phone</InputLabel>
                <OutlinedInput
                  id="phone_number"
                  varient={'outlined'}
                  name={'phone_number'}
                  type={'tel'}
                  onChange={(e) => handleChange(formatPhone(e))}
                  value={details.phone_number}
                  label={'Phone'}
                />
              </FormControl>
            </Grid>
            <Grid item md={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="address">Address</InputLabel>
                <OutlinedInput
                  id="address"
                  varient={'outlined'}
                  name={'address'}
                  value={details.address}
                  onChange={(e) => handleChange(e)}
                  label={'Address'}
                />
              </FormControl>
            </Grid>
            <Grid item sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant={'contained'} color={'primary'} type={'submit'}>
                Save Info
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </form>
    </Card>
  );
};

export default ProfileSettings;
