import React from 'react';
import { Grid, Box, Card, CardContent, Typography, makeStyles } from '@material-ui/core';

/** App theme */
import ELAStyles from '../../../../../styles/jss/components/apps/ELAStyles';

/** App Data */
//import mock from '../../../../../data/ELA-details-mock';
import EditAnalysisDialog from '../../../../common/EditAnalysisDialog';

/** Helper methods */
import { convertPixelsToRem } from '../../../../../helpers';

const useStyles = makeStyles(ELAStyles);

const AnalysisDetails = (props) => {
  const classes = useStyles();

  const { project, user, lastModifiedBy, name, args, updateData, patchAnalysis, isDisabled } = props;

  return (
    <div className={classes.cardContainer}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography className={classes.cardHeader} variant={'h6'}>
          Analysis Details
        </Typography>
        <Typography component={'span'}>
          <EditAnalysisDialog
            user={user}
            name={name}
            args={args}
            update={updateData}
            patchAnalysis={patchAnalysis}
            isDisabled={isDisabled}
          />
        </Typography>
      </Box>
      <Card>
        <CardContent>
          <Grid container>
            <Grid item md={4}>
              <Typography className={classes.cardHeader} variant={'caption'}>
                Project Name:
              </Typography>
              <Typography variant={'subtitle2'}>{project}</Typography>
            </Grid>
            <Grid item md={4}>
              <Typography className={classes.cardHeader} variant={'caption'}>
                Analysis Name:
              </Typography>
              <Typography variant={'subtitle2'}>{name}</Typography>
            </Grid>
            <Grid item md={4}>
              <Typography className={classes.cardHeader} variant={'caption'}>
                Last Modified By:
              </Typography>
              <Typography variant={'subtitle2'}>{lastModifiedBy ? lastModifiedBy.split('@')[0] : ''}</Typography>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: convertPixelsToRem(20) }}>
            <Grid item md={3}>
              <Typography className={classes.cardHeader} variant={'caption'}>
                AIRCRAFT TYPE
              </Typography>
              <Typography variant={'subtitle2'}>{args.aircraftType}</Typography>
            </Grid>
            <Grid item md={3}>
              <Typography className={classes.cardHeader} variant={'caption'}>
                AIRCRAFT MSN
              </Typography>
              <Typography variant={'subtitle2'}>{args.aircraftMSN}</Typography>
            </Grid>
            <Grid item md={3}>
              <Typography className={classes.cardHeader} variant={'caption'}>
                AIRCRAFT EFFECTIVITY
              </Typography>
              <Typography variant={'subtitle2'}>{args.aircraftEffectivity}</Typography>
            </Grid>
            <Grid item md={3}>
              <Typography className={classes.cardHeader} variant={'caption'}>
                AIRCRAFT TAIL
              </Typography>
              <Typography variant={'subtitle2'}>{args.aircraftTail}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default AnalysisDetails;
