import React from 'react';
import { Grid } from '@material-ui/core';

/** Presentational */
import ApplicationCard from '../components/common/ApplicationCard';

/** App data */
import items from '../data/application-apps';

const Applications = (props) => {
  return (
    <div>
      <Grid container spacing={3}>
        {items.map((item, idx) => (
          <ApplicationCard
            key={idx}
            showDialog={item.showDialog}
            title={item.title}
            subtitle={item.subtitle}
            icon={item.icon}
            dialogOptions={item.dialogOptions}
            link={item.link}
          />
        ))}
      </Grid>
    </div>
  );
};

export default Applications;
