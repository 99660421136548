import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, MenuItem, IconButton, Hidden, Grid, Typography, Divider } from '@material-ui/core';
import withRouter from '../../../helpers/withRouter';
import { signOut } from '../../../helpers';
import PasswordDialog from './PasswordDialog';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import NotificationStyles from '../../../styles/jss/components/NotificationStyles';
import { Auth } from 'aws-amplify';

const UserOptions = (props) => {
  const useStyles = makeStyles(NotificationStyles);
  const classes = useStyles();
  const navigate = useNavigate();

  const StyledMenu = withStyles({})((props) => (
    <Menu
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ));

  // Password Dialog
  const [open, setOpen] = React.useState(false);
  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  // Menu handler
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (action) => {
    switch (action) {
      case 'profile':
        navigate('/profile');
        setAnchorEl(null);
        break;
      case 'password':
        openDialog();
        setAnchorEl(null);
        break;
      default:
        setAnchorEl(null);
    }
  };

  const userInitials = Auth.user ? Auth.user.attributes.email.charAt(0).toUpperCase() : '';

  return (
    <div>
      <PasswordDialog open={open} closeDialog={closeDialog} />
      <IconButton
        size="small"
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={(e) => handleClick(e)}
      >
        <Hidden smUp>
          <MoreVertIcon />
        </Hidden>
        <Hidden xsDown className={classes.root}>
          <Avatar className={classes.grey}>{userInitials}</Avatar>
        </Hidden>
      </IconButton>
      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Grid container spacing={0} className={classes.typography}>
          <Grid item xs={9}>
            <Typography className={classes.header} variant="subtitle1">
              Aerotage
            </Typography>{' '}
            {/*Placeholder for Company name */}
          </Grid>
        </Grid>
        <Divider />
        <MenuItem className={classes.menuSpacing} onClick={() => handleClose('profile')}>
          Profile
        </MenuItem>
        <MenuItem className={classes.menuSpacing} onClick={() => handleClose('password')}>
          Change Password
        </MenuItem>
        <MenuItem className={classes.menuSpacing} onClick={() => signOut(navigate)}>
          Sign Out
        </MenuItem>
      </StyledMenu>
    </div>
  );
};

export default withRouter(UserOptions);
