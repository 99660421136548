// Import layout styles
import { DefaultLayout } from './layouts';

/** Views */
import ForgotPassword from './views/ForgotPassword';
import Profile from './views/Profile';
import Members from './views/Members';
import Home from './views/Home';
import Applications from './views/Applications';
import CompanyProfile from './views/CompanyProfile';
import AirbusELA from './views/AirbusELA';
import AirbusELAProject from './components/Apps/AirbusELA/analysisList/AirbusELAProject';
import AirbusELAAnalysis from './components/Apps/AirbusELA/analysis/AirbusELAAnalysis';
import BoeingELA from './views/BoeingELA';
import BoeingELAProject from './components/Apps/BoeingELA/analysisList/BoeingELAProject';
import BoeingELAAnalysis from './components/Apps/BoeingELA/analysis/BoeingELAAnalysis';



// set available routes
const publicRoutes = [
  {
    path: '/',
    component: Home,
    exact: true,
    layout: DefaultLayout,
    pageTitle: 'Home',
  },
  {
    path: '/app',
    component: Applications,
    exact: true,
    layout: DefaultLayout,
    pageTitle: 'Applications',
  },
  {
    path: '/profile',
    component: Profile,
    exact: true,
    layout: DefaultLayout,
    pageTitle: 'Profile',
  },
  {
    path: '/members',
    component: Members,
    exact: true,
    layout: DefaultLayout,
    pageTitle: 'Members',
  },
  {
    path: '/forgot_password',
    component: ForgotPassword,
    exact: true,
    layout: DefaultLayout,
    pageTitle: 'Forgot Password',
  },
  {
    path: '/company_profile',
    component: CompanyProfile,
    exact: true,
    layout: DefaultLayout,
    pageTitle: 'Company Profile',
  },
  {
    path: '/app/AirbusELA',
    component: AirbusELA,
    exact: true,
    layout: DefaultLayout,
    pageTitle: 'Airbus ELA',
  },
  {
    path: '/app/AirbusELA/project/:id',
    component: AirbusELAProject,
    exact: true,
    layout: DefaultLayout,
    pageTitle: 'ELA Project',
  },
  {
    path: '/app/AirbusELA/analysis/:projectId/:id',
    component: AirbusELAAnalysis,
    exact: true,
    layout: DefaultLayout,
    pageTitle: 'ELA Analysis',
  },
  {
    path: '/app/BoeingELA',
    component: BoeingELA,
    exact: true,
    layout: DefaultLayout,
    pageTitle: 'Boeing ELA Analysis',
  },
  {
    path: '/app/BoeingELA/project/:id',
    component: BoeingELAProject,
    exact: true,
    layout: DefaultLayout,
    pageTitle: 'ELA Project',
  },
  {
    path: '/app/BoeingELA/analysis/:projectId/:id',
    component: BoeingELAAnalysis,
    exact: true,
    layout: DefaultLayout,
    pageTitle: 'ELA Analysis',
  },
];

const privateRoutes = [];

export { publicRoutes, privateRoutes };
