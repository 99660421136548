/* Used in connection with Project List page to delete a project or rename a project */

import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { makeStyles } from '@material-ui/core/styles';
import ProjectListStyles from '../../../../styles/jss/components/apps/ProjectListStyles';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { cfg } from '../../../../config';
import { NotificationManager } from 'react-notifications';

const useStyles = makeStyles(ProjectListStyles);

const ProjectListOptions = (props) => {
  const { updateData } = props;

  const [Loading, setLoading] = React.useState(false);

  // Dropdown open/close logic
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // New name dialog and input logic
  const [newName, setNewName] = React.useState('');
  const changeName = (e) => {
    if (error) setError(null);
    setNewName(e.target.value);
  };
  const [error, setError] = React.useState(null);

  const [renameDialog, setRenameDialog] = React.useState(false);
  const openRenameDialog = () => {
    setRenameDialog(true);
    setAnchorEl(null);
  };
  const closeRenameDialog = () => setRenameDialog(false);

  // Change name API logic
  const sendRename = (e) => {
    e.preventDefault();
    setLoading(true);
    Auth.currentSession().then((session) => {
      axios
        .patch(
          `${cfg.apiUrl}/app/boeing-ela/project/${props.row.id}`,
          {
            name: newName,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: session.getIdToken().jwtToken,
            },
          }
        )
        .then((resp) => {
          if (resp.status === 200) {
            closeRenameDialog();
            setLoading(false);
            updateData();
          }
        })
        .catch((err) => {
          if (err.response) {
            setLoading(false);
            if (err.response.status === 400) {
              NotificationManager.error(err.response.data.error.message, 'Error');
            } else {
              NotificationManager.error('An error has occurred, please try again.', 'Error');
            }
          } else if (err.request) {
            setLoading(false);
            NotificationManager.error('An error has occurred, please try again.', 'Error');
          } else {
            setLoading(false);
            NotificationManager.error('An error has occurred, please try again.', 'Error');
          }
        });
    });
  };

  // Delete dialog logic
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const openDeleteDialog = () => {
    setDeleteDialog(true);
    setAnchorEl(null);
  };
  const closeDeleteDialog = () => setDeleteDialog(false);

  const sendDelete = (e) => {
    e.preventDefault();
    setLoading(true);
    Auth.currentSession().then((session) => {
      axios
        .delete(`${cfg.apiUrl}/app/boeing-ela/project/${props.row.id}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: session.getIdToken().jwtToken,
          },
        })
        .then((resp) => {
          if (resp.status === 200) {
            closeDeleteDialog();
            setLoading(false);
            updateData();
          }
        })
        .catch((err) => {
          setLoading(false);
          if (err.response || err.request) {
            NotificationManager.error('An error has occurred, please try again.', 'Error');
          } else {
            NotificationManager.error('An error has occurred, please try again.', 'Error');
          }
        });
    });
  };

  const ITEM_HEIGHT = 48;
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={deleteDialog}
        onClose={closeDeleteDialog}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.dialogContainer }}
      >
        <DialogTitle id="form-dialog-title">Delete Project</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure to delete <b>{props.row.name}</b>? This action will also delete{' '}
            <b>{props.row.analysisCount} analyses</b> inside the project, and cannot be undone.{' '}
            {/*Please re-enter the project name to proceed*/}
          </DialogContentText>
        </DialogContent>
        <DialogActions classes={{ root: classes.buttonContainer }}>
          <Button disabled={Loading} color="primary" onClick={() => closeDeleteDialog()}>
            Cancel
          </Button>
          <Button
            variant={'contained'}
            disabled={Loading}
            color="primary"
            onClick={(e) => sendDelete(e)}
          >
            Delete Project
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={renameDialog}
        onClose={closeRenameDialog}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.dialogContainer }}
      >
        <DialogTitle id="form-dialog-title">Rename</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <TextField
              type={'text'}
              autoFocus
              variant="filled"
              name={'name'}
              fullWidth
              label={'Project Name'}
              value={newName}
              onChange={(e) => changeName(e)}
            />
          </FormControl>
        </DialogContent>
        <DialogActions classes={{ root: classes.buttonContainer }}>
          <Button disabled={Loading} color="primary" onClick={(e) => closeRenameDialog(e)}>
            Cancel
          </Button>
          <Button disabled={Loading} color="secondary" onClick={(e) => sendRename(e)}>
            Rename
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        <MenuItem onClick={openRenameDialog}>Rename</MenuItem>
        <MenuItem onClick={openDeleteDialog}>
          <Typography color={'error'}>Delete</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ProjectListOptions;
