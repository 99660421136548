// Styling for common/LinkCard component

const LinkCardStyles = (theme) => ({
  rootContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    cursor: 'pointer',
    // figure out where this background color is coming from? Not in palette.
    '&:hover': {
      backgroundColor: '#DAE6F7',
    },
  },
  content: {
    justifyContent: 'center',
    textAlign: 'center',
  },
  title: {
    color: theme.palette.primary.dark,
  },
  subtitle: {
    color: theme.palette.grey['700'],
  },
  icon: {
    fontSize: '48px',
    marginBottom: '8px',
    color: theme.palette.grey['700'],
  },
});

export default LinkCardStyles;
