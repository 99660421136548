import React from 'react';
import { Menu, MenuItem, IconButton, Badge, Tooltip } from '@material-ui/core';
import withRouter from '../../../helpers/withRouter';
import { makeStyles, withStyles } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import IconStyles from '../../../styles/jss/components/common/IconStyles';
import { useTheme } from '@material-ui/core/styles';
import SupportDialog from './SupportDialog';

const HelpMenu = (props) => {
  const useStyles = makeStyles(IconStyles);
  const classes = useStyles();
  const theme = useTheme();

  const StyledMenu = withStyles({})((props) => (
    <Menu
      className={classes.menu}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ));

  // Support Dialog
  const [open, setOpen] = React.useState(false);
  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  // Menu handler
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (e) => {
    window.open('/docs/intro/index.html', '_blank');
  };

  const handleClose = (action) => {
    switch (action) {
      case 'support':
        openDialog();
        setAnchorEl(null);
        break;
      default:
        setAnchorEl(null);
    }
  };

  return (
    <div>
      <SupportDialog open={open} closeDialog={closeDialog} />
      <Tooltip title="Help">
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={(e) => handleClick(e)}
          className={classes.icon}
          color={theme.palette.grey[3]}
        >
          <Badge color="secondary">
            <HelpIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleClose('support')}>Contact Support</MenuItem>
      </StyledMenu>
    </div>
  );
};

export default withRouter(HelpMenu);
