import React from 'react';
import { CardContent, Typography, IconButton, Box, Grid, Divider, Link } from '@material-ui/core';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import ClearIcon from '@material-ui/icons/Clear';
import moment from 'moment';
import { getFilename, downloadFile } from './OutputFiles';

const OutputDrawer = (props) => {
  const { isOutputDrawerOpened, toggleDrawer, sortedOutputs } = props;
  return (
    <SwipeableDrawer
      anchor="right"
      open={isOutputDrawerOpened}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
    >
      <div tabIndex={0} role="button" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
        <Box m={2} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant={'h6'}>Output Files</Typography>
          <IconButton disableFocusRipple disableRipple onClick={toggleDrawer(false)}>
            <ClearIcon />
          </IconButton>
        </Box>

        <CardContent>
          <Grid container>
            <Grid item m={0.7} sm={12} md={4}>
              <Typography variant={'caption'}>Run Time</Typography>
            </Grid>
            <Grid item sm={12} md={4}>
              <Typography variant={'caption'}>Output Report</Typography>
            </Grid>
            <Grid item sm={12} md={4}>
              <Typography variant={'caption'}>User</Typography>
            </Grid>

            <Box width="100%"></Box>
            <Box width="100%">
              {sortedOutputs.map((file, index) => {
                return (
                  <React.Fragment key={file.runDate}>
                    <Divider />
                    <Box m={0.7} display="flex" flexDirection="row" width="100%">
                      <Grid item sm={12} md={4} xl={4}>
                        <Typography variant={'caption'} fontSize="14px">
                          {moment(file.runDate).format('lll')}
                        </Typography>
                      </Grid>
                      <Grid item sm={12} md={4} xl={4}>
                        <Link
                          component="button"
                          variant="body2"
                          onClick={(e) => downloadFile(file)}
                        >
                          {getFilename(file.url)}
                        </Link>
                      </Grid>
                      <Grid item sm={12} md={4} xl={4}>
                        <Typography variant={'caption'} fontSize="14px">
                          {file.username ? file.username.split('@')[0] : ''}
                        </Typography>
                      </Grid>
                    </Box>
                  </React.Fragment>
                );
              })}
            </Box>
          </Grid>
        </CardContent>
      </div>
    </SwipeableDrawer>
  );
};

export default OutputDrawer;
