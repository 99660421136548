import React, { useRef } from 'react';
import { Box, Card, Grid, Button  } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import AirbusVariablesTable from './AirbusVariablesTable';

const lodashClonedeep = require('lodash.clonedeep');

const AnalysisVariables = (props) => {
  const savedCurrentVariables = useRef(null); // to cancel changes when not clicking the Save&Lock button

  const useStyles = makeStyles({
    root: {
      height: '100%',
    },
  });

  const unlock = (event, selection) => {
    const clonedVariables = lodashClonedeep(props.currentVariables);
    savedCurrentVariables.current = clonedVariables; // clone and save current variables
    props.setTableUnlocked((prevState) => !prevState); // unlock table
  };

  const saveAndLock = (event, selection) => {
    props.saveCurrentVariables();
    savedCurrentVariables.current = null; // reset saved variables
    props.setTableUnlocked((prevState) => !prevState); // lock table
  };

  const cancelChanges = (event, selection) => {
    if (savedCurrentVariables.current) {
      props.setCurrentVariables(savedCurrentVariables.current); // revert changes to variables
      props.setTableUnlocked(false); // local table
      savedCurrentVariables.current = null; // reset saved variables
    }
  };

  return (
    <>
      <Card style={{ height: '720px' }}>

        <Box display="flex" p={2} borderBottom="1px solid #9E9E9E">
          <Grid container spacing={3} alignItems="center" >
            <Grid item sm={12} md={10}></Grid>
            <Grid item sm={12} md={2} style={{padding: 2}}>
              <Grid container spacing={1} align="right">
                {props.tableUnlocked ? (
                  <Grid item sm={12} md={2}>
                    <Button color="secondary" style={{ width: '75%' }} onClick={cancelChanges}>
                      CANCEL
                    </Button>
                  </Grid>
                ) : (
                  <Grid item sm={12} md={2}></Grid>
                )}
                <Grid item sm={12} md={10}>
                  <LoadingButton
                    color="primary"
                    style={{ width: '75%' }}
                    disabled={
                      JSON.stringify(props.currentVariables) === JSON.stringify(savedCurrentVariables.current) ||
                      props.isRunning
                    }
                    onClick={props.tableUnlocked ? saveAndLock : unlock}
                    loading={props.isSaving}
                    loadingPosition="start"
                    startIcon={props.tableUnlocked || props.isSaving ? <SaveIcon /> : <LockOpenIcon />}
                  >
                    {props.tableUnlocked || props.isSaving ? 'SAVE & LOCK' : 'UNLOCK TABLE'}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box
          className={useStyles.container}
          borderRight="1px solid #9E9E9E"
          borderBottom="1px solid #9E9E9E"
        >
          <AirbusVariablesTable
            data={props.analysisData}
            tableUnlocked={props.tableUnlocked}
            currentVariables={props.currentVariables}
            setCurrentVariables={props.setCurrentVariables}
          />
        </Box>

      </Card>
    </>
  );
};

export default AnalysisVariables;
