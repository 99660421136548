import React, {Fragment} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableBody,
  TableCell,
  TextField,
} from '@material-ui/core/';
import { withStyles } from '@material-ui/styles';
import { styled } from '@mui/system';

/** Helper methods */
import { convertPixelsToRem } from '../../../../../helpers';

const AirbusSetupTableTwo = (props) => {
  // used to setup styles for short text fields
  const ShortTextField = withStyles((theme) => ({
    root: {
      width: '100%',
      paddingLeft: 2,
      paddingRight: 2,
      '& .MuiInputBase-root': {
        height: 20,
        fontSize: `${convertPixelsToRem(12)} !important`,
      },
    },
  }))(TextField);

  // used to setup styles for table rows
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: '#eff2f8',
      },
    },
  }))(TableRow);

  const ThinTableCell = styled(TableCell)({
    paddingTop: 5, paddingBottom: 5
  });

  // used to setup styles for containers and tables
  const useStyles = makeStyles({
    container: {
      borderRight: '1px solid #797979',
      fontSize: `${convertPixelsToRem(12)} !important`,
    },
    table: {
      minWidth: 650,
    },
  });

  const handleTextfieldChange = async (e, cbIdx, valIdx, props) => {
    let val = e.target.value
    if (val !== '*') {
      val = parseFloat(val); // convert to float except for *
    }
    props.update(val, `circuitBreakers.${cbIdx}.data.${valIdx}`);
  };

  const isSelected = (elecIdent) => props.selectedRows.indexOf(elecIdent) !== -1;

  const isCellDisabled = (circuitBreaker, key) => {
    if (circuitBreaker.disabled) {
      return true;
    }
    if (key === '0') {
      return false; // the NOMINAL POWER column is never disabled
    }
    const permInter = circuitBreaker['permInter'];
    return permInter === 'I'
        || (permInter === 'I/P' && props.load === 'dataMaxi')
        || (permInter === 'P/I' && props.load === 'dataOperational');
  };

  return (
    <TableContainer className={useStyles.container} style={{ height: 563 }}>
      <Table className={useStyles.table} stickyHeader aria-label="simple table">
        <TableHead>
          <TableRow>
            <ThinTableCell align="center" style={{height: 59, borderRight: '1px solid #e0e0e0'}} width='12.5%' colSpan={2}>
              <b> CONNECTED </b>
            </ThinTableCell>
            <ThinTableCell align="center" style={{borderRight: '1px solid #e0e0e0'}} width='12.5%' colSpan={2}>
              <b> GND OPERTN  </b>
            </ThinTableCell>
            <ThinTableCell align="center" style={{borderRight: '1px solid #e0e0e0'}} width='12.5%' colSpan={2}>
              <b> ENGINE START </b>
            </ThinTableCell>
            <ThinTableCell align="center" style={{borderRight: '1px solid #e0e0e0'}} width='12.5%' colSpan={2}>
              <b> TAXI </b>
            </ThinTableCell>
            <ThinTableCell align="center" style={{borderRight: '1px solid #e0e0e0'}} width='12.5%' colSpan={2}>
              <b> T/O & CLIMB </b>
            </ThinTableCell>
            <ThinTableCell align="center" style={{borderRight: '1px solid #e0e0e0'}} width='12.5%' colSpan={2}>
              <b> CRUISE </b>
            </ThinTableCell>
            <ThinTableCell align="center" style={{borderRight: '1px solid #e0e0e0'}} width='12.5%' colSpan={2}>
              <b style={{ whiteSpace: 'nowrap' }}>HOLD &</b><b> LAND</b>
            </ThinTableCell>
            <ThinTableCell align="center" style={{borderRight: '1px solid #e0e0e0'}} width='12.5%' colSpan={2}>
              <b> STANDBY </b>
            </ThinTableCell>
          </TableRow>
          <TableRow>
          {
            [...Array(8)].map((_, i) => {
              return props.node.current === 'AC' ? (
                <Fragment key={"table_2_" + i}>
                  <ThinTableCell align="center" style={{height: 39, top: 59, paddingRight: 0}} width='6.25%'>
                    <b> KVA </b>
                  </ThinTableCell>
                  <ThinTableCell align="center" style={{borderRight: '1px solid #e0e0e0', top: 59, paddingRight: 0}} width='6.25%'>
                    <b> PF </b>
                  </ThinTableCell>
                </Fragment>
              ) : (
                <Fragment key={"table_2_" + i}>
                  <ThinTableCell align="center" style={{borderRight: '1px solid #e0e0e0', height: 39, top: 59, paddingRight: 0}} width='6.25%' colSpan={2}>
                    <b> AMPS </b>
                  </ThinTableCell>
                </Fragment>
              )
            })
          }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            props.getCircuitBreakers()?.map((circuitBreaker, cbIdx) => {
                const isItemSelected = isSelected(cbIdx);

                return (
                  <StyledTableRow
                    hover
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={`circuitBreakers-${cbIdx}`}
                    selected={isItemSelected}
                    style={{ height: 41 }}
                  >
                    {Object.keys(circuitBreaker.data).map(function (key, valIdx) {
                      if ( props.node.current === 'DC' && key % 2 === 0) { // skip even keys (KPA) - AMPS are stored in the PF column
                        return null
                      }

                      return (
                        <TableCell
                          align="right"
                          width='6.25%'
                          colSpan={props.node.current === 'DC' ? 2 : 1}
                          key={valIdx}
                          style={{
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingRight: props.tableUnlocked && !circuitBreaker.disabled ? 0 : 10,
                            paddingLeft: 0,
                            color: isCellDisabled(circuitBreaker, key) ? 'Silver': 'black',
                          }}
                        >
                          {props.tableUnlocked && !circuitBreaker.disabled ? (
                            <ShortTextField
                              defaultValue={circuitBreaker.data[key]}
                              variant="outlined"
                              onBlur={(e) => handleTextfieldChange(e, cbIdx, valIdx, props)}
                              disabled={isCellDisabled(circuitBreaker, key)}
                              InputProps={{
                                inputProps: {
                                    style: { textAlign: "right" },
                                }
                              }}
                            />
                          ) : (
                            props.roundValueAtIndex(circuitBreaker.data[valIdx], valIdx)
                          )}
                        </TableCell>
                      );
                    })}
                  </StyledTableRow>
                );
              })
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AirbusSetupTableTwo;
