import { drawerWidth } from '../../aero.js';

const SidebarStyles = (theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.grey[900],
  },
  selected: {
    fontWeight: 'bold',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
    // Change this once you have theme grey colors
    backgroundColor: '#1d1d1d',
  },
  logo: {
    maxWidth: '150px',
  },
  icons: {
    color: 'white',
  },
  menuItems: {
    color: 'white',
    fontSize: 700,
    '&:hover': {
      //color: 'black',
      backgroundColor: 'rgba(63, 81, 181, 0.6)',
    },
  },
  nested: {
    paddingLeft: theme.spacing(9),
  },
});

export default SidebarStyles;
