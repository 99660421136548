import React, { useRef } from 'react';
import { Box, Card, Grid, Button, IconButton  } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles } from '@material-ui/core/styles';
import SortableTree, { changeNodeAtPath, find, toggleExpandedForAll } from '@nosferatu500/react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-minimal';
import ClearIcon from '@material-ui/icons/Clear';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import { withStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';

/** Containers */
import AirbusSetupTableTwo from './AirbusSetupTableTwo';
import AirbusSetupTableOne from './AirbusSetupTableOne';
import AppTabs from '../../../../common/AppTabs';
import AirbusSumTable from './AirbusSumTable';
import ImportData from "./ImportData";

/** Helper methods */
import { convertPixelsToRem, setNestedValue } from '../../../../../helpers';

/** Css */
import './index.css';

const lodashClonedeep = require('lodash.clonedeep');

const StyledGrid = withStyles((theme) => ({
  root: {
    '&&& .hideScrollbar::-webkit-scrollbar': {
      background: 'transparent' /* Chrome/Safari/Webkit */,
      width: 0,
    },
  },
}))(Grid);

function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

const ShortTextField = withStyles((theme) => ({
  root: {
    width: 85,
    '& .MuiInputBase-root': {
      height: 25,
      fontSize: `${convertPixelsToRem(14)} !important`,
    },
  },
}))(TextField);

const AirbusSetup = (props) => {
  // Node
  const [nodeData, setNodeData] = React.useState({});
  const savedNodeData = useRef(null); // to cancel changes when not clicking the Save&Lock button
  const nodePath = useRef(null); // to remember path of node in tree
  const nodeChildren = useRef([]); // to repopulate children when saving node
  // Phase
  const [phaseData, setPhaseData] = React.useState([]);
  const [showPhaseDrop, setShowPhaseDrop] = React.useState(false);
  const [selectedPhase, setSelectedPhase] = React.useState('A');
  const [selectedRows, setSelectedRows] = React.useState([]); // to setlect and delete multiple rows
  const [deletedRows, setDeletedRows] = React.useState([]);
  // Load
  const [activeLoad, setActiveLoad] = React.useState('dataMaxi'); // load of the active tab

  // Update phaseData when nodeData changes
  React.useEffect(() => {
    if (getVersion() === 1) {
      if (nodeData && nodeData.phases) {
        const data = nodeData.phases.find((phase) => (phase.phase || '') === selectedPhase)
        setPhaseData(data);
      } else {
        setPhaseData([]);
      }
    } else {
      setPhaseData(nodeData ?? []);
    }
  }, [nodeData, selectedPhase]);

  const useStyles = makeStyles({
    root: {
      height: '100%',
    },
  });

  const getVersion = () => {
    if (props.analysisData.version === undefined) {
      return 1; // default version
    }
    return parseFloat(props.analysisData.version);
  }

  const isPhaseNodeAC = (node) => {
    if (isEmpty(node)) {
      return false;
    }
    return node.name.endsWith('XP-A')
        || node.name.endsWith('XP-B')
        || node.name.endsWith('XP-C')
  }

  const isNodeAC = (node) => {
    if (isEmpty(node)) {
      return false;
    }
    if (getVersion() >= 1.1) {
      return isPhaseNodeAC(node)
    } else {
      // Version 1
      return node.name.endsWith('XP');
    }
  };

  const isNodeDC = (node) => {
    return !isEmpty(node) && node.name.endsWith('PP');
  };

  const nodeCanHaveData = (node) => {
    return isNodeAC(node) || isNodeDC(node);
  };

  const handleNodeClick = (node, path) => {
    if (!nodeCanHaveData(node)) {
      // skip nodes with no data
      console.log('skip nodes with no data');
      return;
    }
    // Set new state variables
    const { children, ...nodeWithoutchildren } = lodashClonedeep(node); // clone node to avoid changing tree by mistake
    const selectedPhase = isNodeAC(node) ? 'A' : '';

    setShowPhaseDrop(getVersion() === 1 && isNodeAC(node)); // phase drop was replaced in v1.1 by phase nodes
    props.setTableUnlocked(false);
    setNodeData(nodeWithoutchildren);
    nodeChildren.current = children;
    nodePath.current = path;
    setSelectedPhase(selectedPhase);
    savedNodeData.current = null;
    setSelectedRows([]); // reset selected rows
  };

  const handleSelectPhase = (event, selection) => {
    if (selection) {
      setSelectedPhase(selection);
    }
  };

  const unlock = (event, selection) => {
    const clonedNodeData = lodashClonedeep(nodeData);
    savedNodeData.current = clonedNodeData; // clone and save current node data
    props.setTableUnlocked((prevState) => !prevState); // unlock table
  };

  const saveAndLock = (event, selection) => {
    let currentTreeData = lodashClonedeep(props.treeData); // clone current tree

    // Find all nodes with the same name than the current node
    const { matches: searchMatches } = find({
      treeData: props.treeData,
      getNodeKey: ({ treeIndex }) => treeIndex,
      searchMethod: item => item.node.name === nodeData.name,
    })

    // Update all nodes with the updated node data to keep them in sync
    searchMatches.forEach((match) => {
      currentTreeData = changeNodeAtPath({
        // update tree with updated phases
        treeData: currentTreeData,
        path: match.path,
        getNodeKey: ({ treeIndex }) => treeIndex,
        newNode: { ...nodeData, children: nodeChildren.current },
      })
    })

    // Set shouldBeSaving to true to trigger patchAnalysis after saving node to the tree
    props.shouldBeSaving.current = true;

    // Save tree
    props.setTreeData(currentTreeData);
    savedNodeData.current = null; // reset saved node data
    props.setTableUnlocked((prevState) => !prevState); // lock table
  };

  const cancelChanges = (event, selection) => {
    if (savedNodeData.current) {
      setNodeData(savedNodeData.current); // revert changes to node
      setSelectedRows([]); // reset selected rows
      props.setTableUnlocked(false); // local table
      savedNodeData.current = null; // reset saved node data
    }
  };

  const updateData = (value, objectLocation, forceUpdate = false) => {
    let newNodeData = setNestedValue(nodeData, objectLocation, value);
    setNodeData({ ...newNodeData });
  };

  const getPhaseIndex = () => {
    if (nodeData.phases) {
      return nodeData.phases.findIndex((phase) => (phase.phase || '') === selectedPhase);
    }
    return -1;
  };

  const addRow = () => {
    let newRow;

    if (getVersion() >= 1.2) {
      newRow = {
        postmod: {
          elecIdent: '',
          permInter: 'P',
          c: false,
          s: false,
          panel: '',
          ata100: '',
          designation: '',
          dataMaxi: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          dataOperational: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        }
      };
    } else {
      newRow = {
        elecIdent: '',
        permInter: 'P',
        c: false,
        s: false,
        panel: '',
        ata100: '',
        designation: '',
        dataMaxi: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        dataOperational: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      }
    }

    if (getVersion() >= 1.1) {
      setNodeData((prevState) => ({
        ...prevState,
        circuitBreakers: [...prevState.circuitBreakers ?? [], newRow],
      }));
    } else {
      // Version 1
      const phaseIndex = getPhaseIndex();
      if (
        phaseIndex >= 0 &&
        nodeData.phases[phaseIndex] &&
        nodeData.phases[phaseIndex].circuitBreakers
      ) {
        // add new cb to the circuitBreakers of the selected phase
        setNodeData((prevState) => ({
          ...prevState,
          phases: [
            ...prevState.phases.slice(0, phaseIndex),
            {
              ...prevState.phases[phaseIndex],
              circuitBreakers: [...prevState.phases[phaseIndex].circuitBreakers, newRow],
            },
            ...prevState.phases.slice(phaseIndex + 1),
          ],
        }));
      } else {
        // add new phase to node
        setNodeData((prevState) => ({
          ...prevState,
          phases: [
            ...(prevState.phases || []),
            {
              phase: selectedPhase,
              circuitBreakers: [newRow],
            },
          ],
        }));
      }
    }
  };

  const deleteRows = () => {
    if (getVersion() >= 1.2) {
      setNodeData((prevState) => ({
        ...prevState,
        circuitBreakers: prevState.circuitBreakers.map((cb, id) => {
          if (selectedRows.includes(id)) {
            cb["deleted"] = true; // tag CB as deleted
            return cb;
          } else {
            return cb;
          }
        }),
      }));
    } else if (getVersion() === 1.1) {
      setNodeData((prevState) => ({
        ...prevState,
        circuitBreakers: prevState.circuitBreakers.filter((cb, id) => {
          return !selectedRows.includes(id);
        }),
      }));
    } else {
      // Version 1
      const phaseIndex = getPhaseIndex();

      if (nodeData.phases[phaseIndex] && nodeData.phases[phaseIndex].circuitBreakers) {
        setNodeData((prevState) => ({
          ...prevState,
          phases: [
            ...prevState.phases.slice(0, phaseIndex),
            {
              ...prevState.phases[phaseIndex],
              circuitBreakers: prevState.phases[phaseIndex].circuitBreakers.filter((cb, id) => {
                return !selectedRows.includes(id);
              }),
            },
            ...prevState.phases.slice(phaseIndex + 1),
          ],
        }));
      }
    }
    setSelectedRows([]); // reset selected rows
  };

  const deleteCircuitBreaker = (cbIdx) => {
    setNodeData((prevState) => ({
      ...prevState,
      circuitBreakers: prevState.circuitBreakers.filter((cb, id) => {
        return id !== cbIdx;
      }),
    }));
  };

  const isCircuitBreakerDeleted = (cbIdx) => {
    return nodeData.circuitBreakers
        && nodeData.circuitBreakers[cbIdx]
        && "deleted" in nodeData.circuitBreakers[cbIdx]
        && nodeData.circuitBreakers[cbIdx]["deleted"] === true;
  }

  const isCircuitBreakerAdded = (cbIdx) => {
    return nodeData.circuitBreakers
        && nodeData.circuitBreakers[cbIdx]
        && nodeData.circuitBreakers[cbIdx].postmod
        && !nodeData.circuitBreakers[cbIdx].premod; // added CBs only have a postmod object
  }

  const isCircuitBreakerRevised = (cbIdx) => {
    return nodeData.circuitBreakers
        && nodeData.circuitBreakers[cbIdx]
        && nodeData.circuitBreakers[cbIdx].postmod
        && nodeData.circuitBreakers[cbIdx].premod
        && JSON.stringify(nodeData.circuitBreakers[cbIdx].postmod) !== JSON.stringify(nodeData.circuitBreakers[cbIdx].premod);
  }

  const isCircuitBreakerColumnRevised = (cbIdx, column) => {
    return nodeData.circuitBreakers
        && nodeData.circuitBreakers[cbIdx]
        && nodeData.circuitBreakers[cbIdx].postmod
        && nodeData.circuitBreakers[cbIdx].premod
        && nodeData.circuitBreakers[cbIdx].postmod[column].toString() !== nodeData.circuitBreakers[cbIdx].premod[column].toString();
  }

  const isCircuitBreakerColumnIndexRevised = (cbIdx, column, index) => {
    return nodeData.circuitBreakers
        && nodeData.circuitBreakers[cbIdx]
        && nodeData.circuitBreakers[cbIdx].postmod
        && nodeData.circuitBreakers[cbIdx].premod
        && nodeData.circuitBreakers[cbIdx].postmod[column][index]?.toString() !== nodeData.circuitBreakers[cbIdx].premod[column][index]?.toString();
  }

  const getCircuitBreakerColor = (cbIdx, column = null, index = null) => {
    const defaultColor = "black";
    const revisedColor = "orange";
    if (getVersion() <= 1.1 || !nodeData.circuitBreakers) {
      return defaultColor;
    }
    if (isCircuitBreakerDeleted(cbIdx)) {
      return "red";
    } else if (isCircuitBreakerAdded(cbIdx)) {
      return "green";
    } else if (isCircuitBreakerRevised(cbIdx)) {
      if (column) {
        if (index) {
          if (isCircuitBreakerColumnIndexRevised(cbIdx, column, index)) {
            return revisedColor;
          }
        }
        else if (isCircuitBreakerColumnRevised(cbIdx, column)) {
          return revisedColor;
        }
        return defaultColor;
      } else {
        return revisedColor;
      }
    }
    return defaultColor;
  }


  const getDeleteButtonText = () => {
    const length = selectedRows.length;
    if (length === 1) {
      return 'Delete 1 Row';
    } else if (length > 1) {
      return 'Delete ' + length + ' Rows';
    }
    return 'Delete Row(s)';
  };

  const handleCbFinChange = (e) => {
    updateData(e.target.value, `cbfin`);
  };

  const handleCbRatingChange = (e) => {
    const val = parseFloat(e.target.value); // convert to float
    updateData(val, `capacity`);
  };

  const setActiveTab = (tab) => {
    setActiveLoad(tab === 'maxi' ? 'dataMaxi' : 'dataOperational');
  };

  const expandTree = (expanded) => {
    props.setTreeData((prevState) =>
      toggleExpandedForAll({
        treeData: prevState,
        expanded,
      }));
  }

  const foldTree = () => {
    expandTree(false);
  }

  const unfoldTree = () => {
    expandTree(true);
  }

  const refreshData = async () => {
    await props.refreshData();
  }

  const getNodeClassname = (node) => {
    if (!nodeCanHaveData(node)) {
      return 'non-selectable-node'
    } else if (props.isSaving || props.tableUnlocked) {
      return 'disabled-node'
    }
    return 'selectable-node';
  }

  const hasUnsavedChanges = () => {
    return JSON.stringify(nodeData) !== JSON.stringify(savedNodeData.current);
  }

  const isNodeClickable = (node) => {
    return nodeCanHaveData(node) // The node can have data
        && !(props.isSaving || props.tableUnlocked); // and the table is not being saved or unlocked
  }

  return (
    <>
      <Card style={{ height: '720px' }}>
        <Grid container>
          <Grid item md={3}>
            <Box className={useStyles.container} borderRight="1px solid #9E9E9E">
              <Box
                className={useStyles.header}
                borderBottom="1px solid #9E9E9E"
                p={2}
                display="flex"
                justifyContent="space-between"
              >
                <Typography variant="h6">Busbar Structure</Typography>
                <Grid item md={8} align="right">
                  <ImportData
                    orgID={props.orgID}
                    projectID={props.projectID}
                    analysisID={props.analysisID}
                    disabled={props.isRunning || props.isSaving || props.tableUnlocked}
                    refresh={props.refresh}
                    updateData={props.update}
                    aircraft={props.aircraft}
                    missionMix={props.missionMix}
                    saveDraft={props.saveDraft}
                    refreshData={refreshData}
                    type="add"
                    buttonTitle="Add loads"
                    title="Import New Load Data"
                    text="Please follow the steps below and upload a .csv file filled with new load data. The import will fail if any load from the file already exist or if any buss from the file does not exist."
                    templateName="added_template.csv"
                    templateColumns="PERM/INTER,C,S,BUSBAR,PHASE,LOAD,IDENT.,PANEL,ATA 100,D E S I G N A T I O N ,NOMINAL POWER,GROUND,START,ROLL,T/OFF,CLIMB,CRUISE,DESC,LAND,TAXI"
                    successMessage="Data added succesfully"
                  />
                  <ImportData
                    orgID={props.orgID}
                    projectID={props.projectID}
                    analysisID={props.analysisID}
                    disabled={props.isRunning || props.isSaving || props.tableUnlocked}
                    refresh={props.refresh}
                    updateData={props.update}
                    aircraft={props.aircraft}
                    missionMix={props.missionMix}
                    saveDraft={props.saveDraft}
                    refreshData={refreshData}
                    type="delete"
                    buttonTitle="Delete loads"
                    title="Import Deleted Load Data"
                    text="Please follow the steps below and upload a .csv file filled with deleted load data. The import will fail if any buss or load from the file does not exist."
                    templateName="deleted_template.csv"
                    templateColumns="BUSBAR,PHASE,IDENT."
                    successMessage="Data deleted succesfully"
                  />
                  <IconButton
                    aria-label="unfold tree"
                    color={'primary'}
                    size="small"
                    onClick={unfoldTree}
                    disabled={props.isRunning || props.isSaving || props.tableUnlocked}
                  >
                    <UnfoldMoreIcon />
                  </IconButton>
                  <IconButton
                    aria-label="fold tree"
                    color={'primary'}
                    size="small"
                    onClick={foldTree}
                    disabled={props.isRunning || props.isSaving || props.tableUnlocked}
                  >
                    <UnfoldLessIcon />
                  </IconButton>
                </Grid>

              </Box>
              <SortableTree
                style={{ height: '655px' }}
                treeData={props.treeData}
                onChange={(treeData) => props.setTreeData(treeData)}
                canDrag={false}
                // theme={FileExplorerTheme}
                rowHeight={32}
                generateNodeProps={(rowInfo) => {
                  const { node, path } = rowInfo;
                  return {
                    title: node.name,
                    onClick: () => {
                      if (isNodeClickable(node)) {
                        handleNodeClick(node, path);
                      }
                    },
                    style:
                      node.name === nodeData.name
                        ? {
                            borderLeft: '3px solid #1e50c8',
                            color: '#0050c8',
                            backgroundColor: '#eff2f8',
                          }
                        : {},
                    className: getNodeClassname(node),
                  };
                }}
              />
            </Box>
          </Grid>
          <Grid item md={9}>
            <Grid item md={12}>
              <Box display="flex" p={2} borderBottom="1px solid #9E9E9E">
                <Grid container spacing={3} alignItems="center">
                  <Grid item sm={12} md={2}>
                    <Typography color="textPrimary" variant="h6">
                      Busbar: {nodeData.name}
                    </Typography>
                  </Grid>
                  {showPhaseDrop ? (
                    <Grid item sm={12} md={2}>
                      <Grid container alignItems="center">
                        <Grid>
                          <Typography color="textPrimary" variant="body2" paddingRight="10px">
                            Phase:
                          </Typography>
                        </Grid>
                        <Grid>
                          <ToggleButtonGroup
                            style={{
                              maxWidth: '50px',
                              maxHeight: '30px',
                              minWidth: '50px',
                              minHeight: '30px',
                            }}
                            color="primary"
                            exclusive
                            value={selectedPhase}
                            onChange={handleSelectPhase}
                          >
                            <ToggleButton value="A">A</ToggleButton>
                            <ToggleButton value="B">B</ToggleButton>
                            <ToggleButton value="C">C</ToggleButton>
                          </ToggleButtonGroup>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid item sm={12} md={2}></Grid>
                  )}
                  <Grid item sm={12} md={2}>
                    <Grid container alignItems="center">
                      <Grid>
                        <Typography color="textPrimary" variant="body2" paddingRight="10px">
                          C/B FIN:
                        </Typography>
                      </Grid>
                      <Grid>
                        {props.tableUnlocked ? (
                          <ShortTextField
                            defaultValue={nodeData.cbfin}
                            variant="outlined"
                            onBlur={(e) => handleCbFinChange(e)}
                          />
                        ) : (
                          nodeData.cbfin
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={12} md={3}>
                    <Grid container alignItems="center">
                      <Grid>
                        <Typography color="textPrimary" variant="body2" paddingRight="10px">
                          C/B RATING:
                        </Typography>
                      </Grid>
                      <Grid>
                        {props.tableUnlocked ? (
                          <ShortTextField
                            defaultValue={nodeData.capacity}
                            variant="outlined"
                            onBlur={(e) => handleCbRatingChange(e)}
                          />
                        ) : (
                          nodeData.capacity
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={12} md={3}>
                    <Box display="flex" justifyContent="flex-end">

                      {props.tableUnlocked ? (
                          <Button color="secondary" onClick={cancelChanges}>
                            CANCEL
                          </Button>
                      ) : (
                        <td/>
                      )}

                      <LoadingButton
                        color="primary"
                        disabled={
                          !nodePath.current ||
                          !hasUnsavedChanges() ||
                          props.isRunning
                        }
                        onClick={props.tableUnlocked ? saveAndLock : unlock}
                        loading={props.isSaving}
                        loadingPosition="start"
                        startIcon={props.tableUnlocked || props.isSaving ? <SaveIcon /> : <LockOpenIcon />}
                      >
                        {props.tableUnlocked || props.isSaving ? 'SAVE & LOCK' : 'UNLOCK TABLE'}
                      </LoadingButton>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <ScrollSync>
              <StyledGrid container style={{ height: 549 }}>
                <Grid item md={6} className={'root'} align="right">
                  <Box
                    className={useStyles.container}
                    borderBottom="1px solid #9E9E9E"
                    borderRight="1px solid #9E9E9E"
                    height={48}
                    style={{ paddingTop: 5 }}
                  >
                    <Button
                      disabled={!props.tableUnlocked}
                      color={'primary'}
                      startIcon={<AddIcon />}
                      onClick={addRow}
                    >
                      Add Row
                    </Button>
                    { getVersion() <= 1.1 &&
                      <Button
                        disabled={!props.tableUnlocked || selectedRows.length === 0}
                        color={'primary'}
                        startIcon={<DeleteIcon />}
                        onClick={deleteRows}
                      >
                        {getDeleteButtonText()}
                      </Button>
                    }
                  </Box>
                  <Box
                    className={useStyles.container}
                    borderRight="1px solid #9E9E9E"
                    borderBottom="1px solid #9E9E9E"
                  >
                    <ScrollSyncPane>
                      <AirbusSetupTableOne
                        data={phaseData}
                        tableUnlocked={props.tableUnlocked}
                        update={updateData}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        deletedRows={deleteRows}
                        setDeletedRows={setDeletedRows}
                        isCircuitBreakerAdded={isCircuitBreakerAdded}
                        isCircuitBreakerDeleted={isCircuitBreakerDeleted}
                        isCircuitBreakerRevised={isCircuitBreakerRevised}
                        getCircuitBreakerColor={getCircuitBreakerColor}
                        getPhaseIndex={getPhaseIndex}
                        version={getVersion()}
                        deleteCircuitBreaker={deleteCircuitBreaker}
                      />
                    </ScrollSyncPane>
                  </Box>
                </Grid>
                <Grid item md={6} className={'root'}>
                  <Box
                    className={useStyles.container}
                    borderRight="1px solid #9E9E9E"
                    borderBottom="1px solid #9E9E9E"
                  >
                    <AppTabs
                      setActiveTab={setActiveTab}
                      tabs={{
                        content: [
                          { label: 'MAXI', value: 'maxi' },
                          { label: 'OPERATIONAL', value: 'operational' },
                        ],
                      }}
                      tabPanels={[
                        {
                          render: () => (
                            <ScrollSyncPane>
                              <AirbusSetupTableTwo
                                data={phaseData}
                                load="dataMaxi"
                                tableUnlocked={props.tableUnlocked}
                                update={updateData}
                                selectedRows={selectedRows}
                                isCircuitBreakerDeleted={isCircuitBreakerDeleted}
                                isCircuitBreakerRevised={isCircuitBreakerRevised}
                                isCircuitBreakerColumnIndexRevised={isCircuitBreakerColumnIndexRevised}
                                getCircuitBreakerColor={getCircuitBreakerColor}
                                getPhaseIndex={getPhaseIndex}
                                version={getVersion()}
                              />
                            </ScrollSyncPane>
                          ),
                        },
                        {
                          render: () => (
                            <ScrollSyncPane>
                              <AirbusSetupTableTwo
                                data={phaseData}
                                load="dataOperational"
                                tableUnlocked={props.tableUnlocked}
                                update={updateData}
                                selectedRows={selectedRows}
                                isCircuitBreakerDeleted={isCircuitBreakerDeleted}
                                isCircuitBreakerRevised={isCircuitBreakerRevised}
                                isCircuitBreakerColumnIndexRevised={isCircuitBreakerColumnIndexRevised}
                                getCircuitBreakerColor={getCircuitBreakerColor}
                                getPhaseIndex={getPhaseIndex}
                                version={getVersion()}
                              />
                            </ScrollSyncPane>
                          ),
                        },
                      ]}
                    />
                  </Box>
                </Grid>
              </StyledGrid>
            </ScrollSync>
            <Grid item md={12}>
              <AirbusSumTable
                data={phaseData}
                load={activeLoad}
                getPhaseIndex={getPhaseIndex}
                isCircuitBreakerDeleted={isCircuitBreakerDeleted}
                isCircuitBreakerRevised={isCircuitBreakerRevised}
                getCircuitBreakerColor={getCircuitBreakerColor}
                version={getVersion()}
              />
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default AirbusSetup;
