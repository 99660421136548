const initialState = () => ({
  openItems: [],
});

const SidebarItemsReducer = (state = initialState(), action) => {
  switch (action.type) {
    case 'TOGGLE_SIDEBAR_ITEM':
      let temp = { ...state };
      const openItem = action.payload;
      const index = temp.openItems.indexOf(openItem);
      if (index > -1) {
        temp.openItems.splice(index, 1);
      } else {
        temp.openItems.push(openItem);
      }
      return {
        ...state,
        openItems: temp.openItems,
      };
    default:
      return state;
  }
};

export default SidebarItemsReducer;
