import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import { convertPixelsToRem } from '../../../../../helpers';
import { makeStyles } from '@material-ui/core/styles';
import {
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableBody,
  TableCell,
  MenuItem,
  Checkbox,
  Tooltip,
} from '@material-ui/core/';
import { withStyles } from '@material-ui/styles';
import { styled } from '@mui/system';
import OverflowTip from "./OverflowTip";

const AirbusSetupTableOne = (props) => {
  // used to setup styles for containers and tables
  const useStyles = makeStyles({
    container: {
      borderRight: '1px solid #797979',
      fontSize: `${convertPixelsToRem(12)} !important`,
    },
    table: {
      minWidth: 650,
    },
  });

  // used to setup styles for table rows
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: '#eff2f8',
      },
    },
  }))(TableRow);

  const ThinTableCell = styled(TableCell)({
    paddingTop: 5, paddingBottom: 5
  });

  // used to setup styles for short text fields
  const ShortTextField = withStyles((theme) => ({
    root: {
      width: '100%',
      '& .MuiInputBase-root': {
        height: 20,
        fontSize: `${convertPixelsToRem(12)} !important`,
      },
    },
  }))(TextField);

  // used to setup styles for long text fields
  const LongTextField = withStyles((theme) => ({
    root: {
      width: '100%',
      '& .MuiInputBase-root': {
        height: 20,
        fontSize: `${convertPixelsToRem(12)} !important`,
      },
    },
  }))(TextField);

  // used to setup styles for for drop down menus
  const DropDownTextField = withStyles((theme) => ({
    root: {
      width: 50,
      '& .MuiInputBase-root': {
        height: 20,
        fontSize: `${convertPixelsToRem(12)} !important`,
      },
    },
  }))(TextField);

  const handleTextfieldChange = async (e, cbIdx, column, props) => {
    props.update(e.target.value, `circuitBreakers.${cbIdx}.${column}`);
  };

  const columns = ['ata', 'no', 's', 'nomenclature'];
  const boolColumns = ['s'];
  const longColumns = ['nomenclature'];

  const getField = (circuitBreaker, column, cbIdx) => {
    if (boolColumns.includes(column)) {
      return (
        <DropDownTextField
          select
          defaultValue={circuitBreaker[column]}
          variant="outlined"
          onBlur={(e) => handleTextfieldChange(e, cbIdx, column, props)}
        >
          <MenuItem key="cValueNull" value={false} align="center">
            &nbsp;
          </MenuItem>
          <MenuItem key="cValue" value={true}>
            {column.toUpperCase()}
          </MenuItem>
        </DropDownTextField>
      );
    } else if (longColumns.includes(column)) {
      return (
        <LongTextField
          defaultValue={circuitBreaker[column]}
          variant="outlined"
          onBlur={(e) => handleTextfieldChange(e, cbIdx, column, props)}
        />
      );
    } else if (column === 'permInter') {
      return (
        <DropDownTextField
          select
          defaultValue={circuitBreaker[column]}
          variant="outlined"
          onBlur={(e) => handleTextfieldChange(e, cbIdx, column, props)}
        >
          <MenuItem key="PValue" value={'P'}>P</MenuItem>
          <MenuItem key="IValue" value={'I'}>I</MenuItem>
          <MenuItem key="PValue" value={'P/I'}>P/I</MenuItem>
          <MenuItem key="PValue" value={'I/P'}>I/P</MenuItem>
        </DropDownTextField>
      );
    } else {
      return (
        <ShortTextField
          defaultValue={circuitBreaker[column]}
          variant="outlined"
          onBlur={(e) => handleTextfieldChange(e, cbIdx, column, props)}
        />
      );
    }
  };

  const getValue = (circuitBreaker, column) => {
    if (boolColumns.includes(column)) {
      return circuitBreaker[column] ? column : '';
    } else {
      return circuitBreaker[column];
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked && props.data && props.data.circuitBreakers) {
      const newSelecteds = props.data.circuitBreakers.map((_, i) => i);
      props.setSelectedRows(newSelecteds);
      return;
    }
    props.setSelectedRows([]);
  };

  const handleClick = (event, elecIdent) => {
    const selectedIndex = props.selectedRows.indexOf(elecIdent);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(props.selectedRows, elecIdent);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(props.selectedRows.slice(1));
    } else if (selectedIndex === props.selectedRows.length - 1) {
      newSelected = newSelected.concat(props.selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        props.selectedRows.slice(0, selectedIndex),
        props.selectedRows.slice(selectedIndex + 1)
      );
    }

    props.setSelectedRows(newSelected);
  };

  const isSelected = (elecIdent) => props.selectedRows.indexOf(elecIdent) !== -1;

  const rowCount = () =>
    props.data && props.data.circuitBreakers ? props.data.circuitBreakers.length : 0;

  return (
    <TableContainer className="hideScrollbar" style={{ height: 563 }}>
      <Table className={useStyles.table} stickyHeader aria-label="simple table">
        <TableHead>
          <TableRow>
            {props.tableUnlocked && (
            <TableCell />
            )}
            <ThinTableCell align="center" colSpan={3} style={{height: 59, borderRight: '1px solid #e0e0e0'}}>
              <b> C/B DATA </b>
            </ThinTableCell>
            <ThinTableCell align="center" rowSpan={2} style={{height: 78}} width='60%'>
              <b>NOMENCLATURE</b>
            </ThinTableCell>
          </TableRow>
          <TableRow>
            {props.tableUnlocked && (
              <TableCell padding="checkbox" style={{top: 59}}>
                <Checkbox
                  color="primary"
                  size="small"
                  indeterminate={
                    props.selectedRows.length > 0 && props.selectedRows.length < rowCount()
                  }
                  checked={rowCount() > 0 && props.selectedRows.length === rowCount()}
                  onChange={handleSelectAllClick}
                  inputProps={{
                    'aria-label': 'select all circuit breakers',
                  }}
                />
              </TableCell>
            )}
            <ThinTableCell align="center" width='15%' style={{top: 59, height: 39}}>
              <b> ATA </b>
            </ThinTableCell>
            <ThinTableCell align="center" width='22%' style={{top: 59}}>
              <b> NO. </b>
            </ThinTableCell>
            <ThinTableCell align="center" width='3%' style={{borderRight: '1px solid #e0e0e0', top: 59}}>
              <b>S</b>
            </ThinTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            props.getCircuitBreakers()?.map((circuitBreaker, cbIdx) => {
                const isItemSelected = isSelected(cbIdx);

                return (
                  <StyledTableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={`circuitBreakers-${cbIdx}`}
                    selected={isItemSelected}
                    style={{ height: 41 }}
                  >
                    {props.tableUnlocked && (
                      circuitBreaker.disabled ? (
                      <TableCell padding="checkbox"></TableCell>) : (
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          size="small"
                          checked={isItemSelected}
                          onClick={(event) => handleClick(event, cbIdx)}
                        />
                      </TableCell>
                      )
                    )}
                    {columns.map(function (column) {
                      return (
                        <TableCell
                          align={longColumns.includes(column) ? 'left' : 'center'}
                          key={column}
                          style={{
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingRight: 5,
                            paddingLeft: 5,
                            color: circuitBreaker.disabled ? "Silver" : "black"
                          }}
                        >
                        {props.tableUnlocked && !circuitBreaker.disabled
                          ? getField(circuitBreaker, column, cbIdx)
                          : <OverflowTip
                              value={getValue(circuitBreaker, column)}
                            />
                        }
                        </TableCell>
                      );
                    })}
                  </StyledTableRow>
                );
              })
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AirbusSetupTableOne;
