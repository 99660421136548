import React from 'react';
import { Box, Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  profileContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: '15px',
  },
  profileImage: {
    maxWidth: '50%',
    borderRadius: '50%',
    marginBottom: '8px',
  },
}));

const ProfileDetails = (props) => {
  const { user } = props;
  const classes = useStyles();

  return (
    <Card>
      <CardContent>
        {user.attributes.picture && (
          <div className={classes.profileContainer}>
            <img alt={'profile'} className={classes.profileImage} src={'/empty_profile.jpg'} />
            <Typography varient={'caption'}>Set Image</Typography>
          </div>
        )}
        <Typography variant={'h6'} align={'center'}>
          {user.attributes.email.toUpperCase()}
        </Typography>
        <Box mt={4}>
          <Typography>
            First Name: {user.attributes.given_name ? user.attributes.given_name : 'Add First Name'}
          </Typography>
          <Typography>
            Last Name: {user.attributes.family_name ? user.attributes.family_name : 'Add Last Name'}
          </Typography>
          <Typography>
            Email: {user.attributes.email ? user.attributes.email : 'Add Email'}
          </Typography>
          <Typography>
            Phone: {user.attributes.phone_number ? user.attributes.phone_number : 'Add Phone'}
          </Typography>
          <Typography>
            Address: {user.attributes.address ? user.attributes.address : 'Add Address'}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ProfileDetails;
