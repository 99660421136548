import React from 'react';

/** Presentational */
import LinkCard from '../components/common/LinkCard';

const Home = (props) => {
  return (
    <div>
      <LinkCard
        history={props.history}
        link={'/app'}
        title={'Applications'}
        subtitle={'Run an Analysis'}
        icon={'apps'}
      />
    </div>
  );
};

export default Home;
