import React from 'react';
import { Box, TextField, FormControl, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  actionContainer: {
    display: 'flex',
    paddingTop: 20,
  },
});

const ProjectListTableHeader = (props) => {
  const [search, setSearch] = React.useState('');

  const classes = useStyles();
  const { sortBySearch } = props;

  return (
    <Grid className={classes.actionContainer} container spacing={3}>
      <Grid item md={6} sm={12} xs={12}>
        <Box>
          <FormControl style={{ width: '100%' }}>
            <TextField
              id="standard-adornment-amount"
              label={'Search'}
              name={'name'}
              variant={'outlined'}
              value={search}
              onChange={(e) => {
                sortBySearch(e.target.value);
                setSearch(e.target.value);
              }}
            />
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ProjectListTableHeader;
