import React, { useRef } from 'react';
import { Box, Card, Grid, Button, IconButton  } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles } from '@material-ui/core/styles';
import SortableTree, { getNodeAtPath, changeNodeAtPath, find, toggleExpandedForAll } from '@nosferatu500/react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-minimal';
import ClearIcon from '@material-ui/icons/Clear';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import { withStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';

/** Containers */
import AirbusSetupTableTwo from './AirbusSetupTableTwo';
import AirbusSetupTableOne from './AirbusSetupTableOne';
import AppTabs from '../../../../common/AppTabs';
import AirbusSumTable from './AirbusSumTable';
import ImportData from "./ImportData";

/** Helper methods */
import { convertPixelsToRem, setNestedValue } from '../../../../../helpers';

/** Css */
import './index.css';

const lodashClonedeep = require('lodash.clonedeep');

const StyledGrid = withStyles((theme) => ({
  root: {
    '&&& .hideScrollbar::-webkit-scrollbar': {
      background: 'transparent' /* Chrome/Safari/Webkit */,
      width: 0,
    },
  },
}))(Grid);

const ShortTextField = withStyles((theme) => ({
  root: {
    width: 85,
    '& .MuiInputBase-root': {
      height: 25,
      fontSize: `${convertPixelsToRem(14)} !important`,
    },
  },
}))(TextField);

const startsWithNumber = str => {
  return /^\d/.test(str);
}

const AirbusSetup = (props) => {
  // Node
  const [node, setNode] = React.useState({});
  const [parentNode, setParentNode] = React.useState({});
  const [nodeData, setNodeData] = React.useState({});
  const savedNodeData = useRef(null); // to cancel changes when not clicking the Save&Lock button
  const nodePath = useRef(null); // to remember path of node in tree
  const nodeChildren = useRef([]); // to repopulate children when saving node
  const [selectedRows, setSelectedRows] = React.useState([]); // to setlect and delete multiple rows

  const useStyles = makeStyles({
    root: {
      height: '100%',
    },
  });

  const handleNodeClick = (node, path) => {
    if (node.selectable === false) {
      console.log('skip node that cannot be selected');
      return;
    }

    // Set new state variables
    const nodeCopy = lodashClonedeep(node); // clone node to avoid changing tree bt mistake
    const { children, ...nodeWithoutchildren } = nodeCopy

    props.setTableUnlocked(false);
    setNodeData(nodeWithoutchildren);
    setNode(nodeCopy);

    // Set parent node
    const parentPath = [...path];
    parentPath.pop(); // remove last element
    const parentNode = getNodeAtPath({
        treeData: props.treeData,
        path: parentPath,
        getNodeKey: ({ treeIndex }) => treeIndex,
    }).node;
    setParentNode(parentNode);

    nodeChildren.current = children;
    nodePath.current = path;
    savedNodeData.current = null;
    setSelectedRows([]); // reset selected rows
  };

  // const handleSelectPhase = (event, selection) => {
  //   if (selection) {
  //     setSelectedPhase(selection);
  //   }
  // };

  const unlock = (event, selection) => {
    const clonedNodeData = lodashClonedeep(nodeData);
    savedNodeData.current = clonedNodeData; // clone and save current node data
    props.setTableUnlocked((prevState) => !prevState); // unlock table
  };

  const saveAndLock = (event, selection) => {
    let currentTreeData = lodashClonedeep(props.treeData); // clone current tree

    // Find all nodes with the same name than the current node
    const { matches: searchMatches } = find({
      treeData: props.treeData,
      getNodeKey: ({ treeIndex }) => treeIndex,
      searchMethod: item => item.node.name === nodeData.name,
    })

    // Update all nodes with the updated node data to keep them in sync
    searchMatches.forEach((match) => {
      currentTreeData = changeNodeAtPath({
        // update tree with updated phases
        treeData: currentTreeData,
        path: match.path,
        getNodeKey: ({ treeIndex }) => treeIndex,
        newNode: { ...nodeData, children: nodeChildren.current },
      })
    })

    // Set shouldBeSaving to true to trigger patchAnalysis after saving node to the tree
    props.shouldBeSaving.current = true;

    // console.log(currentTreeData)

    // Save tree
    props.setTreeData(currentTreeData);
    savedNodeData.current = null; // reset saved node data
    props.setTableUnlocked((prevState) => !prevState); // lock table
  };

  const cancelChanges = (event, selection) => {
    if (savedNodeData.current) {
      setNodeData(savedNodeData.current); // revert changes to node
      setSelectedRows([]); // reset selected rows
      props.setTableUnlocked(false); // local table
      savedNodeData.current = null; // reset saved node data
    }
  };

  const updateData = (value, objectLocation, forceUpdate = false) => {
    let newNodeData = setNestedValue(nodeData, objectLocation, value);
    setNodeData({ ...newNodeData });
  };

  const addRow = () => {
    let newRow = {
      ata: '',
      no: '',
      nomenclature: '',
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    };

    if (nodeData.circuitBreakers) {
      // add new cb to the circuitBreakers of the selected phase
      setNodeData((prevState) => ({
        ...prevState,
        circuitBreakers: [...prevState.circuitBreakers, newRow],
      }));
    } else {
      // add new phase to node
      setNodeData((prevState) => ({
        ...prevState,
        circuitBreakers: [newRow],
      }));
    }
  };

  const deleteRows = () => {
    if (nodeData.circuitBreakers) {
      setNodeData((prevState) => ({
        ...prevState,
        circuitBreakers: prevState.circuitBreakers.filter((cb, id) => {
          return !selectedRows.includes(id);
        }),
      }));
      setSelectedRows([]); // reset selected rows
    }
  };

  const getDeleteButtonText = () => {
    const length = selectedRows.length;
    if (length === 1) {
      return 'Delete 1 Row';
    } else if (length > 1) {
      return 'Delete ' + length + ' Rows';
    }
    return 'Delete Row(s)';
  };

  const expandTree = (expanded) => {
    props.setTreeData((prevState) =>
      toggleExpandedForAll({
        treeData: prevState,
        expanded,
      }));
  }

  const foldTree = () => {
    expandTree(false);
  }

  const unfoldTree = () => {
    expandTree(true);
  }

  const refreshData = async () => {
    await props.refreshData();
  }

  const endsWithAny = (string, suffixes) => {
    return suffixes.some(function (suffix) {
        return string.endsWith(suffix);
    });
  }

  const groupBy = (xs, key) => {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  const computeKwKvar = (kva, pf, efficiency=1) => {
    let kw = kva * pf;
    kw = Math.abs(kw); // to handle negative pf values
    let kvar = Math.pow(Math.pow(kva, 2) - Math.pow(kw, 2), 0.5);
    kvar = kvar * Math.sign(pf); // to handle negative pf values
    kw = kw / efficiency;
    return {kw, kvar};
  }

  const computeKvaPf = (kw, kvar) => {
    let kva = Math.pow(Math.pow(kw, 2) + Math.pow(kvar, 2), 0.5);
    let pf = kva > 0 ? kw / kva : 0;

    return {kva, pf};
  }

  const computeSumAC = (data, efficiency=1) => {
    let sum = Array(16).fill(0);

    // Loop over (kva, pf) groups
    for (let i = 0; i < 8; i++) {
      const kva_index = i * 2
      const pf_index = i * 2 + 1

      // Compute KW and KVAR
      let kw_sum = 0;
      let kvar_sum = 0;
      data.forEach(row => {
        const res = computeKwKvar(row[kva_index], row[pf_index], efficiency)
        kw_sum = kw_sum +  res.kw;
        kvar_sum = kvar_sum +  res.kvar;
      })

      // Compute KVA and PF
      const res = computeKvaPf(kw_sum, kvar_sum);
      sum[kva_index] += res.kva;
      sum[pf_index] += res.pf;
    }

    return sum;
  }

  const computeSumDC = (data) => {
    let sum = Array(16).fill(0);

    // AMPS are stored in the PF column
    for (let i = 0; i < 16; i++) {
      // const pf_index = i * 2 + 1
      sum[i] += data
        .reduce((partial_sum, row) => {
          const val = row[i]
          if (typeof val === 'number') { // skip * values
            return partial_sum + val;
          }
          return partial_sum
        }, 0);
    }

    return sum;
  }

  const get_XXABC_loads = (node, parentNode) => {
    if (node.phase === '-3') {
      // Get df of adjacent nodes A/B/C
      const adjacent_nodes = parentNode.children.filter((child) => ['A', 'B', 'C'].includes(child.phase))
      const adjacent_node_cbs = adjacent_nodes.filter((n) => n.circuitBreakers).map((n) => n.circuitBreakers)

      // Filter busbars that end with A/B/C
      const adjacent_nodes_df_with_XXABC_loads = adjacent_node_cbs.map((cbs) => cbs.filter((cb) => cb.no && endsWithAny(cb.no, ['A', 'B', 'C'])))

      // Remove empty dfs???

      // Skip cases where not all 3 A/B/C phases have data (otherwise, their sum doesn't appear under the 3 phase node)
      if (adjacent_nodes_df_with_XXABC_loads.length === 3) {
        // Merge loads in 1 df
        let merged_loads = adjacent_nodes_df_with_XXABC_loads.flat();
        merged_loads = merged_loads.map((cb) => {return { ...cb, no: cb.no.replace(/[ABC]/g, '')}}) // get base busbar No. by removing the last character A/B/C

        // Group by no
        let grouped_loads = groupBy(merged_loads, 'no')
        grouped_loads = Object.entries(grouped_loads).map(([k,v]) => v) // replace (k,v) by v
        grouped_loads = grouped_loads.map((group) => {
          return {
            ...group[0],
            ata: '**',
            disabled: true,
            data: computeSumAC([group[0].data, group[1].data, group[2].data])
          }
        })
        return grouped_loads

      }
    }
    return [];
  }

  const hasAcDcConversion = (node) => {
    return node.conversion && node.conversion.pf
  }

  const getSumForNode = (node, parentNode, exclude_XXABC_loads=true, skipConversion=false) => {
    let data;

    // Initialize data
    data = [Array(16).fill(0)];

    // Add current node's data
    if (node.circuitBreakers) {
      let circuitBreakers = node.circuitBreakers.filter(cb => cb.no !== '-') // exclude dash loads (DR1GLY...)
      let circuitBreakers_data = circuitBreakers.map(cb => cb.data)
      data = data.concat(circuitBreakers_data)
    }

    // Add XXA/B/C busbars from A/B/C nodes as XX under the adjacent XX-3 node
    if (!exclude_XXABC_loads) {
      const xxLoads = get_XXABC_loads(node, parentNode)
      xxLoads.forEach((xxLoad) => {
        data = data.concat([xxLoad.data])
      })
    }

    // Add children nodes' data
    if (node.children) {
      for (let i = 0; i < node.children.length; i++) {
        const child_sum = getSumForNode(node.children[i], node)
        data = data.concat([child_sum])
      }
    }

    // Compute sum
    let sum;
    if (node.current === 'AC' && !hasAcDcConversion(node)) { // AC nodes with AC/DC conversion have DC children
      let efficiency = 1
      if (node.conversion && node.conversion.efficiency) {
        efficiency = node.conversion.efficiency
      }
      sum = computeSumAC(data, efficiency);
    } else {
      sum = computeSumDC(data);
    }

    // Conversion
    if (!skipConversion && hasAcDcConversion(node)) { //
      const pf = node.conversion.pf;

      // Compute multiplier
      let mult = pf;
      if (node.conversion.efficiency) {
        mult *= node.conversion.efficiency
      }

      // Convert DC to AC
      for (let i = 0; i < 8; i++) {
        const kva_index = i * 2
        const pf_index = i * 2 + 1

        const amps = sum[pf_index] // amps is stored in the PF column
        const kva = (amps * 28) / (mult * 1000)
        sum[kva_index]= kva
        sum[pf_index] = pf
      }

    }

    return sum
  }

  function addTrailingZeros(value, precision) {
    return value.toFixed(precision);
  }

  const roundValue = (value, precision) => {
    var multiplier = Math.pow(10, precision || 0);
    return addTrailingZeros(Math.round(value * multiplier) / multiplier, precision);
  };

  const roundValueAtIndex = (value, index) => {
    if (value === '*')
      return value
    // Round KVA to 3 digits and PF/AMPS to 2 digits
    return (index % 2 === 0) ? roundValue(value, 3) : roundValue(value, 2);
  }

  const roundValues = (values) => {
    return values.map((v, i) => {
      return roundValueAtIndex(v, i);
    })
  }

  const getCircuitBreakers = () => {
    let cbs = []
    // Add own cbs
    if (nodeData && nodeData.circuitBreakers) {
      cbs = cbs.concat(nodeData.circuitBreakers)
    }

    // Add XXABC cbs
    const xxLoads = get_XXABC_loads(node, parentNode)
    if (xxLoads) {
      cbs = cbs.concat(xxLoads)
    }

    // Add children cbs
    if (node.children) {
      for (let i = 0; i < node.children.length; i++) {
        const child_node = node.children[i]
        const child_sum = getSumForNode(child_node, node)

        // Get nomenclature
        let child_nomenclature = child_node.description;
        if (!child_nomenclature) {
          child_nomenclature = node.description;
          if (child_node.phase === '-3') {
            child_nomenclature += " -3 PH"
          } else {
            child_nomenclature += ` PH ${child_node.phase}`
          }
        }

        // Create cb row
        const child_cb = {
          ata: '**',
          no: child_node.cb,
          s: false,
          nomenclature: child_nomenclature,
          disabled: true,
          data: child_sum
        }
        cbs = cbs.concat([child_cb])
      }
    }

    return cbs ? cbs : null;
  }

  const getNodeClassname = (node, currentNode) => {
    if (node.name === currentNode.name) {
      return 'selected-node'
    } else if (node.selectable === false) {
      return 'non-selectable-node';
    } else if (node.canHaveData === false) {
      return 'no-data-node'
    }
    return '';
  }

  return (
    <>
      <Card style={{ height: '720px' }}>
        <Grid container>
          <Grid item md={3}>
            <Box className={useStyles.container} borderRight="1px solid #9E9E9E">
              <Box
                className={useStyles.header}
                borderBottom="1px solid #9E9E9E"
                p={2}
                display="flex"
                justifyContent="space-between"
              >
                <Typography variant="h6">Busbar Structure</Typography>
                <ImportData
                  orgID={props.orgID}
                  projectID={props.projectID}
                  analysisID={props.analysisID}
                  disabled={props.isRunning || props.isSaving || props.tableUnlocked}
                  refresh={props.refresh}
                  updateData={props.update}
                  aircraft={props.aircraft}
                  missionMix={props.missionMix}
                  saveDraft={props.saveDraft}
                  refreshData={refreshData}/>
                <Grid item md={2} align="right">
                  <IconButton
                    aria-label="unfold tree"
                    color={'primary'}
                    size="small"
                    onClick={unfoldTree}
                  >
                    <UnfoldMoreIcon />
                  </IconButton>
                  <IconButton
                    aria-label="fold tree"
                    color={'primary'}
                    size="small"
                    onClick={foldTree}
                  >
                    <UnfoldLessIcon />
                  </IconButton>
                </Grid>

              </Box>
              <SortableTree
                style={{ height: '655px' }}
                treeData={props.treeData}
                onChange={(treeData) => props.setTreeData(treeData)}
                canDrag={false}
                // theme={FileExplorerTheme}
                rowHeight={32}
                generateNodeProps={(rowInfo) => {
                  const { node, path } = rowInfo;
                  return {
                    title: node.name,
                    onClick: () => {
                      handleNodeClick(node, path);
                    },
                    style:
                      node.name === nodeData.name
                        ? {
                            // borderLeft: '3px solid #B1D4EC',
                            color: '#ffff00',
                          }
                        : {},
                    className: getNodeClassname(node, nodeData),
                  };
                }}
              />
            </Box>
          </Grid>
          <Grid item md={9} style={{position: 'relative'}}>
            {nodeData.name && (
            <Grid item md={12}>
              <Box display="flex" p={2} borderBottom="1px solid #9E9E9E" height="69px">
                <Grid container spacing={3} alignItems="center">
                  <Grid item sm={12} md={5}>
                    <Grid container direction="row" alignItems="center">
                      <Grid item>
                        <Typography color="textPrimary" height="22px" paddingRight="25px">
                          {nodeData.name}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography color="textPrimary" variant="h6">
                          {nodeData.description}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={12} md={3}>
                    <Grid container alignItems="center">
                      <Grid item>
                        <Typography color="textPrimary" variant="body2" height="18px" paddingRight="10px">
                          PARENT BUS:
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography color="textPrimary" variant="subtitle1" height="26px">
                          {startsWithNumber(nodeData.cb) ? "C" + nodeData.cb : nodeData.cb}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={12} md={4}>
                    <Grid container spacing={1} justifyContent="flex-end">
                      {props.tableUnlocked && (
                        <Grid>
                          <Button onClick={cancelChanges}>
                            CANCEL
                          </Button>
                        </Grid>)}
                        <Grid>
                          <LoadingButton
                            color="primary"
                            disabled={
                              !nodePath.current ||
                              node.selectable === false ||
                              node.canHaveData === false ||
                              JSON.stringify(nodeData) === JSON.stringify(savedNodeData.current) ||
                              props.isRunning
                            }
                            onClick={props.tableUnlocked ? saveAndLock : unlock}
                            loading={props.isSaving}
                            loadingPosition="start"
                            startIcon={props.tableUnlocked || props.isSaving ? <SaveIcon /> : <LockOpenIcon />}
                          >
                            {props.tableUnlocked || props.isSaving ? 'SAVE & LOCK' : 'UNLOCK TABLE'}
                          </LoadingButton>
                        </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            )}
            {nodeData.name && (
            <ScrollSync>
              <StyledGrid container style={{ height: 611 }}>
                <Grid item md={4} className={'root'} align="right">
                  <Box
                    className={useStyles.container}
                    borderBottom="1px solid #9E9E9E"
                    height={48}
                    style={{ paddingTop: 5 }}
                  >
                  </Box>
                  <Box
                    className={useStyles.container}
                    borderRight="1px solid #9E9E9E"
                    borderBottom="1px solid #9E9E9E"
                  >
                    <ScrollSyncPane>
                      <AirbusSetupTableOne
                        data={nodeData}
                        tableUnlocked={props.tableUnlocked}
                        update={updateData}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        getCircuitBreakers={getCircuitBreakers}
                      />
                    </ScrollSyncPane>
                  </Box>
                </Grid>
                <Grid item md={8} className={'root'} align="right">
                  <Box
                    className={useStyles.container}
                    borderBottom="1px solid #9E9E9E"
                    borderRight="1px solid #9E9E9E"
                    height={48}
                    style={{ paddingTop: 5 }}
                  >
                    <Button
                      disabled={!props.tableUnlocked}
                      color={'primary'}
                      startIcon={<AddIcon />}
                      onClick={addRow}
                    >
                      Add Row
                    </Button>
                    <Button
                      disabled={!props.tableUnlocked || selectedRows.length === 0}
                      color={'primary'}
                      startIcon={<DeleteIcon />}
                      onClick={deleteRows}
                    >
                      {getDeleteButtonText()}
                    </Button>
                  </Box>
                  <Box
                    className={useStyles.container}
                    borderRight="1px solid #9E9E9E"
                    borderBottom="1px solid #9E9E9E"
                  >
                    <ScrollSyncPane>
                      <AirbusSetupTableTwo
                        data={nodeData}
                        current={nodeData.current}
                        load="data"
                        tableUnlocked={props.tableUnlocked}
                        update={updateData}
                        selectedRows={selectedRows}
                        node={node}
                        getCircuitBreakers={getCircuitBreakers}
                        roundValueAtIndex={roundValueAtIndex}
                      />
                    </ScrollSyncPane>
                  </Box>
                </Grid>
              </StyledGrid>
            </ScrollSync>
            )}
            {nodeData.name && (
            <Grid item md={12}>
              <AirbusSumTable
                nodeData={nodeData}
                current={nodeData.current}
                node={node}
                parentNode={parentNode}
                getSumForNode={getSumForNode}
                roundValues={roundValues}
              />
            </Grid>
            )}
            {!nodeData.name && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, color: 'DimGray', backgroundColor: 'rgba(0, 0, 0, 0.06)'}}
              >
                <Typography fontSize="200%">
                  Please select a bus in the tree
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default AirbusSetup;
