// Styling for common/ApplicationCardStyles component

const ApplicationCardStyles = (theme) => ({
  rootContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  headerRoot: {
    display: 'flex',
    alignItems: 'middle',
  },
  cardHeader: {
    fontSize: theme.typography.h6.fontSize,
    color: theme.palette.primary.main,
  },
  title: {
    color: theme.palette.primary.dark,
  },
  subtitle: {
    color: theme.palette.grey['700'],
  },
  icon: {
    fontSize: theme.typography.h3.fontSize,
    color: theme.palette.primary.main,
  },
  linkActionContainer: {
    justifyContent: 'flex-end',
  },
  linkActionText: {
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
  },
});

export default ApplicationCardStyles;
