import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { withStyles } from '@material-ui/core/styles';

/** Helper methods */
//import { a11yProps } from '../../helpers';

function AppTabs(props) {
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const {
    tabs: {
      content,
      //extraInfoTab
    },
    tabPanels,
  } = props;

  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.setActiveTab(content[newValue].value);
  };

  /*
  const handleChangeIndex = (index) => {
    setValue(index);
  };
  */

  const CustomTab = withStyles({
    root: {
      '&.Mui-selected': {
        backgroundColor: '#eff2f8',
      },
    },
  })(Tab);

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        {content.map((tab, index) => (
          <CustomTab
            key={`tab-react-key-${tab.label}`}
            label={tab.label}
            style={{ borderRight: '1px solid #9E9E9E', borderBottom: '1px solid #9E9E9E' }}
          />
        ))}
        <Tab
          label={
            <div>
              <InfoOutlinedIcon
                style={{ verticalAlign: 'middle', color: theme.palette.grey['700'] }}
              />
              &nbsp; All values in VA
            </div>
          }
          disabled
          style={{
            borderRight: '1px solid #9E9E9E',
            borderBottom: '1px solid #9E9E9E',
            fontSize: '10px',
            color: theme.palette.grey['700'],
          }}
        />
      </Tabs>
      {tabPanels.map((panel, index) => (
        <TabPanel
          key={`tab-panel-react-key-${index}`}
          value={value}
          index={index}
          dir={theme.direction}
        >
          {panel.render()}
        </TabPanel>
      ))}
    </>
  );
}

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

AppTabs.propTypes = {
  tabs: PropTypes.shape({
    content: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ),
  }),
  tabPanels: PropTypes.arrayOf(
    PropTypes.shape({
      render: PropTypes.func.isRequired,
    })
  ),
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default AppTabs;
