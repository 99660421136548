import React, { useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
  //useTheme,
} from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { makeStyles } from '@material-ui/core/styles';
import ProjectStyles from '../../../../styles/jss/components/apps/ProjectStyles';
import { cfg } from '../../../../config';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { NotificationManager } from 'react-notifications';
//import {useNavigate} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(ProjectStyles);

const ProjectOptions = (props) => {
  const classes = useStyles();
  const { updateData, row, project } = props;
  const { handleViewAll /*handleSendFiles*/ } = props.functions;
  const { handleSendFiles } = props.outputFiles;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Duplicate Project dialog logic
  const [duplicateName, setDuplicateName] = React.useState(row.name + ' [copy]');
  const changeDuplicateName = (e) => setDuplicateName(e.target.value);

  const [duplicateDialog, setDuplicateDialog] = React.useState(false);
  const openDuplicateDialog = () => {
    setDuplicateDialog(true);
    setAnchorEl(null);
  };
  const closeDuplicateDialog = () => setDuplicateDialog(false);

  const sendDuplicate = () => {
    props.setIsLoading(true);
    Auth.currentSession().then((session) => {
      axios
        .post(
          `${cfg.apiUrl}/app/boeing-ela/analysis/duplicate`,
          {
            name: duplicateName,
            username: props.user.attributes.email,
            id: row.id,
            projectId: project,
          },
          {
            headers: {
              Authorization: session.getIdToken().jwtToken,
              'Content-Type': 'application/json',
            },
          }
        )
        .then((resp) => {
          props.setIsLoading(false);
          if (resp.status === 200) {
            closeDuplicateDialog();
            console.log('Duplicated ' + row.name + ' with name ' + duplicateName);
            updateData();
          } else {
            props.setIsLoading(false);
            console.log('Something went wrong please try again.');
          }
        })
        .catch((e) => {
          props.setIsLoading(false);
          console.log(e);
          if (e.response || e.request) {
            NotificationManager.error(
              'Something went wrong, please see console for details.',
              'ERROR'
            );
          } else {
            NotificationManager.error(
              'Something went wrong, please see console for details.',
              'ERROR'
            );
          }
        });
    });
  };

  // Delete dialog logic
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const openDeleteDialog = () => {
    setDeleteDialog(true);
    setAnchorEl(null);
  };
  const closeDeleteDialog = () => setDeleteDialog(false);

  const sendDelete = () => {
    // Axios DELETE request API logic
    props.setIsLoading(true);
    Auth.currentSession().then((session) => {
      axios
        .delete(`${cfg.apiUrl}/app/boeing-ela/analysis/${project}/${row.id}`, {
          headers: {
            Authorization: session.getIdToken().jwtToken,
            'Content-Type': 'application/json',
          },
        })
        .then((resp) => {
          props.setIsLoading(false);
          if (resp.status === 200) {
            closeDeleteDialog();
            console.log('Deleted ' + row.name);
            updateData();
          } else {
            closeDeleteDialog();
            NotificationManager.error(
              'Something went wrong, please see console for details.',
              'ERROR'
            );
            console.log(resp);
          }
        })
        .catch((e) => {
          props.setIsLoading(false);
          console.log(e);
          if (e.response || e.request) {
            NotificationManager.error(
              'Something went wrong, please see console for details.',
              'ERROR'
            );
          } else {
            NotificationManager.error(
              'Something went wrong, please see console for details.',
              'ERROR'
            );
          }
        });
    });
  };

  const ITEM_HEIGHT = 48;

  //const [analysisResults, setAnalysisResults] = useState([]);
  const analysisResults = [];
  //const rowID = '';

  const handleSubmit = (e) => {
    e.preventDefault();
    handleViewAll();
    const rowID = row.id;
    //console.log("Row ID from props: ", row.id);

    //console.log("Row ID: ", rowID);
    //const [row, setRow] = useState([]);
    handleSendFiles(rowID);

    // will use to grab output files to send to Output files drawer for display
    return new Promise((resolve, reject) => {
      Auth.currentSession().then((session) => {
        axios
          .get(`${cfg.apiUrl}/app/boeing-ela/analysis/${project}/${row.id}`, {
            headers: {
              Authorization: session.getIdToken().jwtToken,
              'Content-Type': 'application/json',
            },
          })
          .then((resp) => {
            //console.log("resp: ", resp.data.Item.results.files);
            const analysisResults = resp.data.Item.results.files;
            //analysisResults && console.log("Send AnalysisResults: ", analysisResults);
            //const [loading, setLoading] = React.useState(false);
            //this.handleSendFiles(analysisResults);
          })
          .catch((e) => {
            console.log(e);
            NotificationManager.error(
              'Something went wrong when fetching data. Please check console for more info.',
              'ERROR'
            );
            reject(e);
          });
        //analysisResults && console.log("Send AnalysisResults: ", analysisResults);
      });
    });
  };

  // uesed to handle View onClick
  const navigate = useNavigate();
  const handleOnClick = () => navigate('/app/BoeingELA/analysis/' + project + '/' + row.id);

  //console.log("Outside analysisResults: ", analysisResults);

  return (
    <div>
      <Dialog
        open={deleteDialog}
        onClose={closeDeleteDialog}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.dialogContainer }}
      >
        <DialogTitle id="form-dialog-title">Delete Analysis</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete <b>"{row.name}"</b>. This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions classes={{ root: classes.buttonContainer }}>
          <Button color={'default'} onClick={() => closeDeleteDialog()}>
            Cancel
          </Button>
          <Button onClick={() => sendDelete()} color="primary" variant={'contained'}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={duplicateDialog}
        onClose={closeDuplicateDialog}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.dialogContainer }}
      >
        <DialogTitle id="form-dialog-title">Clone Analysis</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter a name for the cloned analysis. The name must be unique.
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <FormControl fullWidth>
            <TextField
              type={'text'}
              autoFocus
              name={'name'}
              fullWidth
              label={'New Name'}
              value={duplicateName}
              onChange={(e) => changeDuplicateName(e)}
            />
          </FormControl>
        </DialogContent>
        <DialogActions classes={{ root: classes.buttonContainer }}>
          <Button color={'default'} onClick={() => closeDuplicateDialog()}>
            Cancel
          </Button>
          <Button onClick={() => sendDuplicate()} color="primary" variant={'contained'}>
            Clone
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        <MenuItem onClick={handleOnClick}>View</MenuItem>
        <MenuItem onClick={(e) => handleSubmit(e)}>Output Files</MenuItem>
        <MenuItem onClick={openDuplicateDialog}>Clone</MenuItem>
        <MenuItem onClick={openDeleteDialog}>
          <Typography color={'error'}>Delete</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ProjectOptions;
