import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Box,
  Divider,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import NewProjectDialogStyles from '../../styles/jss/components/common/NewProjectDialogStyles';
import withRouter from '../../helpers/withRouter';
import { getBase64 } from '../../helpers';
import CloseIcon from '@mui/icons-material/Close';

const InviteMemberDialog = (props) => {
  const [inputs, setInputs] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [args, setArgs] = React.useState([]);
  const [items, setItems] = React.useState([]);
  const [value, setValue] = React.useState('');
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    let tempInputs = {};
    for (let i = 0; i < props.inputs.length; i++) {
      tempInputs[props.inputs[i].id] = '';
    }
    setInputs(tempInputs);
  }, []);

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const previousInputs = inputs;
    previousInputs[name] = value;
    setInputs(previousInputs);
  };

  const handleFileChange = async (files) => {
    // Update state callback
    const updateInputFileState = (file) => {
      const previousInputs = inputs;
      previousInputs['inputFile'] = file; // base64
      setInputs(previousInputs);
    };

    // Reset inputFile if the input file is removed from the UI
    if (files.length === 0) {
      updateInputFileState(''); // reset inputFile
      return;
    }

    // Get the input file as base64 and update the state with the callback
    getBase64(files[0], updateInputFileState); // prints the base64 string
  };

  const handleKeyDown = (evt) => {
    if (['Enter', 'Tab', ','].includes(evt.key)) {
      evt.preventDefault();

      var value = value.trim();

      if (value && isValid(value)) {
        setItems([...items, value]);
        setValue('');
      }
    }
  };

  const handleDelete = (item) => {
    setItems(items.filter((i) => i !== item));
  };

  const handlePaste = (evt) => {
    evt.preventDefault();

    var paste = evt.clipboardData.getData('text');
    var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

    if (emails) {
      var toBeAdded = emails.filter((email) => !isInList(email));
      setItems([...items, ...toBeAdded]);
    }
  };

  const isValid = (email) => {
    let error = null;

    if (isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (error) {
      setError(error);

      return false;
    }

    return true;
  };

  const isInList = (email) => {
    return items.includes(email);
  };

  const isEmail = (email) => {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  };

  const submit = (e) => {};

  const { title } = props;
  const { classes, handleClose } = props;

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      classes={{ paper: classes.dialogContainer }}
    >
      <form onSubmit={(e) => submit(e)}>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <Divider />
        <DialogContent>
          Enter one or more email addresses to invite people to this organization.
          <br />
          <br />
          <FormLabel id="demo-row-radio-buttons-group-label">Email Addresses:</FormLabel>
          <Box mb={2}>
            <FormControl fullWidth>
              <br />
              <>
                {items.map((item) => (
                  <div className="tag-item" key={item}>
                    {item}
                    <button type="button" className="button" onClick={() => handleDelete(item)}>
                      <CloseIcon sx={{ fontSize: 15 }} />
                    </button>
                  </div>
                ))}
                <textarea
                  className={'input ' + (error && ' has-error')}
                  rows={8}
                  value={value}
                  placeholder="Type or paste email addresses and press `Enter`..."
                  onKeyDown={handleKeyDown}
                  onChange={handleChange}
                  onPaste={handlePaste}
                />
                {error && <p className="error">{error}</p>}
              </>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogContent>
          <FormLabel id="demo-row-radio-buttons-group-label">Invite As:</FormLabel>
          <RadioGroup
            row
            aria-labelledby="row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel value="member" control={<Radio />} label="Member" />
            <FormControlLabel value="admin" control={<Radio />} label="Admin" />
          </RadioGroup>
        </DialogContent>
        <DialogActions classes={{ root: classes.buttonContainer }}>
          <Button disabled={loading} color={'default'} onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button
            disabled={loading}
            /*type={'submit'}*/ onClick={() => handleClose()}
            color="primary"
            variant={'contained'}
          >
            Invite
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

InviteMemberDialog.propTypes = {
  title: PropTypes.string,
  inputs: PropTypes.array,
  confirmAction: PropTypes.func,
  open: PropTypes.bool,
};

InviteMemberDialog.defaultProps = {
  title: 'Dialog Title',
  inputs: [
    {
      type: 'text',
      label: 'Text Input',
      id: 'text-input',
    },
    {
      type: 'select',
      label: 'Select Input',
      id: 'option-select',
      options: [
        {
          value: '123',
          label: 'Option 1',
        },
      ],
    },
  ],
  confirmAction: () => {
    console.log('Invite Member Dialog');
  },
  open: false,
};

export default withStyles(NewProjectDialogStyles, { withTheme: true })(
  withRouter(InviteMemberDialog)
);
