// Styling for apps/AirbusELA/ProjectList component

const ProjectListStyles = (theme) => ({
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tableContainer: {
    marginTop: '30px',
    height: '100%',
    position: 'relative',
  },
  selectInput: {
    '&:focus': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
  },
  dialogContainer: {
    [theme.breakpoints.down('xs')]: {
      width: '95%',
      margin: '0',
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: '35%',
    },
  },
  buttonContainer: {
    padding: '16px 24px',
  },
});

export default ProjectListStyles;
