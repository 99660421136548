import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Card, CardContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import withRouter from '../../../../helpers/withRouter';

/** Presentational */
import ContentLoading from '../../../common/ContentLoading';
import AnalysisDetails from './AnalysisDetails';
import AnalysisFrontmatter from './AnalysisFrontmatter'
import AnalysisVariables from './AnalysisVariables';
import { OutputFiles } from './OutputFiles';
import AirbusSetup from './AirbusSetup/';
import ELAHeader from './Header';
import OutputDrawer from './OutputDrawer';
import { NotificationManager } from 'react-notifications';
import LinearProgress from '@mui/material/LinearProgress';
import { Box, Tab, Tabs, Typography } from '@mui/material';

/** App Data */
//import mock from '../../../../data/ELA-details-mock';
import { Auth } from 'aws-amplify';
import { cfg } from '../../../../config';
import axios from 'axios';

/** Helper methods */
import { setNestedValue, checkForData } from '../../../../helpers';

/** App theme */
import ELAStyles from '../../../../styles/jss/components/apps/ELAStyles';

const AirbusELAAnalysis = (props) => {
  const params = useParams();

  // Data
  const orgId = useRef('');
  const [user, setUser] = React.useState(null);
  const [projectName, setProjectName] = React.useState('');
  const [analysisData, setAnalysisData] = React.useState({});
  const [treeData, setTreeData] = React.useState([]);
  const [frontmatter, setFrontmatter] = React.useState({text: '', name: ''}); // the name is the filename when a file is imported, otherwise blank for a manual edit
  const [currentDocument, setCurrentDocument] = React.useState({});
  const [savedCurrentDocument, setSavedCurrentDocument] = React.useState({});
  const [documents, setDocuments] = React.useState([]);
  const [currentVariables, setCurrentVariables] = React.useState({});
  const [analysisArgs, setAnalysisArgs] = React.useState({
    name: '',
    aircraftMSN: '',
    aircraftType: '',
    aircraftEffectivity: '',
    aircraftTail: '',
  });
  const[lastModifiedBy, setLastModifiedBy] = React.useState('');
  const [analysisResults, setAnalysisResults] = React.useState([]);

  // States
  const [isLoading, setIsLoading] = React.useState(true);
  const [isSaving, setIsSaving] = React.useState(false);
  const [isRunning, setIsRunning] = React.useState(false);
  const [isEditDialogOpened, setIsEditDialogOpened] = React.useState(false);
  const [isOutputDrawerOpened, setIsOutputDrawerOpened] = React.useState(false);
  const [loadsTableUnlocked, setLoadsTableUnlocked] = React.useState(false);
  const [frontmatterTableUnlocked, setFrontmatterTableUnlocked] = React.useState(false);
  const [variablesTableUnlocked, setVariablesTableUnlocked] = React.useState(false);
  const shouldBeSaving = useRef(false);
  const shouldBeSavingText = useRef(false);
  const [tabIndex, setTabIndex] = React.useState(0);

  // Running
  const [progressNumber, setProgressNumber] = React.useState('');
  // const runningStatus = useRef('');
  const executionIdFromRun = useRef('');
  const apiTimeout = useRef(''); // sets the timeout for progress calls

  React.useEffect(() => {
    fetchData()
      .then(() => {
        fetchProjectInfo();
        fetchUserInfo();
        reconnectProgress();
      })
      .catch((e) => console.log(e));
    // Cleanup function
    return () => {
      clearTimeout(apiTimeout.current);
    };
  }, []);

  // Patch analysis when updating the tree if shouldBeSaving is true
  React.useEffect(() => {
    if (shouldBeSaving.current) {
      // Patch analysis
      const updatedData = {
        data: {
          ...analysisData,
          structure: treeData[0], // only saves the first root node
        },
        lastModifiedBy: user.attributes.email,
      };
      patchAnalysis(updatedData).then(() => {
        shouldBeSaving.current = false;
      });
    }
  }, [treeData]);

  // Patch analysis when updating the front matter text if shouldBeSaving is true
  React.useEffect(() => {
    if (shouldBeSavingText.current) {
      console.log("##### SAVE TEXT")

      // const newText = text
      console.log(analysisData)
      const newVersion = analysisData.next_document_version

      // Create document
      Auth.currentSession().then((session) => {
        setIsSaving(true);
        axios
          .post(
            `${cfg.apiUrl}/app/airbus-ela/document`,
            {
              projectId: params.projectId,
              analysisId: params.id,
              name: newVersion,
              data: {
                frontmatter: frontmatter.text,
                name: frontmatter.name,
              }
            },
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: session.getIdToken().jwtToken,
              },
            }
          )
          .then((resp) => {
            if (resp.status === 200) {
              const newDocumentId = resp.data.id;
              const newDocument = {
                id: newDocumentId,
                name: resp.data.name,
                data: {
                  frontmatter: frontmatter.text,
                  name: frontmatter.name,
                }
              }

              // Add document to list of documents (alternative to fetching entire analysis with documents)
              setDocuments([...documents, newDocument])

              // Patch analysis next_document_version and current_document_id
              const newAnalysisData = {
                ...analysisData,
                next_document_version: analysisData.next_document_version + 1, // increment counter
                current_document_id: newDocumentId,
              };
              const updatedData = {
                data: newAnalysisData,
                lastModifiedBy: user.attributes.email,
              };
              patchAnalysis(updatedData).then(() => {
                // Update local analysis
                setAnalysisData(newAnalysisData)
                setCurrentDocument(newDocument);
                setSavedCurrentDocument(newDocument);
                shouldBeSavingText.current = false;
              })
              .finally(() => {
                setIsSaving(false);
              })

            } else {
              NotificationManager.warning(
                'Something went wrong, please check console for response.',
                'WARNING'
              );
            }
          })
          .catch((e) => {
            console.log(e);
          })
      });
    }
  }, [frontmatter]);

  React.useEffect(() => {
    if (loadsTableUnlocked || frontmatterTableUnlocked || variablesTableUnlocked || isSaving || isRunning) {
      window.onbeforeunload = () => true // show popup "Changes you made may not be saved"
    } else {
      window.onbeforeunload = undefined // do not show popup
    }
  }, [loadsTableUnlocked, frontmatterTableUnlocked, variablesTableUnlocked, isSaving, isRunning])

  const reconnectProgress = async () => {
    Auth.currentSession().then((session) => {
      axios
        .get(`${cfg.apiUrl}/app/airbus-ela/progress/${params.projectId}/${params.id}`, {
          headers: {
            Authorization: session.getIdToken().jwtToken,
            'Content-Type': 'application/json',
          },
        })
        .then((resp) => {
          if (resp.status === 200) {
            const runningStatus = resp.data.Item.status.name;
            // Reconnect if the run is in progress (status == RUNNING)
            if (runningStatus === 'RUNNING') {
              NotificationManager.info('Reconnecting to running analysis');
              setIsRunning(true);
              setProgressNumber(resp.data.Item.status.progress);
              executionIdFromRun.current = resp.data.Item.status.executionId;
              apiTimeout.current = setTimeout(getRunProgress, 3000);
            }
          } else {
            NotificationManager.error(
              'Something went wrong when fetching progress. Please check console for more info.',
              'ERROR'
            );
          }
        })
        .catch((e) => {
          clearTimeout(apiTimeout.current);
          console.log(e);
          NotificationManager.error(
            'Something went wrong when fetching progress. Please check console for more info.',
            'ERROR'
          );
        });
    });
  };

  // logic to get satus of the analysis run for display in linear bar
  const getRunProgress = async () => {
    Auth.currentSession().then((session) => {
      axios
        .get(`${cfg.apiUrl}/app/airbus-ela/progress/${params.projectId}/${params.id}`, {
          headers: {
            Authorization: session.getIdToken().jwtToken,
            'Content-Type': 'application/json',
          },
        })
        .then((resp) => {
          if (resp.status === 200) {
            apiTimeout.current = setTimeout(getRunProgress, 3000);
            const runningStatus = resp.data.Item.status.name;

            // Check if the progress returned corresponds to the current run
            if (executionIdFromRun.current === resp.data.Item.status.executionId) {
              if (runningStatus === 'SUCCESS') {
                // The run is finished
                NotificationManager.success('Analysis was run succesfully', 'SUCCESS');
                setIsRunning(false);
                setProgressNumber(100); // Set progress to 100%
                clearTimeout(apiTimeout.current);
                refreshData();
              } else if (runningStatus === 'WARNING') {
                // The run is finished
                const message = resp.data.Item.status.message;
                NotificationManager.warning('Analysis finished with warnings: ' + message, 'WARNING', 60000);
                setIsRunning(false);
                setProgressNumber(100); // Set progress to 100%
                clearTimeout(apiTimeout.current);
                refreshData();
              } else {
                // The run is in progress
                if (resp.data.Item.status.progress !== 100) {
                  // avoid issue with progress stuck at 100% at the start of run (based on previous progress file not deleted?)
                  setProgressNumber(resp.data.Item.status.progress);
                }
              }
            }
          } else {
            clearTimeout(apiTimeout.current);
            NotificationManager.error(
              'Something went wrong when fetching progress. Please check console for more info.',
              'ERROR'
            );
          }
        })
        .catch((e) => {
          clearTimeout(apiTimeout.current);
          console.log(e);
          NotificationManager.error(
            'Something went wrong when fetching progress. Please check console for more info.',
            'ERROR'
          );
        });
    });
  };

  const fetchUserInfo = () => {
    Auth.currentAuthenticatedUser().then((resp) => {
      setUser(resp);
    });
  }

  // fetch project info for display
  const fetchProjectInfo = () => {
    Auth.currentSession().then((session) => {
      axios
        .get(`${cfg.apiUrl}/app/airbus-ela/project/${params.projectId}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: session.getIdToken().jwtToken,
          },
        })
        .then((resp) => {
          setProjectName(resp.data.name);
        })
        .catch((e) => {
          console.log(e);
        });
    });
  };

  // logic for fetching data pertaining to analysis ID
  const fetchData = async () => {
    return new Promise((resolve, reject) => {
      Auth.currentSession().then((session) => {
        setIsLoading(true);
        axios
          .get(`${cfg.apiUrl}/app/airbus-ela/analysis/${params.projectId}/${params.id}`, {
            headers: {
              Authorization: session.getIdToken().jwtToken,
              'Content-Type': 'application/json',
            },
          })
          .then((resp) => {
            if (resp.status === 200) {
              console.log(resp.data)
              orgId.current = resp.data.pk.slice(4);
              setAnalysisResults(checkForData(resp, 'data.results.files', []));
              setAnalysisData(resp.data.data);
              setAnalysisArgs({
                name: resp.data.name,
                aircraftMSN: checkForData(resp, 'data.args.aircraftMSN', null),
                aircraftType: checkForData(resp, 'data.args.aircraftType', null),
                aircraftEffectivity: checkForData(resp, 'data.args.aircraftEffectivity', null),
                aircraftTail: checkForData(resp, 'data.args.aircraftTail', null),
              });
              setLastModifiedBy(resp.data.lastModifiedBy);
              setTreeData([resp.data.data.structure]);
              setDocuments(resp.data.documents);
              const savedCurrentDocument = resp.data.documents.find((d) => d.id === resp.data.data.current_document_id)
              setSavedCurrentDocument(savedCurrentDocument);
              setCurrentDocument(savedCurrentDocument);
              if (savedCurrentDocument) { // for backward compatibility (before documents were added)
                const frontmatter = {text: savedCurrentDocument.data.frontmatter, name: savedCurrentDocument.data?.name ?? ''};
                setFrontmatter(frontmatter);
              }
              const currentVariables = resp.data.data.variables;
              setCurrentVariables(currentVariables);
              resolve();
            } else {
              reject();
              NotificationManager.error(
                'Something went wrong when fetching data. Please check console for more info.',
                'ERROR'
              );
            }
          })
          .catch((e) => {
            console.log(e);
            NotificationManager.error(
              'Something went wrong when fetching data. Please check console for more info.',
              'ERROR'
            );
            reject(e);
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
    });
  };

  // // used to save the changes prior to patch call
  // const handleSavingChange = (isSaving) => {
  //   setIsSaving(isSaving);
  // };

  // logic used to run the analysis via a post call to run
  const runAnalysis = async () => {
    Auth.currentSession().then((session) => {
      NotificationManager.info('Preparing to start analysis');
      setIsRunning(true);
      axios
        .post(
          `${cfg.apiUrl}/app/airbus-ela/run`,
          {
            id: params.id,
            projectId: params.projectId,
            org: orgId.current,
            username: user.attributes.email,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: session.getIdToken().jwtToken,
            },
          }
        )
        .then((resp) => {
          if (resp.status === 200) {
            executionIdFromRun.current = resp.data.executionId;
            setProgressNumber(0); // intitialize progress to 0 (to show progress bar)
          }
        })
        .catch((err) => {
          console.log('Error: ', err);
          if (err.response) {
            if (err.response.status === 400) {
              NotificationManager.error(err.response.data.error.message, 'Error');
            } else {
              NotificationManager.error('An error has occurred, please try again.', 'Error');
            }
          } else if (err.request) {
            NotificationManager.error('An error has occurred, please try again.', 'Error');
          } else {
            NotificationManager.error('An error has occurred, please try again.', 'Error');
          }
        });
    });
    await sleep(5000); // used to pause before checking status for display
    NotificationManager.info('Analysis run has started'); // lets the user know the analysis has started
    getRunProgress(); // starts the process of getting an update on the run for showing the user status via linear bar
  };

  // logic to show the user a linear status bar
  const LinearProgressWithLabel = (props) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  };

  // used to refresh the data for display
  const refreshData = async () => {
    await fetchData();
  };

  // used to update the data
  const updateData = (value, objectLocation) => {
    let newArgs = setNestedValue(analysisArgs, objectLocation, value);
    setAnalysisArgs(newArgs);
  };

  // logic for the patch API call
  const patchAnalysis = async (updatedData) => {
    setIsSaving(true);
    return new Promise((resolve, reject) => {
      Auth.currentSession().then((session) => {
        console.log(`${cfg.apiUrl}/app/airbus-ela/analysis/${params.projectId}/${params.id}`);
        axios
          .patch(`${cfg.apiUrl}/app/airbus-ela/analysis/${params.projectId}/${params.id}`, updatedData, {
            headers: {
              Authorization: session.getIdToken().jwtToken,
              'Content-Type': 'application/json',
            },
          })
          .then((resp) => {
            resolve();
            setLastModifiedBy(user.attributes.email);
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 400) {
                NotificationManager.error(err.response.data.error.message, 'Error');
              } else {
                NotificationManager.error('An error has occurred, please try again.', 'Error');
              }
            } else if (err.request) {
              NotificationManager.error('An error has occurred, please try again.', 'Error');
            } else {
              NotificationManager.error('An error has occurred, please try again.', 'Error');
            }
            reject();
          })
          .finally(() => {
            setIsSaving(false);
          });
      });
    });
  };

  /*
    Mission Mix methods
        When a missionMix is selected, the form component will populate the form.
        If the form has already been manipulated, changing mission mix will create a new empty form.
        These require the user to confirm mission mix change if form is manipulated.
     */

  // logic to toggle the View All output files
  const toggleDrawer = (open) => () => {
    setIsOutputDrawerOpened(open);
  };

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const handleVersionChange = (e) => {
    const currentDocumentIndex = e.target.value
    const newCurrentDocument = documents.find((d) => d.name === currentDocumentIndex)
    setCurrentDocument(newCurrentDocument);
    const frontmatter = {text: newCurrentDocument.data.frontmatter, name: newCurrentDocument.data?.name ?? ''}
    setFrontmatter(frontmatter);
  };

  const saveCurrentDocument = (e) => {
    setIsSaving(true);

    // Patch analysis current_document_id
    const newAnalysisData = {
      ...analysisData,
      current_document_id: currentDocument.id,
    };
    const updatedData = {
      data: newAnalysisData,
      lastModifiedBy: user.attributes.email,
    };
    patchAnalysis(updatedData).then(() => {
      // Update local analysis
      setAnalysisData(newAnalysisData)

      setSavedCurrentDocument(currentDocument);
    }).finally(() => {
      setIsSaving(false);
    });
  }

  const saveCurrentVariables = (e) => {
    setIsSaving(true);

    // Patch analysis variables
    const newAnalysisData = {
      ...analysisData,
      variables: currentVariables,
    };
    const updatedData = {
      data: newAnalysisData,
      lastModifiedBy: user.attributes.email,
    };
    patchAnalysis(updatedData).then(() => {
      // Update local analysis
      setAnalysisData(newAnalysisData)

      setSavedCurrentDocument(currentDocument);
    }).finally(() => {
      setIsSaving(false);
    });
  }

  // used to sort the output files under View All drawer
  let sortedOutputs = analysisResults.sort(
    (a, b) =>
      new Date(...b.runDate.split('/').reverse()) - new Date(...a.runDate.split('/').reverse())
  );

  return isLoading ? (
    <div>
      <ContentLoading />
    </div>
  ) : (
    <div>
      {progressNumber !== '' && progressNumber !== 100 && (
        <LinearProgressWithLabel value={progressNumber} />
      )}
      <ELAHeader
        name={analysisArgs.name}
        functions={{ submit: runAnalysis }}
        disabled={isRunning || isSaving || loadsTableUnlocked || frontmatterTableUnlocked || variablesTableUnlocked}
      />
      <Grid container spacing={2}>
        <Grid item sm={12} md={6}>
          <AnalysisDetails
            project={projectName}
            user={user}
            lastModifiedBy={lastModifiedBy}
            name={analysisArgs.name}
            args={analysisArgs}
            updateData={updateData}
            patchAnalysis={patchAnalysis}
            isDisabled={isRunning || isSaving}
          />
        </Grid>
        <Grid item sm={12} md={4}>
          <OutputFiles
            analysisResults={sortedOutputs}
            functions={{ handleViewAll: toggleDrawer(true) }}
          />
        </Grid>
        <Grid item sm={12} md={12}>
          <Box>
            <Tabs value={tabIndex} onChange={handleTabChange}>
              <Tab label="Inputs" disabled={isSaving || frontmatterTableUnlocked || variablesTableUnlocked}/>
              <Tab label="Front matter" disabled={isSaving || loadsTableUnlocked || variablesTableUnlocked}/>
              <Tab label="Variables" disabled={isSaving || loadsTableUnlocked || frontmatterTableUnlocked}/>
            </Tabs>
          </Box>
          <Box sx={{ padding: 2 }}>
            {tabIndex === 0 && (
              <AirbusSetup
                user={user}
                orgID={orgId.current}
                projectID={params.projectId}
                analysisID={params.id}
                treeData={treeData}
                setTreeData={setTreeData}
                analysisData={analysisData}
                onSavingChange={setIsSaving}
                patchAnalysis={patchAnalysis}
                refreshData={refreshData}
                isRunning={isRunning}
                tableUnlocked={loadsTableUnlocked}
                setTableUnlocked={setLoadsTableUnlocked}
                isSaving={isSaving}
                shouldBeSaving={shouldBeSaving}
              />
            )}
            {tabIndex === 1 && currentDocument && currentDocument.data.frontmatter && (
              <AnalysisFrontmatter
                project={projectName}
                user={user}
                lastModifiedBy={lastModifiedBy}
                name={analysisArgs.name}
                args={analysisArgs}
                updateData={updateData}
                patchAnalysis={patchAnalysis}
                isSaving={isSaving}
                isDisabled={isRunning || isSaving}
                tableUnlocked={frontmatterTableUnlocked}
                setTableUnlocked={setFrontmatterTableUnlocked}
                frontmatter={frontmatter}
                setFrontmatter={setFrontmatter}
                shouldBeSavingText={shouldBeSavingText}
                documents={documents}
                currentDocument={currentDocument}
                savedCurrentDocument={savedCurrentDocument}
                saveCurrentDocument={saveCurrentDocument}
                handleVersionChange={handleVersionChange}
              />
            )}
            {tabIndex === 2 && currentVariables && (
              <AnalysisVariables
                user={user}
                orgID={orgId.current}
                projectID={params.projectId}
                analysisID={params.id}
                treeData={treeData}
                setTreeData={setTreeData}
                analysisData={analysisData}
                onSavingChange={setIsSaving}
                patchAnalysis={patchAnalysis}
                refreshData={refreshData}
                isRunning={isRunning}
                tableUnlocked={variablesTableUnlocked}
                setTableUnlocked={setVariablesTableUnlocked}
                isSaving={isSaving}
                shouldBeSaving={shouldBeSaving}
                currentVariables={currentVariables}
                setCurrentVariables={setCurrentVariables}
                saveCurrentVariables={saveCurrentVariables}
              />
            )}
            {tabIndex === 1 && typeof currentDocument === 'undefined' && (
              <Card>
                <CardContent>
                  <Typography variant="h6"  sx={{ mb: 1.5 }}>
                    Unavailable Front Matter
                  </Typography>
                  <Typography color="text.secondary">
                    The Front Matter is unavailable for this analysis. The analysis was created before the Front Matter was available. Please create a new analysis to edit the Front Matter.
                  </Typography>
                </CardContent>
              </Card>
            )}
            {tabIndex === 1 && typeof currentDocument !== 'undefined' && typeof currentDocument.data.frontmatter === 'undefined' && (
              <Card>
                <CardContent>
                  <Typography variant="h6"  sx={{ mb: 1.5 }}>
                    Unavailable Front Matter
                  </Typography>
                  <Typography color="text.secondary">
                    The Front Matter is unavailable for this analysis. The aircraft type does not support editable Front Matters.
                  </Typography>
                </CardContent>
              </Card>
            )}
            {tabIndex === 2 && typeof currentVariables === 'undefined' && (
              <Card>
                <CardContent>
                  <Typography variant="h6"  sx={{ mb: 1.5 }}>
                    Unavailable variables
                  </Typography>
                  <Typography color="text.secondary">
                    The variables are unavailable for this analysis. The analysis was created before the variables were available. Please create a new analysis to edit the variables.
                  </Typography>
                </CardContent>
              </Card>
            )}
          </Box>
        </Grid>
      </Grid>
      <OutputDrawer
        isOutputDrawerOpened={isOutputDrawerOpened}
        toggleDrawer={toggleDrawer}
        sortedOutputs={sortedOutputs}
      />
    </div>
  );
};

// Temporary workaround for eventual consistent read issue in analyze-post (latest data not always fetched)
function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export default withStyles(ELAStyles, { withTheme: true })(withRouter(AirbusELAAnalysis));
