/*
TODO:
    1. Clean up code and simplify.
 */

import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  FormControl,
  Box,
  Link,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PasswordDialogStyles from '../../../styles/jss/components/PasswordDialogStyles';
import { Auth } from 'aws-amplify';
import { NotificationContainer, NotificationManager } from 'react-notifications';

const useStyles = makeStyles(PasswordDialogStyles);

const PasswordDialog = (props) => {
  const classes = useStyles();
  const { closeDialog } = props;
  const handleClose = () => {
    setData({
      current_password: {
        value: '',
        error: false,
        errorMessage: false,
      },
      new_password: {
        value: '',
        error: false,
        errorMessage: false,
      },
      confirm: {
        value: '',
        error: false,
        errorMessage: '',
      },
    });
    closeDialog();
  };

  const [data, setData] = React.useState({
    current_password: {
      value: '',
      error: false,
      errorMessage: false,
    },
    new_password: {
      value: '',
      error: false,
      errorMessage: false,
    },
    confirm: {
      value: '',
      error: false,
      errorMessage: '',
    },
  });

  const handleChange = (event) => {
    // const { name, value } = event.target;
    const name = event.target.name;
    const val = event.target.value;
    const validated = validation(val, name);
    setData({ ...data, [name]: validated });
  };

  const validation = (input, field) => {
    switch (field) {
      case 'new_password':
        if (input.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/)) {
          return {
            value: input,
            error: false,
            errorMessage: false,
          };
        } else {
          return {
            value: input,
            error: true,
            errorMessage: 'Must include 6 chars, 1 uppercase, and 1 number',
          };
        }
      case 'current_password':
        if (input.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/)) {
          return {
            value: input,
            error: false,
            errorMessage: false,
          };
        } else {
          return {
            value: input,
            error: true,
            errorMessage: 'Must include 6 chars, 1 uppercase, and 1 number',
          };
        }
      case 'confirm':
        if (input !== data.new_password.value) {
          return {
            value: input,
            error: true,
            errorMessage: 'Must match new password.',
          };
        } else {
          return {
            value: input,
            error: false,
            errorMessage: false,
          };
        }
      default:
        return {
          value: input,
          error: false,
          errorMessage: false,
        };
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    try {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          return Auth.changePassword(user, data.current_password.value, data.new_password.value);
        })
        .then((data) => {
          if (data === 'SUCCESS') {
            NotificationManager.success('Successfully changed your password.', 'Success');
            handleClose();
          }
        })
        .catch((err) => {
          console.log(err);
          NotificationManager.warning(err.message, 'Error');
        });
    } catch (e) {
      NotificationManager.error('Please check console for error', 'An Error Occurred');
      console.log(e);
    }
  };

  return (
    <div>
      <NotificationContainer />
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.dialogContainer }}
      >
        <form onSubmit={(e) => submit(e)}>
          <DialogTitle id="form-dialog-title" className={classes.titleContainer}>
            Change Password
          </DialogTitle>
          <DialogContent>
            <DialogContentText className={classes.forgotPasswordText}>
              <Typography variant={'caption'}>
                If you forgot your password: <Link href={'/forgot_password'}>Click Here</Link>
              </Typography>
            </DialogContentText>
            <Box mb={3}>
              <FormControl fullWidth>
                <TextField
                  type={'password'}
                  id={'current_password'}
                  autoFocus
                  name={'current_password'}
                  fullWidth
                  label={
                    data.current_password.error
                      ? data.current_password.errorMessage
                      : 'Current Password'
                  }
                  error={data.current_password.error}
                  value={data.current_password.value}
                  required
                  onChange={(e) => handleChange(e)}
                />
              </FormControl>
            </Box>
            <Box mb={3}>
              <FormControl fullWidth>
                <TextField
                  type={'password'}
                  id={'new_password'}
                  name={'new_password'}
                  fullWidth
                  error={data.new_password.error}
                  label={data.new_password.error ? data.new_password.errorMessage : 'New Password'}
                  value={data.new_password.value}
                  required
                  onChange={(e) => handleChange(e)}
                />
              </FormControl>
            </Box>
            <Box mb={3}>
              <FormControl fullWidth>
                <TextField
                  type={'password'}
                  id={'confirm'}
                  name={'confirm'}
                  fullWidth
                  error={data.confirm.error}
                  label={data.confirm.error ? data.confirm.errorMessage : 'Confirm Password'}
                  value={data.confirm.value}
                  required
                  onChange={(e) => handleChange(e)}
                />
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions classes={{ root: classes.buttonContainer }}>
            <Button color={'default'} onClick={() => handleClose()}>
              Cancel
            </Button>
            <Button type={'submit'} color="primary" variant={'contained'}>
              Change password
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default PasswordDialog;
