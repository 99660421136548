import React from 'react';
import { useNavigate } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import CardContent from '@material-ui/core/CardContent';
import {
  Grid,
  Divider,
  Box,
  Typography,
  Card,
  CardActions,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  TextField,
} from '@material-ui/core';
import EditCompanyProfile from '../components/companyProfile/CompanyProfileEdit';
import axios from 'axios';
import { cfg } from '../config';
import { Auth } from 'aws-amplify';
import ContentLoading from '../components/common/ContentLoading';
import { signOut } from '../helpers';

const CompanyProfile = (props) => {
  const navigate = useNavigate();

  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [deleteDialogOpened, setDeleteDialogOpened] = React.useState(false);
  const [deleteText, setDeleteText] = React.useState('');

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    Auth.currentSession().then((session) => {
      const idToken = session.getIdToken();
      axios
        .get(`${cfg.apiUrl}/org`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: idToken.jwtToken,
          },
        })
        .then((resp) => {
          const inAdminGroup = idToken.payload['cognito:groups'] !== undefined && idToken.payload['cognito:groups'].includes('admin');
          setIsAdmin(inAdminGroup)
          setData(resp.data);
          setIsLoading(false);
        })
        .catch((e) => {
          console.log(e);
        });
    });
  };

  const deleteAccount = () => {
    setIsLoading(true);

    Auth.currentSession()
      .then((session) => {
        axios
          .delete(`${cfg.apiUrl}/org`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: session.getIdToken().jwtToken,
            },
          })
          .then((resp) => {
            if (resp.status === 200) {
              setDeleteDialogOpened(false);
            } else {
              setDeleteDialogOpened(false);
              NotificationManager.error(
                'Something went wrong, please see console for details.',
                'ERROR'
              );
              console.log(resp);
            }

            // Sign out
            signOut(navigate); // TODO: FIX
          })
          .catch((e) => {
            console.log(e);
            if (e.response || e.request) {
              NotificationManager.error(
                'Something went wrong, please see console for details.',
                'ERROR'
              );
            } else {
              NotificationManager.error(
                'Something went wrong, please see console for details.',
                'ERROR'
              );
            }

            setIsLoading(false);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const changeDeleteText = (e) => {
    setDeleteText(e.target.value);
  };

  const cancelDialog = () => {
    setDeleteDialogOpened(false);
    setDeleteText(''); // reset delete text
  };

  const targetString = () => {
    return isAdmin ? 'account & organization' : 'account'
  }

  return (
    <div>
      {isLoading ? (
        <div>
          <ContentLoading />
        </div>
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Card>
                <CardContent>
                  <Grid container spacing={6}>
                    <Grid item xs={11}>
                      <Box p={1}>
                        <Typography variant={'body1'}>Organization</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={1}>
                      <EditCompanyProfile isAdmin={isAdmin}/>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardContent>
                  <Grid container spacing={5}>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        <b>Company Address</b>
                      </Typography>
                      <Typography variant="body1">{data.address.line1}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body1">
                        <b>City</b>
                      </Typography>
                      <Typography variant="body1">{data.address.city}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body1">
                        <b>State</b>
                      </Typography>
                      <Typography variant="body1">{data.address.state}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body1">
                        <b>Zip Code</b>
                      </Typography>
                      <Typography variant="body1">{data.address.postal_code}</Typography>
                    </Grid>
                    <Grid item md={12}>
                      <Button
                        color="secondary"
                        size="small"
                        onClick={() => setDeleteDialogOpened(true)}
                      >
                        {`Delete ${targetString()}`}
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Dialog
            open={deleteDialogOpened}
            onClose={() => setDeleteDialogOpened(false)}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">{`Delete the ${targetString()}?`}</DialogTitle>
            <DialogContent>
              <DialogContentText>
              {`This will delete the ${targetString()}. This action cannot be undone.`}
              </DialogContentText>
              <FormControl fullWidth>
                <TextField
                  type={'text'}
                  name={'delete'}
                  fullWidth
                  label={'To confirm deletion, type permanently delete'}
                  value={deleteText}
                  onChange={(e) => changeDeleteText(e)}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button disabled={isLoading} color={'default'} onClick={() => cancelDialog()}>
                Cancel
              </Button>
              <Button
                disabled={isLoading || deleteText !== 'permanently delete'}
                onClick={() => deleteAccount()}
                color="primary"
                variant={'contained'}
              >
                {`Delete ${targetString()}`}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </div>
  );
};

export default CompanyProfile;
