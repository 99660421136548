const NotificationStyles = (theme) => ({
  buttonContainer: {
    padding: '16px 24px',
  },
  titleContainer: {
    paddingBottom: 0,
  },
  NotificationText: {
    marginBottom: '20px',
  },
  icon: {
    fontSize: '48px',
    marginBottom: '8px',
    color: theme.palette.grey['700'],
    '&:hover': {
      color: '#3f51b5',
    },
  },
  paper: {
    marginRight: theme.spacing(1),
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  rootContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  headerRoot: {
    display: 'flex',
    alignItems: 'middle',
  },
  header: {
    padding: theme.spacing(2),
    width: '480px',
    fontWeight: 'bold',
  },
  body: {
    padding: theme.spacing(2),
    height: '417px',
  },
  markRead: {
    padding: theme.spacing(2),
  },
  dialogContainer: {
    [theme.breakpoints.down('xs')]: {
      width: '95%',
      margin: '0',
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: '35%',
    },
  },
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  grey: {
    color: '#fff',
    backgroundColor: '#303030',
  },
  menuSpacing: {
    padding: theme.spacing(2),
  },
});

export default NotificationStyles;
