import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  FormControl,
  Box,
  Typography,
  Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SupportDialogStyles from '../../../styles/jss/components/SupportDialogStyles';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import { Component } from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const useStyles = makeStyles(SupportDialogStyles);

const SupportDialog = (props) => {
  const classes = useStyles();
  const { closeDialog } = props;
  const handleClose = () => {
    closeDialog();
  };

  // need to update handleChange
  const handleChange = (event) => {};

  // need to add submit details
  const submit = async (e) => {
    NotificationManager.success('Successfully sent support request.', 'Success');
    handleClose();
  };

  class FileUploadZone extends Component {
    constructor(props) {
      super(props);
      this.state = {
        files: [],
      };
    }
    handleChange(files) {
      this.setState({
        files: files,
      });
    }
    render() {
      return (
        <DropzoneAreaBase
          Icon={CloudUploadIcon}
          className={classes.icon}
          dropzoneText={'Drag and Drop files here or click'}
          onChange={this.handleChange.bind(this)}
        />
      );
    }
  }

  return (
    <div>
      <NotificationContainer />
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.dialogContainer }}
      >
        <form noValidate onSubmit={(e) => submit(e)}>
          <DialogTitle id="form-dialog-title" className={classes.titleContainer}>
            Contact Support
          </DialogTitle>
          <Divider />
          <DialogContent>
            <DialogContentText>
              <Typography variant={'caption'}>Let us know what we can help with</Typography>
            </DialogContentText>
            <Box mb={2}>
              <FormControl fullWidth>
                <TextField
                  id={'subject'}
                  autoFocus
                  name={'subject'}
                  fullWidth
                  variant="filled"
                  label="Subject"
                  onChange={(e) => handleChange(e)}
                />
              </FormControl>
            </Box>
            <Box mb={2}>
              <FormControl fullWidth>
                <TextField
                  id={'description'}
                  name={'description'}
                  fullWidth
                  variant="filled"
                  label="Description"
                  multiline
                  rows={4}
                  onChange={(e) => handleChange(e)}
                />
              </FormControl>
            </Box>
            <Box mb={2}>
              <FormControl fullWidth>
                <DialogContentText>
                  <Typography variant={'caption'}>
                    Attachments - file, screenshots, log can help pinpoint issues quickly
                  </Typography>
                </DialogContentText>
                <FileUploadZone />
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions classes={{ root: classes.buttonContainer }}>
            <Button color={'default'} onClick={() => handleClose()}>
              Cancel
            </Button>
            <Button type={'submit'} color="primary" variant={'contained'}>
              Send
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default SupportDialog;
