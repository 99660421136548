import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import { convertPixelsToRem } from '../../../../../helpers';
import { makeStyles } from '@material-ui/core/styles';
import {
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableBody,
  TableCell,
  MenuItem,
  Checkbox,
  Tooltip,
} from '@material-ui/core/';
import { withStyles } from '@material-ui/styles';
import UndoIcon from '@mui/icons-material/Undo';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import UndoChangesDialog from "./UndoChangesDialog";

// used to setup styles for containers and tables
const useStyles = makeStyles({
  container: {
    borderRight: '1px solid #797979',
    fontSize: `${convertPixelsToRem(12)} !important`,
  },
  table: {
    minWidth: 650,
  },
});

// used to setup styles for table rows
const StyledTableRow = withStyles((theme) => ({
  root: {
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: '#eff2f8',
    },
  },
}))(TableRow);

// used to setup styles for short text fields
const ShortTextField = withStyles((theme) => ({
  root: {
    width: 65,
    '& .MuiInputBase-root': {
      height: 20,
      fontSize: `${convertPixelsToRem(12)} !important`,
    },
  },
}))(TextField);

// used to setup styles for long text fields
const LongTextField = withStyles((theme) => ({
  root: {
    width: 300,
    '& .MuiInputBase-root': {
      height: 20,
      fontSize: `${convertPixelsToRem(12)} !important`,
    },
  },
}))(TextField);

// used to setup styles for for drop down menus
const DropDownTextField = withStyles((theme) => ({
  root: {
    width: 50,
    '& .MuiInputBase-root': {
      height: 20,
      fontSize: `${convertPixelsToRem(12)} !important`,
    },
  },
}))(TextField);

const AirbusSetupTableOne = (props) => {

  // Undo chanegs dialog
  const [undoChangesOpen, setUndoChangesOpen] = React.useState(false);
  const [undoChangesElecIdent, setUndoChangesElecIdent] = React.useState("");
  const [undoChangesCbIdx, setUndoChangesCbIdx] = React.useState("");

  // Delete forever dialog
  const [deleteForeverOpen, setDeleteForeverOpen] = React.useState(false);
  const [deleteForeverElecIdent, setDeleteForeverElecIdent] = React.useState("");
  const [deleteForeverCbIdx, setDeleteForeverCbIdx] = React.useState("");

  const handleTextfieldChange = async (e, cbIdx, column, props) => {
    let objectLocation = ''
    if (props.version === 1) {
      // Version 1
      const phaseIdx = props.getPhaseIndex();
      objectLocation = `phases.${phaseIdx}.circuitBreakers.${cbIdx}.${column}`;
    } else if (props.version === 1.1) {
      // Version 1.1
      objectLocation = `circuitBreakers.${cbIdx}.${column}`;
    } else {
      // Version 1.2
      objectLocation = `circuitBreakers.${cbIdx}.postmod.${column}`;
    }
    props.update(e.target.value, objectLocation);
  };

  const columns = ['elecIdent', 'permInter', 'c', 's', 'panel', 'ata100', 'designation'];
  const boolColumns = ['c', 's'];
  const longColumns = ['designation'];

  const GetField = (circuitBreaker, column, cbIdx) => {
    const color = props.getCircuitBreakerColor(cbIdx, column);

    if (boolColumns.includes(column)) {
      return (
        <DropDownTextField
          select
          defaultValue={circuitBreaker[column]}
          variant="outlined"
          onBlur={(e) => handleTextfieldChange(e, cbIdx, column, props)}
          InputProps={{ style: {color: color} }}
          key={Math.random()} // to force component to refresh (needed by the undo modified changes feature)
        >
          <MenuItem key="cValueNull" value={false} align="center">
            &nbsp;
          </MenuItem>
          <MenuItem key="cValue" value={true}>
            {column.toUpperCase()}
          </MenuItem>
        </DropDownTextField>
      );
    } else if (longColumns.includes(column)) {
      return (
        <LongTextField
        defaultValue={circuitBreaker[column]}
          variant="outlined"
          onBlur={(e) => handleTextfieldChange(e, cbIdx, column, props)}
          InputProps={{ style: { color: color}}}
          key={Math.random()} // to force component to refresh (needed by the undo modified changes feature)
        />
      );
    } else if (column === 'permInter') {
      return (
        <DropDownTextField
          select
          defaultValue={circuitBreaker[column]}
          variant="outlined"
          onBlur={(e) => handleTextfieldChange(e, cbIdx, column, props)}
          InputProps={{ style: {color: color} }}
          key={Math.random()} // to force component to refresh (needed by the undo modified changes feature)
        >
          <MenuItem key="PValue" value={'P'}>P</MenuItem>
          <MenuItem key="IValue" value={'I'}>I</MenuItem>
          <MenuItem key="PValue" value={'P/I'}>P/I</MenuItem>
          <MenuItem key="PValue" value={'I/P'}>I/P</MenuItem>
          <MenuItem key="PValue" value={'*'}>*</MenuItem>
        </DropDownTextField>
      );
    } else {
      return (
        <ShortTextField
          defaultValue={circuitBreaker[column]}
          variant="outlined"
          onBlur={(e) => handleTextfieldChange(e, cbIdx, column, props)}
          InputProps={{ style: { color: color}}}
          key={Math.random()} // to force component to refresh (needed by the undo modified changes feature)
        />
      );
    }
  };

  const getValue = (circuitBreaker, column) => {
    if (boolColumns.includes(column)) {
      return circuitBreaker[column] ? column : '';
    } else {
      return circuitBreaker[column];
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked && props.data && props.data.circuitBreakers) {
      const newSelecteds = props.data.circuitBreakers.map((_, i) => i);
      props.setSelectedRows(newSelecteds);
      return;
    }
    props.setSelectedRows([]);
  };

  const handleClick = (event, elecIdent) => {
    const selectedIndex = props.selectedRows.indexOf(elecIdent);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(props.selectedRows, elecIdent);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(props.selectedRows.slice(1));
    } else if (selectedIndex === props.selectedRows.length - 1) {
      newSelected = newSelected.concat(props.selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        props.selectedRows.slice(0, selectedIndex),
        props.selectedRows.slice(selectedIndex + 1)
      );
    }

    props.setSelectedRows(newSelected);
  };

  const handleDeleteOrUndo = (event, circuitBreaker, circuitBreakerMain, cbIdx) => {
    if (props.isCircuitBreakerAdded(cbIdx)) {
      // Permanent delete of added CB with confirmation popup
      setDeleteForeverElecIdent(circuitBreaker["elecIdent"]);
      setDeleteForeverCbIdx(cbIdx)
      setDeleteForeverOpen(true);
    } else if (props.isCircuitBreakerRevised(cbIdx)) {
      // Undo changes to revised CB with confirmation popup
      setUndoChangesElecIdent(circuitBreaker["elecIdent"]);
      setUndoChangesCbIdx(cbIdx)
      setUndoChangesOpen(true);
    } else {
      // Mark CB as deleted or undo delete
      const newValue = !circuitBreakerMain["deleted"];
      const objectLocation = `circuitBreakers.${cbIdx}.deleted`;
      props.update(newValue, objectLocation);
    }
  };

  const isSelected = (elecIdent) => props.selectedRows.indexOf(elecIdent) !== -1;

  const rowCount = () =>
    props.data && props.data.circuitBreakers ? props.data.circuitBreakers.length : 0;

  // Undo changes dialog
  const confirmUndoChanges = () => {
    const newValue = JSON.parse(JSON.stringify(props.data.circuitBreakers[undoChangesCbIdx].premod));
    const objectLocation = `circuitBreakers.${undoChangesCbIdx}.postmod`;
    props.update(newValue, objectLocation);
    setUndoChangesOpen(false);
  };

  // Delete forever dialog
  const confirmDeleteForever = () => {
    props.deleteCircuitBreaker(deleteForeverCbIdx);
    setDeleteForeverOpen(false);
  };

  return (
    <TableContainer className="hideScrollbar" style={{ height: 500 }}>
      <UndoChangesDialog
        open={undoChangesOpen}
        setOpen={setUndoChangesOpen}
        message={`This will revert all modified values for ${undoChangesElecIdent} to the baseline values.`}
        confirm={confirmUndoChanges}
        cancel={() => setUndoChangesOpen(false)}
      />
      <UndoChangesDialog
        open={deleteForeverOpen}
        setOpen={setDeleteForeverOpen}
        elecIdent={deleteForeverElecIdent}
        message={`This will delete forever the new row ${deleteForeverElecIdent}.`}
        confirm={confirmDeleteForever}
        cancel={() => setDeleteForeverOpen(false)}
      />
      <Table className={useStyles.table} stickyHeader aria-label="simple table">
        <TableHead>
          <TableRow>
            {props.version >= 1.2 && (
              <TableCell padding="checkbox">
              </TableCell>
            )}
            {props.tableUnlocked && props.version <= 1.1 && (
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  size="small"
                  indeterminate={
                    props.selectedRows.length > 0 && props.selectedRows.length < rowCount()
                  }
                  checked={rowCount() > 0 && props.selectedRows.length === rowCount()}
                  onChange={handleSelectAllClick}
                  inputProps={{
                    'aria-label': 'select all circuit breakers',
                  }}
                />
              </TableCell>
            )}
            <TableCell align="center">
              <b>
                ELEC
                <br />
                IDENT
              </b>
            </TableCell>
            <TableCell align="center">
              <b>
                PERM
                <br />
                INTER
              </b>
            </TableCell>
            <TableCell align="center">
              <b>C</b>
            </TableCell>
            <TableCell align="center">
              <b>S</b>
            </TableCell>
            <TableCell align="center">
              <b>PANEL</b>
            </TableCell>
            <TableCell align="center">
              <b>
                ATA
                <br />
                100
              </b>
            </TableCell>
            <TableCell align="left" style={{ minWidth: 300 }}>
              <b>DESIGNATION</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data && props.data.circuitBreakers
            ? props.data.circuitBreakers.map((circuitBreakerMain, cbIdx) => {
                const isItemSelected = isSelected(cbIdx);
                const circuitBreaker = props.version >= 1.2 ? circuitBreakerMain.postmod : circuitBreakerMain;
                const isAdded = props.isCircuitBreakerAdded(cbIdx);
                const isDeleted = props.isCircuitBreakerDeleted(cbIdx);
                const isRevised = props.isCircuitBreakerRevised(cbIdx);
                const hasChanged = isAdded || isDeleted || isRevised;

                return (
                  <StyledTableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={cbIdx}
                    selected={isItemSelected}
                    style={{ height: 41 }}
                  >

                      {props.version >= 1.2 && (
                        <TableCell padding="checkbox">
                          {(props.tableUnlocked || hasChanged) && (
                            <Tooltip title={isAdded ?  "Delete forever" : isRevised ? "Revert to baseline" : (isDeleted ? "Restore" : "Delete")}>
                              <Checkbox
                                color="secondary"
                                size="small"
                                style={{
                                  color: props.getCircuitBreakerColor(cbIdx)
                                }}
                                checked={isDeleted}
                                onClick={(event) => handleDeleteOrUndo(event, circuitBreaker, circuitBreakerMain, cbIdx)}
                                icon={isAdded ?  ( props.tableUnlocked ? <DeleteForeverIcon/> : <AddCircleOutlineIcon/> ) : (props.isCircuitBreakerRevised(cbIdx) ? (props.tableUnlocked ? <UndoIcon/> : <ChangeCircleOutlinedIcon/>) : <DeleteOutlinedIcon/>)}
                                checkedIcon={props.tableUnlocked ? <UndoIcon/> : <RemoveCircleOutlineIcon/>}
                                disabled={!props.tableUnlocked}
                              />
                            </Tooltip>
                          )}
                        </TableCell>
                      )}
                      {props.version <= 1.1 && props.tableUnlocked && (
                        <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          size="small"
                          checked={isItemSelected}
                          onClick={(event) => handleClick(event, cbIdx)}
                        />
                        </TableCell>
                      )}
                    {columns.map(function (column) {
                      return (
                        <TableCell
                          align={longColumns.includes(column) ? 'left' : 'center'}
                          key={column}
                          style={{
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingRight: 0,
                            paddingLeft: 0,
                            color: props.getCircuitBreakerColor(cbIdx, column),
                            textDecoration : props.isCircuitBreakerDeleted(cbIdx) ? 'line-through' : 'none'
                          }}
                        >
                          {props.tableUnlocked && !props.isCircuitBreakerDeleted(cbIdx)
                            ? GetField(circuitBreaker, column, cbIdx)
                            : getValue(circuitBreaker, column)}
                        </TableCell>
                      );
                    })}
                  </StyledTableRow>
                );
              })
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AirbusSetupTableOne;
