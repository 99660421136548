import React from 'react';
import { Auth } from 'aws-amplify';
import {
  Checkbox,
  Grid,
  Card,
  Button,
  Typography,
} from '@material-ui/core';
import Stack from '@mui/material/Stack';
import { searchData } from '../helpers';
import MemberHeader from '../components/members/MemberHeader';
import ContentLoading from '../components/common/ContentLoading';
import { withStyles } from '@material-ui/core/styles';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import InviteMemberDialog from '../components/common/InviteMemberDialog';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import axios from 'axios';
import { cfg } from '../config';
import MemberListStyles from '../styles/jss/components/apps/MemberListStyles';


const Members = (props) => {
  const [data, setData] = React.useState([]);
  const [parsedData, setParsedData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [userCognitoId, setUserCognitoId] = React.useState('');
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    Auth.currentSession().then((session) => {
      const idToken = session.getIdToken();
      axios
        .get(`${cfg.apiUrl}/org`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: idToken.jwtToken,
          },
        })
        .then((resp) => {
          setUserCognitoId(idToken.payload.sub)
          setData(resp.data.members.users);
          setParsedData(resp.data.members.users);
          setIsLoading(false);
        })
        .catch((e) => {
          console.log(e);
        });
    });
  };

    //logic to sort the data based upon the search
    const sortBySearch = (input) => {
      let newData = searchData(data, input, ['username']);
      setParsedData(newData);
    };

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  //   setOpenMenu(Boolean(event.currentTarget));
  // };

  // const handleClose = (action) => {
  //   switch (action) {
  //     case 'transfer':
  //       setOpenMenu(true);
  //       setAnchorEl(null);
  //       break;
  //     default:
  //       setAnchorEl(null);
  //   }
  // };

  const dialogOptions = {
    title: 'Invite Member',
  };

  const columns = [
    {
      name: 'Username',
      cell: (row) =>
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography variant='inherit'>{row.username}</Typography>
        { row.sub === userCognitoId && <VerifiedUserIcon color="primary"/>}
      </Stack>,
      sortable: true,
    },
    {
      name: 'Role',
      sortable: true,
      cell: (row) => <span>{row.role}</span>,
    },
    {
      name: 'Created on',
      cell: (row) => <span>{moment(row.createDate).format('lll')}</span>,
      sortable: true,
    },
    {
      name: 'Actions',
      allowOverflow: true,
      right: true,
    },
  ];

  const { classes } = props;

  return (isLoading ? (
    <div>
      <ContentLoading />
    </div>
  ) : (
    <div>
      <InviteMemberDialog
        updateData={() => fetchData()}
        handleClose={() => setOpen(false)}
        open={open}
        {...dialogOptions}
      />
      <Grid container justifyContent="flex-end">
        <Button disabled variant={'contained'} color={'primary'} onClick={() => setOpen(true)}>
          + Invite Member
        </Button>
      </Grid>
      <Card className={classes.tableContainer}>
        <DataTable
          style={{ marginTop: '0px' }}
          columns={columns}
          data={parsedData}
          striped
          pagination
          paginationPerPage={10}
          noHeader
          subHeader
          subHeaderComponent={
            <MemberHeader
              sortBySearch={sortBySearch}
            />
          }
          selectableRowsComponent={Checkbox}
        />
      </Card>
    </div>
  ));
};

export default withStyles(MemberListStyles)(Members);
