const ProjectStyles = (theme) => ({
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  headerBackIcon: {
    marginRight: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  tableContainer: {
    marginTop: '30px',
    height: '100%',
    position: 'relative',
  },
  actionContainer: {
    display: 'flex',
    paddingTop: 20,
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  filterInner: {
    flex: 1,
    margin: '0px 10px',
  },
  selectInput: {
    '&:focus': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
  },
  dialogContainer: {
    [theme.breakpoints.down('xs')]: {
      width: '95%',
      margin: '0',
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: '35%',
    },
  },
  buttonContainer: {
    padding: '16px 24px',
  },
});

export default ProjectStyles;
