import React from 'react';
import {
  Grid,
  Box,
  Divider,
  Card,
  CardContent,
  Typography,
  makeStyles,
  Link,
} from '@material-ui/core';
import { downloadBlob } from '../../../../../helpers';
import { Storage, Auth } from 'aws-amplify';
import moment from 'moment';

/** App theme */
import ELAStyles from '../../../../../styles/jss/components/apps/ELAStyles';

/** Helper methods */
//import { convertPixelsToRem } from '../../../../../helpers';

const useStyles = makeStyles(ELAStyles);

const getFilename = (url) => {
  return url.substring(url.lastIndexOf('/') + 1);
};

const downloadFile = (file) => {
  Auth.currentAuthenticatedUser().then((user) => {
    const filename = getFilename(file.url);
    const key = file.url.replace(/^(public\/)/, ''); // remove "public/" from the start of the url
    Storage.get(key, {
      download: true,
    })
      .then((resp) => {
        downloadBlob(resp.Body, filename);
      })
      .catch((e) => {
        console.log(e);
      });
  });
};

const OutputFiles = (props) => {
  const classes = useStyles();
  const {
    analysisResults,
    functions: { handleViewAll },
  } = props;
  // const { handleViewAll } = props.functions;

  const handleSubmit = (e) => {
    e.preventDefault();
    handleViewAll();
  };

  return (
    <div className={classes.cardContainer}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography className={classes.cardHeader} variant={'h6'}>
          Output Files
        </Typography>
        <Typography className={classes.cardHeaderOption} onClick={(e) => handleSubmit(e)}>
          View All
        </Typography>
      </Box>
      <Card>
        <CardContent>
          <Grid container>
            <Grid item sm={12} md={4}>
              <Typography className={classes.cardHeader} variant={'caption'}>
                Run Time
              </Typography>
            </Grid>
            <Grid item sm={12} md={4}>
              <Typography className={classes.cardHeader} variant={'caption'}>
                Output report
              </Typography>
            </Grid>
            <Grid item sm={12} md={4}>
              <Typography className={classes.cardHeader} variant={'caption'}>
                User
              </Typography>
            </Grid>
            <Box width="100%"></Box>
            <Box width="100%">
              {analysisResults.slice(0, 3).map((file, index) => {
                return (
                  <React.Fragment key={file.runDate}>
                    {index !== analysisResults.length + 0 && <Divider />}
                    <Box m={0.7} display="flex" flexDirection="row" width="100%">
                      <Grid item sm={12} md={4} xl={4}>
                        <Typography variant={'caption'} fontSize="14px">
                          {moment(file.runDate).format('lll')}
                        </Typography>
                      </Grid>
                      <Grid item sm={12} md={4} xl={4}>
                        <Link
                          component="button"
                          variant="body2"
                          onClick={(e) => downloadFile(file)}
                        >
                          {getFilename(file.url)}
                        </Link>
                      </Grid>
                      <Grid item sm={12} md={4} xl={4}>
                        <Typography variant={'caption'} fontSize="14px">
                          {file.username ? file.username.split('@')[0] : ''}
                        </Typography>
                      </Grid>
                    </Box>
                  </React.Fragment>
                );
              })}
            </Box>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export { OutputFiles, getFilename, downloadFile };
