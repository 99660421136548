import React, { useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import {
  Grid,
  Box,
  Card,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import { $createTextNode, $getRoot, $getSelection, ParagraphNode, TextNode } from "lexical";
import {LexicalComposer} from '@lexical/react/LexicalComposer';
import {PlainTextPlugin} from '@lexical/react/LexicalPlainTextPlugin';
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin';
import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin';
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import {
  $convertFromMarkdownString,
  $convertToMarkdownString,
  TRANSFORMERS,
} from '@lexical/markdown';
import TreeViewPlugin from "./plugins/TreeViewPlugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import ExampleTheme from "./themes/ExampleTheme";

import "./styles.css";

const lodashClonedeep = require('lodash.clonedeep');

const PlainTextEditor = forwardRef((props, ref) => {
  const [editor] = useLexicalComposerContext();


  React.useEffect(() => {
    editor.update(() => {
      $convertFromMarkdownString(props.frontmatter.text, TRANSFORMERS);
    });
  }, [editor, props]);

  React.useEffect(() => {
    editor.setEditable(props.unlocked);
  }, [editor, props.unlocked]);

  useImperativeHandle(ref, () => ({
    getMarkdown() {
      let markdown;
      editor.update(() => {
        markdown = $convertToMarkdownString();
      });
      return markdown;
    }

  }))



  function onChange(editorState) {
    editorState.read(() => {
      // Read the contents of the EditorState here.
      const root = $getRoot();
      const selection = $getSelection();

      console.log(root, selection);
    });
  }


  return (
    <div className="editor-container">
      <ToolbarPlugin
        disabled={!props.unlocked}
        unlocked={props.unlocked}
        isSaving={props.isSaving}
        isDisabled={props.isDisabled}
        unlock={props.unlock}
        saveAndLock={props.saveAndLock}
        cancelChanges={props.cancelChanges}
        documents={props.documents}
        currentDocument={props.currentDocument}
        savedCurrentDocument={props.savedCurrentDocument}
        saveCurrentDocument={props.saveCurrentDocument}
        handleVersionChange={props.handleVersionChange}
        shouldBeSavingText={props.shouldBeSavingText}
        setFrontmatter={props.setFrontmatter}
      />
      <div className="editor-inner">
        <RichTextPlugin
          contentEditable={<ContentEditable className="editor-input" />}
          // placeholder={<Placeholder />}
        />
        <OnChangePlugin onChange={onChange}/>
        <HistoryPlugin />
        {/*<TreeViewPlugin />*/}
        <AutoFocusPlugin />
        <CodeHighlightPlugin />
        <ListPlugin />
        <LinkPlugin />
        <AutoLinkPlugin />
        <ListMaxIndentLevelPlugin maxDepth={7} />
        <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
      </div>
    </div>
  );
})

const AnalysisFrontmatter = (props) => {
  // Data

  const savedFrontmatter = useRef(null); // to cancel changes when not clicking the Save&Lock button

  // States
  // const [unlocked, setUnlocked] = React.useState(false);
  const shouldBeSaving = useRef(false);
  const editorPluginRef = useRef();

  // Catch any errors that occur during Lexical updates and log them
  // or throw them as needed. If you don't throw them, Lexical will
  // try to recover gracefully without losing user data.
  function onError(error) {
    console.error(error);
  }

  const initialConfig = {
    theme: ExampleTheme,
    // editorState: () => $convertFromMarkdownString(text, TRANSFORMERS),
    onError,
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode,
      CodeHighlightNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode
    ]
  };

  const unlock = (event, selection) => {
    const clonedFrontmatter = lodashClonedeep(props.frontmatter);
    savedFrontmatter.current = clonedFrontmatter; // clone and save current text
    props.setTableUnlocked((prevState) => !prevState); // unlock table
  };

  const saveAndLock = (event, selection) => {
    const markdown = editorPluginRef.current.getMarkdown();

    // Set shouldBeSaving to true to trigger patchAnalysis after saving node to the tree
    props.shouldBeSavingText.current = true;

    // Save frontmatter
    const frontmatter = {text: markdown, name: ''}; // name is blank for manual edits
    props.setFrontmatter(frontmatter);
    savedFrontmatter.current = null; // reset saved text
    props.setTableUnlocked((prevState) => !prevState); // lock table
  };

  const cancelChanges = (event, selection) => {
    if (savedFrontmatter.current) {
      props.setFrontmatter(savedFrontmatter.current); // revert changes to text
      props.setTableUnlocked((prevState) => !prevState); // lock table
      savedFrontmatter.current = null; // reset saved text
    }
  };

  return (
    <Card>
      <LexicalComposer initialConfig={initialConfig}>
        <PlainTextEditor
          ref={editorPluginRef}
          unlocked={props.tableUnlocked}
          isSaving={props.isSaving}
          isDisabled={props.isDisabled}
          unlock={unlock}
          saveAndLock={saveAndLock}
          cancelChanges={cancelChanges}
          frontmatter={props.frontmatter}
          documents={props.documents}
          currentDocument={props.currentDocument}
          savedCurrentDocument={props.savedCurrentDocument}
          saveCurrentDocument={props.saveCurrentDocument}
          handleVersionChange={props.handleVersionChange}
          shouldBeSavingText={props.shouldBeSavingText}
          setFrontmatter={props.setFrontmatter}
        />
      </LexicalComposer>
    </Card>
  );
};

export default AnalysisFrontmatter;
