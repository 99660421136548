import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  makeStyles
} from "@material-ui/core";
import {Timeline, TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent} from "@material-ui/lab";
import {Auth} from 'aws-amplify';
import axios from 'axios';
import {cfg} from "../../../../../config";
import withRouter from '../../../../../helpers/withRouter';
import { getBase64, downloadBoeingCsvTemplate } from '../../../../../helpers';

import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import LoadingButton from '@mui/lab/LoadingButton';
import { DropzoneArea } from 'material-ui-dropzone';
import { NotificationManager } from 'react-notifications';
import clsx from "clsx";

const useStyles = makeStyles({
  timeline_item: {
    '&:before': {
      flex: 0,
      padding: 0,
    }
  },
  content1: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '20px 0'
  },
  dropzoneContainer: {
    border: 'dashed 1px lightgray',
    borderRadius: '5px',
    width: '100%',
    marginTop: 20
  },
  dropzoneInnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
    position: 'relative'
  },
  dropzone: {
    position:"absolute",
    outline:"none !important",
    width:"100%",
    height:"100%",
    cursor:"pointer",
    opacity:"0",
    marginTop: -20
  },
  dragging: {
    border: 'solid 1px red'
  },
  previewChip: {
    minWidth: 16,
    maxWidth: 21
  },
})

const ImportData = props => {
  const [open, setOpen] = React.useState(false);
  const [uploadFile, setUploadFile] = React.useState(null);
  const [isUploading, setIsUploading] = React.useState(false);

  const handleClose = () => {
    setUploadFile(null);
    setOpen(false);
  }
  const classes = useStyles();

  const theme = createTheme({
    overrides: {
      MuiDropzoneArea: {
        root: {
          minHeight: '', // reduce component height
        },
        text: {
          fontSize: '16px',
        },
      }
    }
  });

  const submit = async () => {
    Auth.currentSession()
      .then(session => {
        setIsUploading(true);
        axios.patch(`${cfg.apiUrl}/app/boeing-ela/config`, {
          "org": props.orgID,
          "projectId": props.projectID,
          "id": props.analysisID,
          "csvEncoded": uploadFile,
          "fromOriginal": false,
        }, {
          headers: {
            "Authorization": session.getIdToken().jwtToken,
            "Content-Type": "application/json"
          }
        })
          .then(async resp => {
            console.log(resp)
            if (resp.status === 200) {
              // Close form
              const message = JSON.parse(resp.data).message
              NotificationManager.success(`Data imported succesfully: ${message}`, 'SUCCESS', 10000);
              setIsUploading(false);
              setOpen(false);
              await props.refreshData();
            } else {
              NotificationManager.warning(
                'Something went wrong, please check console for response.',
                'WARNING'
              );
            }
          })
          .catch((err) => {
            console.log(err)
            setIsUploading(false);
            if (err.response) {
              if (err.response.status === 400) {
                NotificationManager.error(`The import failed: ${err.response.data}`, 'Error', 10000);
                return
              }
            }
            NotificationManager.error('An error has occurred, please try again.', 'Error');
          });
      })
  }

  const handleFileChange = async (files) => {
    // Reset inputFile if the input file is removed from the UI
    if (files.length === 0) {
      setUploadFile(null); // reset inputFile
      return;
    }

    // Get the input file as base64 and update the state with the callback
    getBase64(files[0], setUploadFile); // prints the base64 string
  };


  return(
    <>
      <Button disabled={props.disabled} onClick={() => setOpen(i => !i)} style={{marginRight: 20}} size={'small'} color={'primary'}>Import data (.csv)</Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="import-analysis-dialog">Import New Load Data</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please follow the steps below and upload a .csv file filled with new load data. The import will fail if any load from the file already exist or if any buss from the file does not exist.
          </DialogContentText>
          <Timeline align={'left'}>
            <TimelineItem classes={{root: classes.timeline_item}}>
              <TimelineSeparator>
                <TimelineDot>
                  <GetAppIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography>Download .csv template if needed</Typography>
                <div className={classes.content1}>
                  <div>
                    <Button color={'primary'} onClick={() => downloadBoeingCsvTemplate()}>template.csv</Button>
                  </div>
                </div>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem classes={{root: classes.timeline_item}}>
              <TimelineSeparator>
                <TimelineDot>
                  <PublishIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography>Upload .csv template with data</Typography>
                  <MuiThemeProvider theme={theme}>
                    <DropzoneArea
                      dropzoneText={'Drag & Drop or click to upload a .csv file'}
                      onChange={(e) => handleFileChange(e)}
                      acceptedFiles={['.csv']}
                      filesLimit={1}
                      showPreviews={true}
                      showPreviewsInDropzone={false}
                      useChipsForPreview
                      previewText="Selected file"
                    />
                  </MuiThemeProvider>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </DialogContent>
        <DialogActions>
          <Button disabled={isUploading} onClick={handleClose} color="primary">
            Cancel
          </Button>
          <LoadingButton
            color="primary"
            disabled={isUploading || !Boolean(uploadFile)}
            onClick={submit}
            loading={isUploading}
            loadingPosition="start"
            startIcon={<CloudUploadIcon />}
          >
            Upload File
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default withRouter(ImportData);