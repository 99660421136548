import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import { convertPixelsToRem } from '../../../../../helpers';
import { makeStyles } from '@material-ui/core/styles';
import {
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableBody,
  TableCell,
} from '@material-ui/core/';
import { withStyles } from '@material-ui/styles';

const AirbusVariablesTable = (props) => {
  // used to setup styles for containers and tables
  const useStyles = makeStyles({
    container: {
      borderRight: '1px solid #797979',
      fontSize: `${convertPixelsToRem(12)} !important`,
    },
    table: {
      minWidth: 650,
    },
  });

  // used to setup styles for table rows
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: '#eff2f8',
      },
    },
  }))(TableRow);

  // used to setup styles for long text fields
  const LongTextField = withStyles((theme) => ({
    root: {
      width: 300,
      '& .MuiInputBase-root': {
        height: 20,
        fontSize: `${convertPixelsToRem(12)} !important`,
      },
    },
  }))(TextField);

  const handleTextfieldChange = async (e, variableIndex, column, props) => {
    let newVariables = [...props.currentVariables];
    newVariables[variableIndex].value = e.target.value;
    props.setCurrentVariables(newVariables);
  };

  const GetField = (variable, column, cbIdx) => {
    return (
      <LongTextField
        defaultValue={variable[column]}
        variant="outlined"
        onBlur={(e) => handleTextfieldChange(e, cbIdx, column, props)}
      />
    );
  };

  return (
    <TableContainer className="hideScrollbar" style={{ height: 800 }}>
      <Table className={useStyles.table} stickyHeader aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center" style={{padding: 8}}>
              <b>
                NAME
              </b>
            </TableCell>
            <TableCell align="center" style={{padding: 8}}>
              <b>
                VALUE
              </b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.currentVariables
            ? props.currentVariables.map((variable, id) => {
                return (
                  <StyledTableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={`variable-${id}`}
                    style={{ height: 41 }}
                  >
                    <TableCell
                      align={'center'}
                      key='name'
                      style={{
                        paddingTop: 10,
                        paddingBottom: 10,
                        paddingRight: 0,
                        paddingLeft: 0,
                      }}
                    >
                      {variable.name}
                    </TableCell>

                    <TableCell
                      align={'center'}
                      key='value'
                      style={{
                        paddingTop: 10,
                        paddingBottom: 10,
                        paddingRight: 0,
                        paddingLeft: 0,
                        width: "50%",
                      }}
                    >
                      {props.tableUnlocked
                        ? GetField(variable, 'value', id)
                        : variable.value
                      }
                    </TableCell>

                  </StyledTableRow>
                );
              })
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AirbusVariablesTable;
