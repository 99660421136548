// Styling for footer component

import { drawerWidth } from '../../aero.js';

const AppbarStyles = (theme) => ({
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    // backgroundColor: theme.palette.common.white
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  pageTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default AppbarStyles;
