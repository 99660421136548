/*
Array for application items shown on the Application page.

dialogOptions:
    title: the title of the dialog
    description: description of the project (set to null omit description)
    inputs: the inputs for the form
        type: text or select. If select, options with value and label must be included.
        label: the label for the input
        id: must be unique. ID will also be the state.input.[name] of form data.
    confirmAction: a function to run when the form is submitted.
 */

const items = [
  {
    title: 'Airbus Electrical Load Analysis',
    subtitle: 'Airbus ELA',
    icon: 'airplanemode_active',
    link: '/app/AirbusELA',
    showDialog: true,
    dialogOptions: {
      title: 'New Project',
      description:
        'To create a new Fatigue Spectral Analysis project, please input a project name and select an aircraft.',
      inputs: [
        {
          type: 'text',
          label: 'Project Name',
          id: 'project-name',
        },
        {
          type: 'select',
          label: 'Aircraft Type',
          id: 'select-aircraft',
          options: [
            {
              value: 'ab380',
              label: 'Airbus a380',
            },
            {
              value: 'b737',
              label: 'Boeing 737',
            },
          ],
        },
      ],
      postUrl: "app/airbus-ela/project",
      app: "airbus-ela",
      confirmAction: () => {
        console.log('confirmed');
      },
    },
  },
];

export default items;
