import React from 'react';
import { Divider, Grid, makeStyles, withStyles, Typography } from '@material-ui/core';
import { Menu, IconButton, Badge, Tooltip } from '@material-ui/core';
import withRouter from '../../../helpers/withRouter';
import { useTheme } from '@material-ui/core/styles';
import NotificationStyles from '../../../styles/jss/components/NotificationStyles';
import NotificationsIcon from '@material-ui/icons/Notifications';

const NotificationsMenu = (props) => {
  const useStyles = makeStyles(NotificationStyles);
  const classes = useStyles();
  const theme = useTheme();

  const StyledMenu = withStyles({})((props) => (
    <Menu
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ));

  // Menu handler
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (action) => {
    switch (action) {
      default:
        setAnchorEl(null);
    }
  };

  return (
    <div>
      <Tooltip title="Notifications">
        <IconButton
          aria-label="show 17 new notifications"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={(e) => handleClick(e)}
          className={classes.icon}
          color={theme.palette.grey[3]}
        >
          <Badge badgeContent={0} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <StyledMenu
        className={classes.dialogContainer}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Grid container spacing={0} className={classes.typography}>
          <Grid item xs={9}>
            <Typography className={classes.header} variant="subtitle1">
              Notifications
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.markRead} variant="subtitle1">
              Mark all as read
            </Typography>{' '}
            {/*Placeholder for marking messages as read */}
          </Grid>
        </Grid>
        <Divider />
        <Typography className={classes.body} variant="subtitle1">
          Notifications will be listed here
        </Typography>{' '}
        {/*Placeholder for messages */}
      </StyledMenu>
    </div>
  );
};
export default withRouter(NotificationsMenu);
