import React from 'react';
import { Drawer, Hidden } from '@material-ui/core';
import SidebarItems from './SidebarItems';
import { makeStyles } from '@material-ui/core';
import SidebarStyles from '../../../styles/jss/components/SidebarStyles';
import { useSelector } from 'react-redux';
import theme from '../../../theme';
import { ToggleSidebar } from '../../../redux/actions';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(SidebarStyles);

const MainSidebar = (props) => {
  const classes = useStyles();
  const sidebar = useSelector((state) => state.sidebar);
  const { window } = props;
  const container = window !== undefined ? () => window().document.body : undefined;
  const dispatch = useDispatch();

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          BackdropProps={{ invisible: true }}
          variant="temporary"
          anchor={'left'}
          open={sidebar.open && sidebar.screenWidth < theme.breakpoints.values.lg}
          onClose={() => dispatch(ToggleSidebar())}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <SidebarItems />
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="persistent"
          className={classes.drawer}
          anchor="left"
          open={sidebar.open}
        >
          <SidebarItems />
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default MainSidebar;
