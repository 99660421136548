import { combineReducers } from 'redux';
import SidebarReducer from './SidebarReducer';
import MobileSidebarReducer from './MobileSidebarReducer';
import SidebarItemsReducer from './SidebarItemsReducer';

const rootReducers = combineReducers({
  sidebar: SidebarReducer,
  mobileSidebar: MobileSidebarReducer,
  sidebarItems: SidebarItemsReducer,
});

export default rootReducers;
