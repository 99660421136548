import React from 'react';
import MainSidebar from '../components/layout/sidebar/MainSidebar';
//import MainFooter from '../components/layout/footer/Footer';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import AppBar from '../components/layout/appbar/AppBar';
import { useSelector } from 'react-redux';
import DefaultLayoutStyles from '../styles/jss/DefaultLayout';
import theme from '../theme';
import { CssBaseline } from '@material-ui/core';

const useStyles = makeStyles(DefaultLayoutStyles);

const DefaultLayout = ({ children, ...props }) => {
  const classes = useStyles();
  const sidebar = useSelector((state) => state.sidebar);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar title={props.title} />
      <MainSidebar />
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: sidebar.open && sidebar.screenWidth > theme.breakpoints.values.sm,
        })}
      >
        <div className={classes.drawerHeader} />
        {children}
      </main>
      {/*Issue with footer: sitting on top of content.*/}
      {/*<MainFooter />*/}
    </div>
  );
};

export default DefaultLayout;
