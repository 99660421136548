import { convertPixelsToRem } from '../../../../helpers';

const ELAStyles = (theme) => ({
  // ELA header styles
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
  },
  headerBackIcon: {
    marginRight: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  headerRightContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  headerAnalysisTitle: {
    minWidth: '40%',
  },

  cardHeader: {
    marginBottom: convertPixelsToRem(10),
    color: theme.palette.primary.lightText,
    textTransform: 'capitalize',
  },
  cardHeaderOption: {
    color: '#3f51b5',
    fontSize: convertPixelsToRem(14),
    '&:hover': {
      cursor: 'pointer',
    },
  },

  // ELA Left Menu styles
  cardContainer: {
    marginBottom: '20px',
  },
  predefinedFormControl: {
    marginBottom: '20px',
  },
  selectWithIcon: {
    display: 'flex',
    alignItems: 'center',
  },

  // ELA Input Content
  inputErrorCard: {
    height: '300px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputErrorCardContent: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default ELAStyles;
