import React from 'react';

/** Presentational */
import ProjectList from '../components/Apps/AirbusELA/projectList/ProjectList';

const AirbusELA = () => {
  return (
    <div>
      <ProjectList />
    </div>
  );
};

export default AirbusELA;
