// ##############################
// // // File to add helper functions
// // // Example: function that adds commas to large numbers
// #############################

import { Auth } from 'aws-amplify';
import moment from 'moment';

async function signOut(navigate) {
  try {
    Auth.signOut()
      .then((resp) => {
        console.log('Signed Out.');
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        console.log('Redirect to home page');
        navigate('/'); // redirect to home page
      });
  } catch (error) {
    console.log('error signing out: ', error);
  }
}

/**
 * Summary. Filter data based on filter object
 *
 * Description. Filters data object based on [key,value] pairs in filter object. Filter keys much match object keys.
 *
 * @param {Object} data The data you would like to filter through.
 * @param {Object} filters The filters you would like to apply to the data.
 * @return {Object} filteredData The filtered data.
 */
async function filterData(data, filters) {
  return data.filter(function (item) {
    for (let key in filters) {
      if (key.split('.').length > 1) {
        let pList = key.split('.');
        let value = pList.reduce((o, s) => {
          if (typeof (o || {})[s] == 'undefined') {
            return null;
          } else {
            return o[s];
          }
        }, item);
        return value === filters[key];
      } else {
        if (key === 'runDate' && moment(item[key]).isSame(filters[key], 'day')) return true;

        if (
          key === 'runDate' ||
          item[key] === '' ||
          item[key].toString().toLowerCase().indexOf(filters[key].toString().toLowerCase()) === -1
        )
          return false;
      }
    }
    return true;
  });
}

function searchData(data, term, keys = null) {
  // Pass in filtered data
  //
  let sortedData = [];
  for (let i = 0; i < data.length; i++) {
    for (const key of keys || Object.keys(data[i])) {
      // use provided keys or all object keys
      if (data[i][key].toString().toLowerCase().indexOf(term.toLowerCase()) !== -1) {
        sortedData.push(data[i]);
        break;
      }
    }
  }
  return sortedData;
}

function getAvailableOptions(data, key) {
  let array = [];
  for (let i = 0; i < data.length; i++) {
    if (!array.includes(data[i][key])) {
      array.push(data[i][key]);
    }
  }
  return array;
}

function checkForData(obj, key, def) {
  return key.split('.').reduce((object, path) => {
    return typeof (object || {})[path] == 'undefined' || (object || {})[path] === null
      ? def
      : (object || {})[path];
  }, obj);
}

/**
 * Summary. Sets a key value in a nested object using string location ('key1.key2.key3')
 *
 * @param {Object} obj The full object you would like to manipulate.
 * @param {Object} address The address of the key you would like to change.
 * @return {Object} value The desired value of the final key.
 */
function setNestedValue(obj, address, value) {
  let schema = obj;
  let pList = address.split('.');
  let len = pList.length;
  for (let i = 0; i < len - 1; i++) {
    let elem = pList[i];
    if (!schema[elem]) schema[elem] = {};
    schema = schema[elem];
  }
  schema[pList[len - 1]] = value;
  if (pList[len - 1] === 'missionMix') {
    // setMissionMixInputs(value);
  }
  return obj;
}

function downloadBlob(blob, filename) {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename || 'download';
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };
  a.addEventListener('click', clickHandler, false);
  a.click();
  return a;
}

/**
 *
 * Converts pixels to rems with base pixel unit of 16px
 *
 * @param  {number} unitValue - number of pixels
 * @returns string - rem value
 */
const convertPixelsToRem = (unitValue) => {
  return `${(unitValue / 16).toFixed(2)}rem`;
};

/**
 * Method to return a11y props for material ui tab component
 *
 * @param  {number} index
 */
const a11yProps = (index) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
};

// file upload
function getBase64(file, updateInputFileStateCallback) {
  var reader = new FileReader();
  reader.onload = function (e) {
    updateInputFileStateCallback(reader.result);
  };
  reader.onerror = function (error) {
    console.log('Error: ', error);
  };
  reader.readAsDataURL(file);
}

function downloadAirbusCsvTemplate(filename, fields='PERM/INTER,C,S,BUSBAR,PHASE,LOAD,IDENT.,PANEL,ATA 100,D E S I G N A T I O N ,NOMINAL POWER,GROUND,START,ROLL,T/OFF,CLIMB,CRUISE,DESC,LAND,TAXI') {
  downloadCsvTemplate(fields, filename);
}

function downloadBoeingCsvTemplate() {
  const text = 'ATA,No.,Bus,S,Nomenclature,Connected KVA,Connected PF,Gnd Opertn KVA,Gnd Opertn PF,Engine Start KVA,Engine Start PF,Taxi KVA,Taxi PF,Takeoff & Climb KVA,Takeoff & Climb PF,Cruise KVA,Cruise PF,Hold & Land KVA,Hold & Land PF,Standby KVA,Standby PF';
  downloadCsvTemplate(text, "template.csv");
}

function downloadCsvTemplate(text, filename) {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export {
  signOut,
  filterData,
  searchData,
  getAvailableOptions,
  checkForData,
  setNestedValue,
  downloadBlob,
  convertPixelsToRem,
  a11yProps,
  getBase64,
  downloadAirbusCsvTemplate,
  downloadBoeingCsvTemplate,
  sleep,
};
