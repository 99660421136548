// import logo from './logo.svg';
import React from 'react';

import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/core/styles';

import theme from './theme';
import { publicRoutes } from './routes';
import { Header } from './components/authentication/Header';
import { Footer } from './components/authentication/Footer';

import 'react-notifications/lib/notifications.css';
import '@nosferatu500/react-sortable-tree/style.css'; // This only needs to be imported once in your app
import './App.css';

function App() {
  const reload = () => window.location.reload();
  return (
    <ThemeProvider theme={theme}>
      <Router basename={''}>
        <div>
          <Routes>
          <Route path="docs" onEnter={reload} />
          <Route path="docs/intro" onEnter={reload} />
            {publicRoutes.map((route, idx) => {
              return (
                <Route
                  key={idx + '-pu'}
                  path={route.path}
                  exact={route.exact}
                  element={
                    <route.layout title={route.pageTitle}>
                      <route.component />
                    </route.layout>
                  }
                />
              );
            })}
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default withAuthenticator(App, {
  loginMechanisms: ['email'],
  components: {
    Header,
    // SignIn: {
    //   Header: SignInHeader,
    //   Footer: SignInFooter
    // },
    Footer,
  },
});
