// Placeholder variables are replaced by environment variables ("envsubst < src/config.js" command in the Makefile)
export const cfg = {
  amplify: {
    Auth: {
      mandatorySignIn: true,
      region: 'us-east-1',
      userPoolId: 'us-east-1_nvD6SOaUx',
      userPoolWebClientId: '1b9h4gmbfg526jno3jggs8ro4j',
      identityPoolId: 'us-east-1:7e3a7806-4dc4-41a0-b557-ecf827431739',
    },
    Storage: {
      region: 'us-east-1',
      bucket: 'dev-aerotage-data',
      identityPoolId: 'us-east-1:7e3a7806-4dc4-41a0-b557-ecf827431739',
    },
  },
  apiUrl: 'https://1o8voaw1x1.execute-api.us-east-1.amazonaws.com/dev',
};