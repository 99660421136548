const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
const TOGGLE_MOBILE_SIDEBAR = 'TOGGLE_MOBILE_SIDEBAR';
const TOGGLE_SIDEBAR_ITEM = 'TOGGLE_SIDEBAR_ITEM';

export const ToggleSidebar = () => {
  return {
    type: TOGGLE_SIDEBAR,
  };
};

export const ToggleMobileSidebar = () => {
  return {
    type: TOGGLE_MOBILE_SIDEBAR,
  };
};

export const ToggleSidebarItem = (name) => {
  return {
    type: TOGGLE_SIDEBAR_ITEM,
    payload: name,
  };
};
