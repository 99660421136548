import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableBody,
  TableCell,
  Box,
  Grid,
} from '@material-ui/core/';
import { withStyles } from '@material-ui/styles';

/** Helper methods */
import { convertPixelsToRem } from '../../../../../helpers';

const useStyles = makeStyles({
  container: {
    borderRight: '1px solid #797979',
    fontSize: `${convertPixelsToRem(12)} !important`,
  },
  table: {
    minWidth: 650,
  },
});

const StyledGrid = withStyles((theme) => ({
  root: {
    '&&& .hideScrollbar::-webkit-scrollbar': {
      background: 'transparent' /* Chrome/Safari/Webkit */,
      width: 0,
    },
  },
}))(Grid);

const AirbusSumTable = (props) => {

  const getTableWidth = () => {
    if (props.data && props.data.circuitBreakers && props.data.circuitBreakers.length > 10) {
      return 'calc(100% - 17px)'; // 17px is the scrollbar's width
    }
    return '100%';
  };

  const round = (value, precision) => {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  };

  const getSumRows = (isSheddableOnly) => {
    return Array.from(Array(10).keys()).map(function (key, valIdx) {
      // loop over [0,...,9]
      const sum = props.data.circuitBreakers
        .map((cb) => props.version >= 1.2 ? cb.postmod : cb)
        .filter((cb) => !isSheddableOnly || cb['s'] === false) // filter by sheddable
        .filter((cb, cbIdx) => !props.isCircuitBreakerDeleted(cbIdx)) // exclude deleted CB
        .reduce((partial_sum, cb) => partial_sum + (parseFloat(cb[props.load][key]) || 0), 0); // convert values to float and use 0 if the value is not a float (parseFloat returns NaN)
      return (
        <TableCell align="center" key={valIdx}>
          {round(sum, 1)}
        </TableCell>
      );
    });
  };

  return (
    <StyledGrid container>
      <Grid item md={3} />
      <Grid item md={3}>
        <Box borderRight="1px solid #9E9E9E" borderLeft="1px solid #9E9E9E">
          <TableContainer className={useStyles.container} style={{ height: 500 }}>
            <Table className={useStyles.table}>
              <TableBody>
                <TableRow>
                  <TableCell align="center">
                    <b>BUSBAR - TOTAL NOT SHEDDABLE</b>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">
                    <b>BUSBAR - TOTAL (VA)</b>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
      <Grid item md={6}>
        <TableContainer className={useStyles.container} style={{ height: 500 }}>
          <Table className={useStyles.table} style={{ width: getTableWidth() }}>
            <TableHead style={{ visibility: 'collapse', border: 'hidden' }}>
              <TableRow>
                <TableCell align="center">
                  <b>
                    NOMINAL
                    <br />
                    POWER
                  </b>
                </TableCell>
                <TableCell align="center">
                  <b>GROUND</b>
                </TableCell>
                <TableCell align="center">
                  <b>START</b>
                </TableCell>
                <TableCell align="center">
                  <b>ROLL</b>
                </TableCell>
                <TableCell align="center">
                  <b>T/OFF</b>
                </TableCell>
                <TableCell align="center">
                  <b>CLIMB</b>
                </TableCell>
                <TableCell align="center">
                  <b>CRUISE</b>
                </TableCell>
                <TableCell align="center">
                  <b>DESC</b>
                </TableCell>
                <TableCell align="center">
                  <b>LAND</b>
                </TableCell>
                <TableCell align="center">
                  <b>TAXI</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow style={{ height: 41 }}>
                {props.data && props.data.circuitBreakers ? getSumRows(true) : null}
              </TableRow>
              <TableRow style={{ height: 41 }}>
                {props.data && props.data.circuitBreakers ? getSumRows(false) : null}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ width: 35 }} borderRight="1px solid #9E9E9E" borderLeft="1px solid #9E9E9E" />
      </Grid>
    </StyledGrid>
  );
};

export default AirbusSumTable;
