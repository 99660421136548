import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  makeStyles
} from "@material-ui/core";
import {Timeline, TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent} from "@material-ui/lab";
import {Auth} from 'aws-amplify';
import axios from 'axios';
import {cfg} from "../../../../../config";
import {Buffer} from 'buffer';
import withRouter from '../../../../../helpers/withRouter';
import { getBase64, downloadAirbusCsvTemplate } from '../../../../../helpers';

import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import LoadingButton from '@mui/lab/LoadingButton';
import { DropzoneArea } from 'material-ui-dropzone';
import { NotificationManager } from 'react-notifications';
import clsx from "clsx";

const useStyles = makeStyles({
  timeline_item: {
    '&:before': {
      flex: 0,
      padding: 0,
    }
  },
  content1: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '20px 0'
  },
  dropzoneContainer: {
    border: 'dashed 1px lightgray',
    borderRadius: '5px',
    width: '100%',
    marginTop: 20
  },
  dropzoneInnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
    position: 'relative'
  },
  dropzone: {
    position:"absolute",
    outline:"none !important",
    width:"100%",
    height:"100%",
    cursor:"pointer",
    opacity:"0",
    marginTop: -20
  },
  dragging: {
    border: 'solid 1px red'
  },
  previewChip: {
    minWidth: 16,
    maxWidth: 21
  },
})

const ImportFrontmatter = props => {
  const [open, setOpen] = React.useState(false);
  const [uploadFile, setUploadFile] = React.useState(null);
  const uploadFilename = React.useRef('');
  const [isUploading, setIsUploading] = React.useState(false);

  const handleClose = () => {
    setUploadFile(null);
    setOpen(false);
  }
  const classes = useStyles();

  const theme = createTheme({
    overrides: {
      MuiDropzoneArea: {
        root: {
          minHeight: '', // reduce component height
        },
        text: {
          fontSize: '16px',
        },
      }
    }
  });

  const submit = async () => {
    const markdown = uploadFile;

    // Set shouldBeSaving to true to trigger patchAnalysis after saving node to the tree
    props.shouldBeSavingText.current = true;

    // Save text
    const frontmatter = {text: markdown, name: uploadFilename.current}
    props.setFrontmatter(frontmatter);

    // Close form
    setIsUploading(false);
    setOpen(false);
  }

  const DecodeAndSetUploadFile = (file) => {
    const base64 = file.split('base64,')[1]
    const buffer = Buffer.from(base64, 'base64');
    const decoded = buffer.toString('ascii');
    setUploadFile(decoded)
  }

  const handleFileChange = async (files) => {
    // Reset inputFile if the input file is removed from the UI
    if (files.length === 0) {
      setUploadFile(null); // reset inputFile
      return;
    }

    const filename = files[0].name
    const basename = filename.split('.').slice(0, -1).join('.'); // remove the file extension
    uploadFilename.current = basename;

    // Get the input file as base64 and update the state with the callback
    getBase64(files[0], DecodeAndSetUploadFile); // prints the base64 string
  };


  return(
    <>
      <Button
        disabled={props.disabled}
        onClick={() => setOpen(i => !i)}
        style={{marginRight: 20}}
        size={'small'}
        startIcon={<CloudUploadIcon />}
        color={'primary'}>
          {props.buttonTitle}
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="import-analysis-dialog">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.text}
          </DialogContentText>
          <Timeline align={'left'}>
            <TimelineItem classes={{root: classes.timeline_item}}>
              <TimelineSeparator>
                <TimelineDot>
                  <GetAppIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography>Download .md file if needed</Typography>
                <div className={classes.content1}>
                  <div>
                    <Button disabled color={'primary'} onClick={() => downloadAirbusCsvTemplate(props.templateName, props.templateColumns)}>Standard_frontmatter.md</Button>
                    <Button disabled color={'primary'} onClick={() => downloadAirbusCsvTemplate(props.templateName, props.templateColumns)}>Enhanced_frontmatter.md</Button>
                  </div>
                </div>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem classes={{root: classes.timeline_item}}>
              <TimelineSeparator>
                <TimelineDot>
                  <PublishIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography>Upload .md file</Typography>
                  <MuiThemeProvider theme={theme}>
                    <DropzoneArea
                      dropzoneText={'Drag & Drop or click to upload a .md file'}
                      onChange={(e) => handleFileChange(e)}
                      acceptedFiles={['.md']}
                      filesLimit={1}
                      showPreviews={true}
                      showPreviewsInDropzone={false}
                      useChipsForPreview
                      previewText="Selected file"
                    />
                  </MuiThemeProvider>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </DialogContent>
        <DialogActions>
          <Button disabled={isUploading} onClick={handleClose} color="primary">
            Cancel
          </Button>
          <LoadingButton
            color="primary"
            disabled={isUploading || !Boolean(uploadFile)}
            onClick={submit}
            loading={isUploading}
            loadingPosition="start"
            startIcon={<CloudUploadIcon />}
          >
            Upload File
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default withRouter(ImportFrontmatter);