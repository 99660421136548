// Add new links to the Navigation Sidebar
// Be sure to import the respective MUI icon (https://material-ui.com/components/material-icons/)

import HomeIcon from '@material-ui/icons/Home';
import AppsIcon from '@material-ui/icons/Apps';
import OrganizationIcon from '@material-ui/icons/Business';
import React from 'react';

const items = [
  {
    title: 'Dashboard',
    icon: <HomeIcon />,
    to: '/',
  },
  {
    title: 'Applications',
    icon: <AppsIcon />,
    items: [
      {
        title: 'Airbus ELA Analysis',
        to: '/app/AirbusELA',
      },
    ],
  },
  {
    title: 'Organization',
    icon: <OrganizationIcon />,
    items: [
      {
        title: 'Company Profile',
        to: '/company_profile',
      },
      {
        title: 'Members',
        to: '/members',
      },
      /*{
                title: 'Billing & Subscription',
                to: '/', //update to location
            },
            {
                title: 'Settings',
                to: '/', //update to location
            }*/
    ],
  },
];

export default items;
