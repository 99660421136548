import React from 'react';
import { useNavigate } from 'react-router-dom';
import withRouter from '../../../../helpers/withRouter';
import { withStyles } from '@material-ui/core/styles';
import { Checkbox, Box, Button, Typography, Card } from '@material-ui/core';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import ContentLoading from '../../../common/ContentLoading';
import { getAvailableOptions, searchData } from '../../../../helpers';

/**  Containers */
import ProjectListEmpty from './ProjectListEmpty';

/** Presentational */
import ProjectListOptions from './ProjectListOptions';
import ProjectListTableHeader from './ProjectListTableHeader';
import NewProjectDialog from '../../../common/NewProjectDialog';

/** App styles */
import ProjectListStyles from '../../../../styles/jss/components/apps/ProjectListStyles';

/** Initial state */
import axios from 'axios';
import { cfg } from '../../../../config';
import { Auth } from 'aws-amplify';

const ProjectList = (props) => {
  const navigate = useNavigate();

  const [data, setData] = React.useState([]);
  const [parsedData, setParsedData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isEmpty, setIsEmpty] = React.useState(false);

  React.useEffect(() => {
    fetchData();
  }, []);

  // API call to get the initial data for display
  const fetchData = () => {
    Auth.currentSession().then((session) => {
      axios
        .get(`${cfg.apiUrl}/app/boeing-ela/project`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: session.getIdToken().jwtToken,
          },
        })
        .then((resp) => {
          setIsLoading(false);
          setData(resp.data);
          setParsedData(resp.data);
          setOpen(resp.data.length < 1);
          setIsEmpty(resp.data.length < 1);
        });
    });
  };

  //logic to sort the data based upon the search
  const sortBySearch = (input) => {
    let newData = searchData(data, input, ['name']);
    setParsedData(newData);
  };

  // refreshes the data using the get API call
  const refreshData = () => {
    fetchData();
  };

  // logic to setup the columsn for the Projects page
  const columns = [
    {
      name: 'Project Name',
      selector: (row) => row['projectName'],
      cell: (row) => (
        <Button
          disableRipple
          disableElevation
          disableFocusRipple
          color={'primary'}
          variant={'text'}
          onClick={() => navigate('/app/BoeingELA/project/' + row.id)}
        >
          {row.name}
        </Button>
      ),
      sortable: true,
    },
    {
      name: '# of Analysis',
      selector: (row) => row['analysisNum'],
      sortable: true,
      cell: (row) => <span>{row.analysisCount}</span>,
    },
    {
      name: 'Last Activity Date',
      selector: (row) => row['lastActivity'],
      cell: (row) => <span>{moment(row.modified).format('lll')}</span>,
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => <ProjectListOptions row={row} updateData={refreshData} />,
      allowOverflow: true,
      right: true,
    },
  ];

  const dialogOptions = {
    title: 'Create New Project',
    description:
      "To create a new Boeing ELA project, please input a project name.",
    inputs: [
      {
        type: 'text',
        label: 'Project Name',
        id: 'name',
      },
    ],
    postUrl: "app/boeing-ela/project",
    app: "boeing-ela"
  };

  const { classes } = props;

  return (
    <div>
      {isLoading ? (
        <div>
          <ContentLoading />
        </div>
      ) : (
        <>
          {isEmpty ? (
            <ProjectListEmpty />
          ) : (
            <>
              <NewProjectDialog
                updateData={() => fetchData()}
                handleClose={() => setOpen(false)}
                open={open}
                setOpen={setOpen}
                navigate={navigate}
                {...dialogOptions}
                // title={dialogOptions.title}
                // description={dialogOptions.description}
                // inputs={dialogOptions.inputs}
              />
              <Box className={classes.headerContainer}>
                <Typography variant={'h5'}>Project List</Typography>
                <Button variant={'contained'} color={'primary'} onClick={() => setOpen(true)}>
                  + New Project
                </Button>
              </Box>
              <Card className={classes.tableContainer}>
                <DataTable
                  style={{ marginTop: '0px' }}
                  columns={columns}
                  data={parsedData}
                  striped
                  pagination
                  paginationPerPage={10}
                  noHeader
                  subHeader
                  subHeaderComponent={
                    <ProjectListTableHeader
                      sortBySearch={sortBySearch}
                      aircraft={getAvailableOptions(data, 'aircraft')}
                    />
                  }
                  selectableRowsComponent={Checkbox}
                />
              </Card>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default withStyles(ProjectListStyles)(withRouter(ProjectList));
