import React from 'react';
import {
  Grid,
  Box,
  Divider,
  Card,
  CardContent,
  Typography,
  makeStyles,
  Link,
  IconButton,
} from '@material-ui/core';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import WarningIcon from '@mui/icons-material/Warning';
import { downloadBlob } from '../../../../../helpers';
import { Storage, Auth } from 'aws-amplify';
import moment from 'moment';

/** App theme */
import ELAStyles from '../../../../../styles/jss/components/apps/ELAStyles';

/** Helper methods */
//import { convertPixelsToRem } from '../../../../../helpers';

const useStyles = makeStyles(ELAStyles);

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(255, 0, 0, 0.87)',
    maxWidth: 700,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const getFilename = (url) => {
  return url.substring(url.lastIndexOf('/') + 1);
};

const getFilenamWithoutExtension = (url) => {
  let filename = getFilename(url);
  filename = filename.replace(/\.[^/.]+$/, "");
  return filename;
};

const downloadFile = (url) => {
  Auth.currentAuthenticatedUser().then((user) => {
    const filename = getFilename(url);
    const key = url.replace(/^(public\/)/, ''); // remove "public/" from the start of the url
    Storage.get(key, {
      download: true,
    })
      .then((resp) => {
        downloadBlob(resp.Body, filename);
      })
      .catch((e) => {
        console.log(e);
      });
  });
};

const OutputFiles = (props) => {
  const classes = useStyles();
  const {
    analysisResults,
    functions: { handleViewAll },
  } = props;
  // const { handleViewAll } = props.functions;

  const handleSubmit = (e) => {
    e.preventDefault();
    handleViewAll();
  };

  return (
    <div className={classes.cardContainer}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography className={classes.cardHeader} variant={'h6'}>
          Output Files
        </Typography>
        <Typography className={classes.cardHeaderOption} onClick={(e) => handleSubmit(e)}>
          View All
        </Typography>
      </Box>
      <Card>
        <CardContent>
          <Grid container>
            <Grid item sm={12} md={4}>
              <Typography className={classes.cardHeader} variant={'caption'}>
                Run Time
              </Typography>
            </Grid>
            <Grid item sm={12} md={6}>
              <Typography className={classes.cardHeader} variant={'caption'}>
                Output report
              </Typography>
            </Grid>
            <Grid item sm={12} md={2}>
              <Typography className={classes.cardHeader} variant={'caption'}>
                User
              </Typography>
            </Grid>
            <Box width="100%"></Box>
            <Box width="100%">
              {analysisResults.slice(0, 3).map((file, index) => {
                return (
                  <React.Fragment key={file.runDate}>
                    {index !== analysisResults.length + 0 && <Divider />}
                    <Box m={0.7} display="flex" flexDirection="row" width="100%">
                      <Grid item sm={12} md={4} xl={4}>
                        <Typography variant={'caption'} fontSize="14px">
                          {moment(file.runDate).format('lll')}
                        </Typography>
                      </Grid>
                      <Grid item sm={12} md={3} xl={3}>
                        <Typography variant={'caption'} fontSize="14px" style={{color: file.warnings && file.warnings.length > 0 ? "red" : "black"}}>
                          {getFilename(file.url)}
                        </Typography>
                        { file.warnings && file.warnings.length > 0 &&
                          <HtmlTooltip
                            title={
                              <React.Fragment>
                                <Typography variant={'body2'} fontSize="14px">ANALYSIS FINISHED WITH THE FOLLOWING WARNINGS:</Typography>
                                {
                                  file.warnings.map(function (warning, idx) {
                                    return (<li key={idx}>{warning}</li>)
                                  })
                                }
                              </React.Fragment>
                            }
                          >
                            <IconButton size="small" color="secondary">
                              <WarningIcon/>
                            </IconButton>
                          </HtmlTooltip>
                        }
                      </Grid>
                      <Grid item sm={12} md={1} xl={1}>
                        <Link
                          component="button"
                          variant="body2"
                          onClick={(e) => downloadFile(file.url)}
                        >
                          PDF
                        </Link>
                      </Grid>
                      <Grid item sm={12} md={1} xl={1}>
                        <Link
                          component="button"
                          variant="body2"
                          onClick={(e) => downloadFile(file.csvUrl)}
                        >
                          {file.csvUrl ? "CSV" : ''}
                        </Link>
                      </Grid>
                      <Grid item sm={12} md={1} xl={1}>
                        <Link
                          component="button"
                          variant="body2"
                          onClick={(e) => downloadFile(file.mdUrl)}
                        >
                          {file.mdUrl ? "MD" : ''}
                        </Link>
                      </Grid>
                      <Grid item sm={12} md={2} xl={2}>
                        <Typography variant={'caption'} fontSize="14px">
                          {file.username ? file.username.split('@')[0] : ''}
                        </Typography>
                      </Grid>
                    </Box>
                  </React.Fragment>
                );
              })}
            </Box>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export { OutputFiles, getFilename, getFilenamWithoutExtension, downloadFile };
