/*
Basic application card found on the Application page.
Props: Title, Subtitle, Icon, Link, and LinkAction
Icons use Material font icons: https://material.io/resources/icons/?style=baseline
 */

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardHeader, CssBaseline, Grid, makeStyles } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import ApplicationCardStyles from '../../styles/jss/components/common/ApplicationCardStyles';
import withRouter from '../../helpers/withRouter';
import NewProjectDialog from './NewProjectDialog';

const useStyles = makeStyles(ApplicationCardStyles);

const ApplicationCard = (props) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const { title, subtitle, icon, link, showDialog, dialogOptions } = props;

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Grid xs={12} md={6} lg={4} item className={classes.rootContainer}>
      <CssBaseline />
      {showDialog && (
        <NewProjectDialog
          open={open}
          setOpen={setOpen}
          handleClose={() => handleClose()}
          title={dialogOptions.title}
          description={dialogOptions.description}
          inputs={dialogOptions.inputs}
          postUrl={dialogOptions.postUrl}
          app={dialogOptions.app}
          updateData={() => console.log('New project created.')}
          navigate={navigate}
        />
      )}
      <Card className={classes.cardContainer}>
        <CardHeader
          className={classes.headerRoot}
          avatar={
            <Icon className={classes.icon} color={theme.palette.grey[3]}>
              {icon}
            </Icon>
          }
          title={title}
          subheader={subtitle}
          classes={{ title: classes.cardHeader }}
          onClick={() => navigate(link)}
        />
      </Card>
    </Grid>
  );
};

ApplicationCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  link: PropTypes.string,
  icon: PropTypes.string,
  actionText: PropTypes.string,
  showDialog: PropTypes.bool,
};

ApplicationCard.defaultProps = {
  title: 'Application Title',
  subtitle: 'Subtitle',
  link: '/',
  icon: 'apps',
  actionText: '+ New Project',
  showDialog: false,
};

export default withRouter(ApplicationCard);

/*

*/
